import ProbillSummary from '../models/ProbillSummary';

const areProbillSummaryDataSetsEqual = (data1?: ProbillSummary[], data2?: ProbillSummary[]) => {
    if (!data1 && !data2) {
        return true;
    }
    
    if (!data1 || !data2) {
        return false;
    }

    if (data1.length !== data2.length) {
        return false;
    }

    const keys1 = data1.map((x: any) => x.probillNumber);
    const keys2 = data2.map((x: any) => x.probillNumber);

    for (let i = 0; i < keys1.length; i++) {
        if (keys2.indexOf(keys1[i]) === -1) {
            return false;
        }
    }

    return true;
};

export default areProbillSummaryDataSetsEqual;
