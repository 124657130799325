import React from 'react';
import Axios from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import Cookies from 'js-cookie';
import QuoteRequestFormBase, { QuoteRequest, QuoteRequestFormObserver, QuoteRequestFormProps, QuoteRequestFormState } from '@rosenau/quote-request-form-component';
import { FormSection, getErrorMessage, getFormikErrorsFromValidationResult, modalConfirmation, showToast, withoutEmpty } from '@rosenau/rosenau-ui';
import removeModalBackdrop from '../utils/removeModalBackdrop';

const endpointURL = process.env.REACT_APP_QuoteRequestEndpointURL;

interface QuoteRequestModalFormProps extends QuoteRequestFormProps {
    onClose: () => void;
}

export default class QuoteRequestModalForm extends QuoteRequestFormBase<QuoteRequestModalFormProps> {
    initialScroll: number = window.pageYOffset;

    componentDidUpdate(oldProps: QuoteRequestModalFormProps, oldState: QuoteRequestFormState) {
        super.componentDidUpdate(oldProps, oldState);

        if (!oldState.initialValues && this.state.initialValues) {
            $("#quote-request-modal").modal();

            $("#quote-request-modal").on("hide.bs.modal", event => {
                this.close();
    
                event.stopPropagation();
                event.preventDefault();
    
                return false;
            });
        }
    }

    componentWillUnmount() {
        removeModalBackdrop();

        // Scrolling gets jumbled on mobile, so go back to the original position on unmount:
        window.requestAnimationFrame(() => window.scrollTo(0, this.initialScroll));
    }

    render() {
        if (!this.state.initialValues) {
            return null;
        }

        return <div ref={this.ref}>
            <Formik
                initialValues={this.state.initialValues}
                onSubmit={this.submit.bind(this)}
                validateOnBlur={false}
                validateOnChange={false}
                validate={this.validate.bind(this)}>
                {formik => <Form>
                    <QuoteRequestFormObserver formik={formik} dirty={this.state.formDirty} updateDirty={dirty => this.setState({ formDirty: dirty })} />
                    <div id="quote-request-modal" className="modal" tabIndex={-1} data-backdrop="static" role="dialog">
                        <div className="modal-dialog modal-dialog-scrollable" style={{maxWidth: "1024px"}}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Quick Quote</h5>
                                    <button type="button" className="close" aria-label="Close" onClick={() => this.close()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="mt-n3">
                                        {this.state.errorMessage && <div className="alert alert-danger mt-2 error-message">{this.state.errorMessage}</div>}
                                        {this.renderFormLayout(formik)}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn" onClick={() => this.close()} disabled={formik.isSubmitting || formik.isValidating}>Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting || formik.isValidating}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>}
            </Formik>
        </div>;
    }

    close() {
        if (!this.state.formComplete && this.state.formDirty) {
            modalConfirmation("Are you sure?", "You have entered details but haven't yet submitted your request. Are you sure you want to exit the form?", () => this.props.onClose(), undefined, undefined, undefined, 1052);
        } else {
            this.props.onClose();
        }
    }

    renderButtons() {
        // Not used.

        return null;
    }

    renderConsent(formik: FormikProps<QuoteRequest>): React.ReactNode {
        return <React.Fragment>
            <FormSection title="Conditions">
                <div className="mb-4">
                    <div className="alert alert-warning mt-n3 mr-n3 mb-n4 ml-n3" role="alert" style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                        <ul style={{marginLeft: "-1.25em"}}>
                            <li>Quote number must appear on Bill of Lading to be honored, failure to reference this quote number will result in the bill being rated according to our tariff. The rate number is to be used for this shipment only and is valid until the stated expiry date. Quoted rate may revert to tariff rate if payment is not received by due date.</li>
                            <li>The rate given is only an estimate based on the freight characteristics you provided such as weight, dimensions, cubic feet, or class given at the time of quotation. If any information changes at the time of shipping, the rate might change as well. If not stated, additional services such as tailgate, private residential delivery, declared value and other accessorial charges will be extra.</li>
                            <li>Rosenau Transport Ltd. will not be liable for used equipment, glass, granite, overhead doors and-or uncrated freight. It is shipped at shipper's risk of damage with no declared value. ALL freight must be properly packaged and-or protected for shipping. Claims to carrier movement for damaged goods require that merchandise be in its original shipping packaging.</li>
                            <li>Rosenau Transport provides heated service only when requested but does not provide temperature controlled environments within specific ranges. We will not be responsible for any penalties relating to temperature controlled claims regardless of circumstances or fault.</li>
                            <li>Rosenau Transport endeavors to meet all delivery timelines when requested, however, we cannot guarantee appointment times will be met as scheduled. We reserve the right to refuse any associated penalties relating to delay regardless of circumstances or fault.</li>
                            <li>Rosenau Transport terms will supersede all proceeding agreements or terms as supplied by either the shipper, consignee or 3rd party.</li>
                        </ul>
                        <p className="m-0">Find out more at <a href="https://www.rosenau.ca" rel="noopener noreferrer" target="_blank">www.rosenau.ca</a></p>
                    </div>
                </div>
            </FormSection>
            {super.renderConsent(formik)}
        </React.Fragment>;
    }

    renderConfirmation() {
        // Not used.

        return null;
    }

    async submit(values: QuoteRequest) {
        if (!this.state.errorMessage) {
            try {
                const quoteRequest = withoutEmpty(values);

                const __ss_tk = Cookies.get("__ss_tk");

                if (__ss_tk) {
                    quoteRequest.__ss_tk = __ss_tk;
                }
    
                await Axios.post(`${endpointURL}/create`, quoteRequest);

                this.onSubmitComplete();
            } catch (error) {
                this.setState({
                    errorMessage: <React.Fragment>Unexpected error submitting the form: <strong>{getErrorMessage(error)}</strong></React.Fragment>
                });
            }
        }

        return Promise.resolve();
    }

    async validate(values: QuoteRequest): Promise<any> {
        this.setState({
            errorMessage: null,
            formComplete: false
        });

        try {
            const response = await Axios.post(`${endpointURL}/validate`, withoutEmpty(values));
            
            const errors = getFormikErrorsFromValidationResult(response.data);

            this.setState({
                formValidated: true
            });

            return Promise.resolve(errors);
        } catch (error) {
            this.setState({
                errorMessage: <React.Fragment>Unexpected error submitting the form: <strong>{getErrorMessage(error)}</strong></React.Fragment>,
                formValidated: true
            });

            return Promise.resolve({});
        }
    }

    onSubmitComplete() {
        showToast("Quote request submitted", "Thanks for contacting us! Someone will get in touch with you within 15 minutes during our regular business hours: Monday to Friday 9 AM to 5 PM. Once you have received the email with your quote, click the button in the email to book your shipment and the details of your quote will be automatically entered for you.", "success");

        this.props.onClose();
    }
}
