import React, { useContext } from 'react';
import LoginContext from '../contexts/LoginContext';
import QuoteRequestModalForm from './QuoteRequestModalForm';
import { CustomerPortalProps } from './CustomerPortal';

const QuoteRequest = (props: CustomerPortalProps) => {
    const loginContext = useContext(LoginContext);

    const { auth } = loginContext;

    const onClose = () => {
        props.history.push("/");
    };

    if (!auth.session) {
        return null;
    }

    return <QuoteRequestModalForm onClose={onClose} />;
};

export default QuoteRequest;
