import React from 'react';
import City from '../models/City';
import ShipmentInfoSearchQuery, { createBlankShipmentInfoSearchQuery } from '../models/ShipmentInfoSearchQuery';

export interface SearchShipmentsByInfoContextDataModel {
    cities?: City[];
    citiesErrorMessage?: React.ReactNode;
    query: ShipmentInfoSearchQuery;
    errorMessage?: React.ReactNode;
}

export interface SearchShipmentsByInfoContextData extends SearchShipmentsByInfoContextDataModel {
    updateCities: (cities?: City[]) => Promise<void>;
    updateCitiesErrorMessage: (errorMessage?: React.ReactNode) => Promise<void>;
    updateQuery: (query: ShipmentInfoSearchQuery) => Promise<void>;
    updateErrorMessage: (errorMessage?: React.ReactNode) => Promise<void>;
}

export const createBlankSearchShipmentsByInfoContextDataModel = () => ({
    query: createBlankShipmentInfoSearchQuery()
} as SearchShipmentsByInfoContextDataModel);

const SearchShipmentsByInfoContext = React.createContext<SearchShipmentsByInfoContextData>({
    ...createBlankSearchShipmentsByInfoContextDataModel(),
    updateCities: () => Promise.resolve(),
    updateCitiesErrorMessage: () => Promise.resolve(),
    updateQuery: () => Promise.resolve(),
    updateErrorMessage: () => Promise.resolve()
});

export default SearchShipmentsByInfoContext;
