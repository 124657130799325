/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';
import Moment from 'moment';
import { DataTable, DataTableCustomColumn, DataTableFilterByDate, DataTableFilterByLookup, DataTableFilterByString, DataTableProps, DataTableSortColumn, FilteredKeys, withFiltering, WithFilteringProps, withPaging, WithPagingProps, withSorting, WithSortingProps } from '@rosenau/rosenau-ui';
import ProbillSummary from '../models/ProbillSummary';
import getMapFromStringArray from '../utils/getMapFromStringArray';

interface ProbillSummaryDataTableProps extends DataTableProps<ProbillSummary> {
    selectedProbillsMap: Record<string, boolean>;
}

class ProbillSummaryDataTable extends DataTable<ProbillSummary, ProbillSummaryDataTableProps> {
    getTRProps(item: ProbillSummary) {
        if (this.props.selectedProbillsMap[item.probillNumber]) {
            return {
                className: "table-info"
            };
        }

        return {};
    }
}

class ProbillSummaryCustomColumn extends DataTableCustomColumn<ProbillSummary> {}
class ProbillSummarySortColumn<K extends keyof ProbillSummary> extends DataTableSortColumn<ProbillSummary, K> {}
class ProbillSummaryFilterByDate<K extends FilteredKeys<ProbillSummary, Date>> extends DataTableFilterByDate<ProbillSummary, K> {}
class ProbillSummaryFilterByLookup<K extends keyof ProbillSummary> extends DataTableFilterByLookup<ProbillSummary, K> {}
class ProbillSummaryFilterByString<K extends FilteredKeys<ProbillSummary, string>> extends DataTableFilterByString<ProbillSummary, K> {}

interface ProbillSummaryTableComponentProps {
    filter?: any;
    page?: number;
    selectedProbills?: string[];
    sortColumn?: string;
    sortDirection?: string;
    onViewProbill?: (probillNumber: string) => void;
    updateFilter: (filter?: any) => void;
    updatePage: (page?: number) => void;
    updateSelectedProbills?: (selectedProbills?: string[]) => void;
    updateSort: (sortColumn?: string, sortDirection?: string) => void;
}

const areDataSetsEqual = (data1: any, data2: any) => {
    if (!data1 && !data2) {
        return true;
    }
    
    if (!data1 || !data2) {
        return false;
    }

    if (data1.length !== data2.length) {
        return false;
    }

    const probillNumbers1 = data1.map((x: any) => x.probillNumber);
    const probillNumbers2 = data2.map((x: any) => x.probillNumber);

    for (let i = 0; i < probillNumbers1.length; i++) {
        if (probillNumbers2.indexOf(probillNumbers1[i]) === -1) {
            return false;
        }
    }

    return true;
};

const areFiltersEqual = (filter1: any, filter2: any) => {
    if (!filter1 && !filter2) {
        return true;
    }
    
    if (!filter1 || !filter2) {
        return false;
    }

    if (filter1.probillDateStart !== filter2.probillDateStart) {
        return false;
    }

    if (filter1.probillDateEnd !== filter2.probillDateEnd) {
        return false;
    }

    if (filter1.referenceNumber !== filter2.referenceNumber) {
        return false;
    }

    if (filter1.originName !== filter2.originName) {
        return false;
    }

    if (filter1.destinationName !== filter2.destinationName) {
        return false;
    }

    if (filter1.shipperName !== filter2.shipperName) {
        return false;
    }

    if (filter1.consigneeName !== filter2.consigneeName) {
        return false;
    }

    return true;
}

const ProbillSummaryTable = (props: ProbillSummaryTableComponentProps & DataTableProps<ProbillSummary> & WithFilteringProps & WithSortingProps & WithPagingProps) => {
    const { filter, page, sortColumn, sortDirection, resetPageOnDataChanged: _resetPageOnDataChanged, onViewProbill, updateFilter, updatePage, updateSort, updateData, ...rest } = props;

    const [ filteredProbills, setFilteredProbills ] = useState<ProbillSummary[] | undefined>(props.data);

    const selectedProbillsMap = getMapFromStringArray(props.selectedProbills);

    const isProbillSelected = (probillNumber: string) => !!selectedProbillsMap[probillNumber];

    const toggleSelectProbill = (probillNumber: string) => {
        if (!props.selectedProbills || !props.updateSelectedProbills) {
            return;
        }

        const index = props.selectedProbills.indexOf(probillNumber);

        if (index > -1) {
            props.updateSelectedProbills([
                ...props.selectedProbills.slice(0, index),
                ...props.selectedProbills.slice(index + 1)
            ]);
        } else {
            props.updateSelectedProbills([
                ...props.selectedProbills,
                probillNumber
            ]);
        }
    };

    const areAllProbillsSelected = () => filteredProbills && props.selectedProbills && filteredProbills.every(x => props.selectedProbills && props.selectedProbills.indexOf(x.probillNumber) > -1);

    const toggleSelectAllProbills = () => {
        if (!filteredProbills || !props.updateSelectedProbills) {
            return;
        }

        const filteredProbillNumbers = filteredProbills.map(x => x.probillNumber);
        const filteredProbillNumbersMap = getMapFromStringArray(filteredProbillNumbers);

        if (areAllProbillsSelected()) {
            props.updateSelectedProbills((props.selectedProbills || []).filter(x => !filteredProbillNumbersMap[x]));
        } else {
            props.updateSelectedProbills([...filteredProbillNumbers, ...(props.selectedProbills || []).filter(x => filteredProbillNumbers.indexOf(x) === -1)]);
        }
    };

    const resetSelectedProbills = () => {
        if (!props.updateSelectedProbills) {
            return;
        }

        props.updateSelectedProbills([]);
    }

    let hiddenSelectionWarning: React.ReactNode | undefined = undefined;

    if (filteredProbills && props.selectedProbills) {
        const hiddenSelections = props.selectedProbills.filter(x => !filteredProbills.some(y => y.probillNumber === x));

        if (hiddenSelections.length) {
            hiddenSelectionWarning = <div className="alert alert-warning">Warning: <strong>{hiddenSelections.length} {hiddenSelections.length !== 1 ? "selections are" : "selection is"} not shown in this list. <a href="#" onClick={event => {
                resetSelectedProbills();

                event.stopPropagation();
                event.preventDefault();

                return false;
            }}>Click here</a> to reset your selections.</strong></div>;
        }
    }

    const children = [
        <ProbillSummarySortColumn prop="probillNumber" heading="Probill number" render={onViewProbill ? (probillNumber => <a href={`/probill/${encodeURIComponent(probillNumber)}`} onClick={event => {
                onViewProbill(probillNumber);

                event.stopPropagation();
                event.preventDefault();

                return false;
            }}>{probillNumber}</a>) : undefined} />,
        <ProbillSummarySortColumn prop="probillDate" heading="Date" render={date => Moment(date, "YYYYMMDD").format("YYYY-MM-DD")} />,
        <ProbillSummarySortColumn prop="referenceNumber" heading="Reference number" />,
        <ProbillSummarySortColumn prop="originName" heading="Origin" />,
        <ProbillSummarySortColumn prop="destinationName" heading="Destination" />,
        <ProbillSummarySortColumn prop="shipperName" heading="Shipper" />,
        <ProbillSummarySortColumn prop="consigneeName" heading="Consignee" />,
        <ProbillSummaryFilterByDate prop="probillDate" labelText="Date" />,
        <ProbillSummaryFilterByString prop="referenceNumber" labelText="Reference number" />,
        <ProbillSummaryFilterByLookup prop="originName" labelText="Origin" />,
        <ProbillSummaryFilterByLookup prop="destinationName" labelText="Destination" />,
        <ProbillSummaryFilterByString prop="shipperName" labelText="Shipper" />,
        <ProbillSummaryFilterByString prop="consigneeName" labelText="Consignee" />
    ];

    if (props.updateSelectedProbills) {
        children.unshift(<ProbillSummaryCustomColumn heading={<input type="checkbox" checked={areAllProbillsSelected()} onChange={() => toggleSelectAllProbills()} />} render={item => <input type="checkbox" checked={isProbillSelected(item.probillNumber)} onChange={() => toggleSelectProbill(item.probillNumber)} />} />);
    }

    const ProbillSummaryTable = withFiltering(withSorting(withPaging(ProbillSummaryDataTable, 20)));

    return <React.Fragment>
        {hiddenSelectionWarning}
        <ProbillSummaryTable className="mb-n2" {...rest} initialValues={filter} page={page} sortColumn={sortColumn} sortDirection={sortDirection} updateData={data => {
            if (!areDataSetsEqual(data, filteredProbills)) {
                setFilteredProbills(data);
                updateData?.(data);
            }
        }} updateFilter={newFilter => {
            if (!areFiltersEqual(filter, newFilter)) {
                updateFilter(newFilter);
            }
        }} updatePage={updatePage} updateSort={updateSort} selectedProbillsMap={selectedProbillsMap} resetPageOnDataChanged={false} renderFooter={() => props.selectedProbills?.length ? <tfoot>
            <tr>
                <td colSpan={8} className="text-muted">{props.selectedProbills.length} probill{props.selectedProbills.length !== 1 ? "s" : ""} selected</td>
            </tr>
        </tfoot> : undefined}>
            {children}
        </ProbillSummaryTable>
    </React.Fragment>;
};

export default ProbillSummaryTable;
