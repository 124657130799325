import React from 'react';

export interface PaymentsContextDataModel {
    enablePayments: boolean;
}

export interface PaymentsContextData extends PaymentsContextDataModel {
    update: (enablePayments: boolean) => Promise<void>;
}

const PaymentsContext = React.createContext<PaymentsContextData>({
    enablePayments: false,
    update: () => Promise.resolve()
});

export default PaymentsContext;
