/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { LoadingSpinner } from '@rosenau/rosenau-ui';
import { CustomerPortalProps } from './CustomerPortal';
import AppContext from '../contexts/AppContext';
import LoginContext from '../contexts/LoginContext';
import ViewProbillContext from '../contexts/ViewProbillContext';
import ViewProbillImageContext from '../contexts/ViewProbillImageContext';
import Base64Document from '../models/Base64Document';
import APIResponse from '../models/APIResponse';
import { isSearch } from '../navigation/NavItems';
import { endpointBaseURL } from '../utils/Constants';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import getImageFromProbill from '../utils/getImageFromProbill';
import getImageTypeTranslation from '../utils/getImageTypeTranslation';
import isSessionExpired from '../utils/isSessionExpired';
import removeModalBackdrop from '../utils/removeModalBackdrop';
import saveImage from '../utils/saveImage';

const ViewProbillImage = (props: CustomerPortalProps) => {
    const context = useContext(ViewProbillImageContext);
    const appContext = useContext(AppContext);
    const loginContext = useContext(LoginContext);
    const viewProbillContext = useContext(ViewProbillContext);
    
    const { image, imageData, update } = context;
    const { cancelTokenSource } = appContext;
    const { auth, sessionExpired } = loginContext;
    const { onImageClose: onClose, probill, viewedProbills, updateViewedProbills } = viewProbillContext;

    const probillNumber = probill?.probillNumber;

    const load = async () => {
        await updateViewedProbills(props.location.state?.viewedProbills?.split(",").filter(x => x) || []);

        const probillViewImageFilename = decodeURIComponent(props.match.params.probillViewImageFilename || "");

        if (image?.path === probillViewImageFilename) {
            return;
        }

        update();

        if (!auth.session) {
            return;
        }

        if (!probillViewImageFilename) {
            return;
        }

        const newImage = getImageFromProbill(probill, probillViewImageFilename);

        if (!newImage) {
            return;
        }
        
        try {
            const response = await Axios.post<APIResponse<Base64Document[]>>(`${endpointBaseURL}/customerportal/probills/documents/getprobilldocument`, {
                accessToken: auth.session.accessToken,
                formatType: "jpeg",
                imageUri: newImage.path
            }, {
                cancelToken: cancelTokenSource.token
            });

            if (isSessionExpired(response.data)) {
                sessionExpired();

                return;
            }

            if (response.data.status === "ERROR" || !response.data.body) {
                update(undefined, undefined, <React.Fragment>Could not load probill image: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);

                return;
            }

            context.update(newImage, response.data.body);
        } catch (error) {
            if (Axios.isCancel(error)) {
                return;
            }
            
            update(undefined, undefined, <React.Fragment>Could not load probill image: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    const close = () => probillNumber && onClose(props, probillNumber, { viewedProbills: viewedProbills.length ? viewedProbills.join(",") : undefined });

    useEffect(() => {
        $("#probill-image-view-modal").modal();

        $("#probill-image-view-modal").on("hide.bs.modal", event => {
            close();

            event.stopPropagation();
            event.preventDefault();

            return false;
        });

        return removeModalBackdrop;
    }, []);

    useEffect(() => {
        load();
    }, [props.location.pathname, probill]);

    return <div id="probill-image-view-modal" className="modal" tabIndex={-1} role="dialog" data-backdrop="static" style={{zIndex: (isSearch(props.match.path) ? 1053 : 1052)}}>
        <div className="modal-dialog modal-dialog-scrollable" style={{maxWidth: "800px"}}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{probillNumber && image && imageData ? `${getImageTypeTranslation(image.type)} image for probill ${probillNumber}` : "Loading..."}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => close()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {probillNumber && image && imageData ? (
                        imageData.length > 1 ? imageData.map((image, index) => <div className="card probill-image">
                            <div className="card-header">Image {index + 1}</div>
                            <div className="card-body">
                                <img src={`data:image/png;base64,${image.base64Document}`} width="100%" alt={image.fileName} />
                            </div>
                            <div className="card-footer">
                                <div className="text-right">
                                    <button type="button" className="btn btn-primary" onClick={() => saveImage(image, "jpeg")}>Save image</button>
                                </div>
                            </div>
                        </div>) : (
                            imageData.length === 1 ? <img src={`data:image/jpeg;base64,${imageData[0].base64Document}`} width="100%" alt={imageData[0].fileName} /> : <div className="alert alert-danger">Could not load image data.</div>
                        )
                    ) : <LoadingSpinner />}
                </div>
                <div className="modal-footer">
                    <div>
                        <button type="button" className="btn" onClick={() => close()}>Close</button>
                        {imageData?.length === 1 && <button type="button" className="btn btn-primary" onClick={() => saveImage(imageData[0], "jpeg")}>Save image</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default ViewProbillImage;
