import React from 'react';
import ProbillAddress from '../models/ProbillAddress';
import ProbillCarrier from '../models/ProbillCarrier';

export function formatLines(lines: React.ReactNode[]): React.ReactNode {
    if (lines.length) {
        return <React.Fragment>{lines.map((line, index) => <React.Fragment>{line} {index + 1 !== lines.length && <br />}</React.Fragment>)}</React.Fragment>;
    }

    return <React.Fragment></React.Fragment>;
}

const moneyFormat = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export function formatMoney(value?: any): string {
    const numValue = (value && typeof value.toNumber === "function") ? value.toNumber() : parseFloat(value);

    if (isNaN(numValue)) {
        return "";
    }

    if (numValue || numValue === 0) {
        return moneyFormat.format(numValue);
    }

    return "";
}

export function formatAddress(customerName: string | undefined, address: string, address2: string, city: string, province: string, postalCode: string): React.ReactNode {
    var lines = [];

    if (customerName) {
        lines.push(customerName);
    }

    if (address) {
        lines.push(address);
    }

    if (address2) {
        lines.push(address2);
    }

    var cityLine = [];

    if (city && province) {
        cityLine.push(`${city}, ${province}`);
    } else if (city) {
        cityLine.push(city);
    } else if (province) {
        cityLine.push(province);
    }

    if (postalCode) {
        cityLine.push(postalCode);
    }

    if (cityLine.length) {
        lines.push(cityLine.join(" "));
    }

    return formatLines(lines);
}

export function formatProbillAddress(address: ProbillAddress) {
    return formatAddress(address.name, address.address, address.address2, address.city, address.province, address.postalCode);
}

export function formatProbillCarrier(carrier: ProbillCarrier) {
    var lines = [];

    if (carrier.name) {
        lines.push(carrier.name);
    }

    if (carrier.reference) {
        lines.push(<React.Fragment><strong>Reference:</strong> {carrier.reference}</React.Fragment>);
    }

    if (carrier.prepaid) {
        lines.push(<React.Fragment><strong>Prepaid:</strong> {formatMoney(carrier.prepaid)}</React.Fragment>);
    }

    if (carrier.collect) {
        lines.push(<React.Fragment><strong>Collect:</strong> {formatMoney(carrier.collect)}</React.Fragment>);
    }

    if (carrier.charge) {
        lines.push(<React.Fragment><strong>Charge:</strong> {formatMoney(carrier.charge)}</React.Fragment>);
    }

    return formatLines(lines);
}
