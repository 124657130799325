import React from 'react';
import FuelSurcharge from '../models/FuelSurcharge';

export interface FuelSurchargeContextDataModel {
    data?: FuelSurcharge;
    errorMessage?: React.ReactNode;
}

export interface FuelSurchargeContextData extends FuelSurchargeContextDataModel {
    update: (fuelSurcharge?: FuelSurcharge, errorMessage?: React.ReactNode) => Promise<void>;
}

const ViewFuelSurchargeContext = React.createContext<FuelSurchargeContextData>({
    update: () => Promise.resolve()
});

export default ViewFuelSurchargeContext;
