import React from 'react';

export interface PaidInvoicesContextDataModel {
    keys: string[];
}

export interface PaidInvoicesContextData extends PaidInvoicesContextDataModel {
    removeProcessedInvoices: (newKeys: string[]) => Promise<void>;
    payInvoices: (keys: string[]) => Promise<void>;
}

export const createBlankPaidInvoicesContextDataModel = () => ({
    keys: []
} as PaidInvoicesContextDataModel);

const PaidInvoicesContext = React.createContext<PaidInvoicesContextData>({
    ...createBlankPaidInvoicesContextDataModel(),
    removeProcessedInvoices: () => Promise.resolve(),
    payInvoices: () => Promise.resolve()
});

export default PaidInvoicesContext;
