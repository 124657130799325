/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { DataTable, DataTableColumn, DataTableFilterByLookup, DataTableSortColumn, LoadingSpinner, withFiltering, withPaging, withSorting } from '@rosenau/rosenau-ui';
import { CustomerPortalProps } from './CustomerPortal';
import ViewOpenShipmentsContext from '../contexts/ViewOpenShipmentsContext';
import LoginContext from '../contexts/LoginContext';
import ViewProbillContext, { getDefaultProbillCallbacks } from '../contexts/ViewProbillContext';
import APIResponse from '../models/APIResponse';
import OpenShipment from '../models/OpenShipment';
import { endpointBaseURL } from '../utils/Constants';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import isSessionExpired from '../utils/isSessionExpired';

class OpenShipmentsDataTable extends DataTable<OpenShipment> {}
class OpenShipmentsColumn<K extends keyof OpenShipment> extends DataTableColumn<OpenShipment, K> {}
class OpenShipmentsSortColumn<K extends keyof OpenShipment> extends DataTableSortColumn<OpenShipment, K> {}
class OpenShipmentsFilterByLookup<K extends keyof OpenShipment> extends DataTableFilterByLookup<OpenShipment, K> {}

const pageSize = 10;

const ViewOpenShipments = (props: CustomerPortalProps) => {
    const context = useContext(ViewOpenShipmentsContext);
    const loginContext = useContext(LoginContext);
    const viewProbillContext = useContext(ViewProbillContext);

    const { data, errorMessage, filter, page, sortColumn, sortDirection, update, updateFilter, updatePage, updateSort } = context;
    const { auth, sessionExpired } = loginContext;
    const { updateCallbacks } = viewProbillContext;
    
    const OpenShipmentsTable = withFiltering(withSorting(withPaging(OpenShipmentsDataTable, pageSize)));

    const load = async () => {
        if (context.data) {
            return;
        }

        context.update();

        if (!auth.session) {
            return;
        }

        try {
            const response = await Axios.post<APIResponse<OpenShipment[]>>(`${endpointBaseURL}/customerportal/shipments/getopenshipments?accessToken=${encodeURIComponent(auth.session.accessToken)}`, {});

            if (isSessionExpired(response.data)) {
                sessionExpired();

                return;
            }

            if (response.data.status === "ERROR" || !response.data.body) {
                update(undefined, <React.Fragment>Could not load open shipments: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);

                return;
            }

            update(response.data.body);
        } catch (error) {
            update(undefined, <React.Fragment>Could not load open shipments: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    const viewProbill = async (probillNumber: string) => {
        const probillURL = `/probill/${encodeURIComponent(probillNumber)}`;

        await updateCallbacks(getDefaultProbillCallbacks());

        props.history.push(probillURL);
    };

    useEffect(() => {
        load();
    }, []);

    return <div className="card">
        <div className="card-header bg-primary text-white">
            Currently open shipments
        </div>
        <div className={data && data.length && data.length <= pageSize ? "card-body mb-n3" : "card-body"}>
            {errorMessage ? <div className="alert alert-danger m-n3">{errorMessage}</div> : (
                data ? <React.Fragment>
                    <div className="alert alert-info mx-n3 mt-n3 mb-0">
                        Currently open shipments includes all freight tendered to Rosenau Transport Ltd. that is currently
                        undelivered as well as all freight delivered within the last 2 working days.
                    </div>
                    {data.length ? <OpenShipmentsTable data={data} page={page} sortColumn={sortColumn} sortDirection={sortDirection as any} initialValues={filter} updatePage={updatePage} updateSort={updateSort} updateFilter={updateFilter} resetPageOnDataChanged={false}>
                        <OpenShipmentsFilterByLookup prop="origin" labelText="Origin" />
                        <OpenShipmentsFilterByLookup prop="destination" labelText="Destination" />
                        <OpenShipmentsSortColumn prop="probillNumber" heading="Probill number" render={probillNumber => <a href={`/probill/${encodeURIComponent(probillNumber)}`} onClick={event => {
                            viewProbill(probillNumber);

                            event.stopPropagation();
                            event.preventDefault();

                            return false;
                        }}>{probillNumber}</a>} />
                        <OpenShipmentsSortColumn prop="origin" heading="Origin" />
                        <OpenShipmentsSortColumn prop="destination" heading="Destination" />
                        <OpenShipmentsColumn prop="lastStatusMessage" heading="Last status message" />
                    </OpenShipmentsTable> : <p className="m-0 pt-3 pb-0 px-0">There are no open shipments to display.</p>}
                </React.Fragment> : <LoadingSpinner />
            )}
        </div>
    </div>;
};

export default ViewOpenShipments;
