import React, { useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import Moment from 'moment';
import { FormCityPickerCityField, FormColumn, FormDateField, FormInputField, FormRow, LoadingSpinner } from '@rosenau/rosenau-ui';
import { CustomerPortalProps } from './CustomerPortal';
import SearchShipmentsByInfoContext from '../contexts/SearchShipmentsByInfoContext';
import ShipmentInfoSearchQuery, { createBlankShipmentInfoSearchQuery, shipmentInfoSearchQueryToQueryString } from '../models/ShipmentInfoSearchQuery';

const environment = process.env.REACT_APP_Environment === "production" ? "production" : "development";

class InputField extends FormInputField<ShipmentInfoSearchQuery> {}
class DateField extends FormDateField<ShipmentInfoSearchQuery> {}
class CityField extends FormCityPickerCityField<ShipmentInfoSearchQuery> {}

interface ShipmentInfoSearchQueryObserverProps {
    query: ShipmentInfoSearchQuery;
}

const ShipmentInfoSearchQueryObserver = (props: ShipmentInfoSearchQueryObserverProps) => {
    const { query: newQuery } = props;
    
    const context = useContext(SearchShipmentsByInfoContext);

    const { query } = context;

    useEffect(() => {
        if (!newQuery || !query || newQuery.startDate !== query.startDate || newQuery.endDate !== query.endDate || newQuery.origin !== query.origin || newQuery.destination !== query.destination || newQuery.minimumPounds !== query.minimumPounds || newQuery.maximumPounds !== query.maximumPounds || newQuery.consigneeName !== query.consigneeName || newQuery.consigneeAddress !== query.consigneeAddress || newQuery.shipperName !== query.shipperName || newQuery.shipperAddress !== query.shipperAddress) {
            context.updateQuery(newQuery);
        }
    }, [context, query, newQuery, newQuery.startDate, newQuery.endDate, newQuery.origin, newQuery.destination, newQuery.minimumPounds, newQuery.maximumPounds, newQuery.consigneeName, newQuery.consigneeAddress, newQuery.shipperName, newQuery.shipperAddress]);

    return null;
};

const SearchShipmentsByInfo = (props: CustomerPortalProps) => {
    const context = useContext(SearchShipmentsByInfoContext);

    const { cities, citiesErrorMessage, query, errorMessage } = context;

    const [ submitting, setSubmitting ] = React.useState<boolean>(false);

    const validate = (query: ShipmentInfoSearchQuery) => {
        const result: any = {};

        if (Moment(query.startDate, "YYYY-MM-DD").toDate() > Moment(query.endDate, "YYYY-MM-DD").toDate()) {
            result.startDate = "Start date must be no later than end date.";
        } else if (Moment(query.endDate, "YYYY-MM-DD").diff(Moment(query.endDate, "YYYY-MM-DD"), "months") > 12) {
            result.endDate = "End date must be no more than 12 months later than start date.";
        }

        if (!query.destination && query.destinationName) {
            result.destinationName = "Destination is invalid.";
        }

        if (!query.origin && query.originName) {
            result.originName = "Origin is invalid.";
        }

        return result;
    };

    const submit = (query: ShipmentInfoSearchQuery) => {
        setSubmitting(true);

        props.history.push(`/search/shipment-info?${shipmentInfoSearchQueryToQueryString(query)}`);
    }

    useEffect(() => {
        setSubmitting(false);
    }, [props.location.pathname]);

    return citiesErrorMessage ? <div className="alert alert-danger">{citiesErrorMessage}</div> : (
        cities ? <Formik
            initialValues={query}
            onSubmit={submit}
            validate={validate}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {formik => <Form>
                <ShipmentInfoSearchQueryObserver query={formik.values} />
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <fieldset disabled={submitting}>
                    <FormRow>
                        <FormColumn columns={6}>
                            <DateField name="startDate" labelText="Start date" required={(!formik.values.origin && !formik.values.originName) || (!formik.values.destination && !formik.values.destinationName)} fieldColumns={8} />
                        </FormColumn>
                        <FormColumn columns={6}>
                            <DateField name="endDate" labelText="End date" required={(!formik.values.origin && !formik.values.originName) || (!formik.values.destination && !formik.values.destinationName)} fieldColumns={8} />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn columns={6}>
                            <CityField environment={environment} name="originName" labelText="Origin" onSelected={city => {
                                formik.setFieldValue("origin", city?.c);
                            }} required={!formik.values.startDate || !formik.values.endDate} />
                        </FormColumn>
                        <FormColumn columns={6}>
                            <CityField environment={environment} name="destinationName" labelText="Destination" onSelected={city => {
                                formik.setFieldValue("destination", city?.c)
                            }} required={!formik.values.startDate || !formik.values.endDate} />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn columns={6}>
                            <InputField name="minimumPounds" labelText="From weight (lbs)" type="number" min={0} max={99999} fieldColumns={4} />
                        </FormColumn>
                        <FormColumn columns={6}>
                            <InputField name="maximumPounds" labelText="To weight (lbs)" type="number" min={0} max={99999} fieldColumns={4} />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn columns={6}>
                            <InputField name="consigneeName" labelText="Consignee name" />
                        </FormColumn>
                        <FormColumn columns={6}>
                            <InputField name="consigneeAddress" labelText="Consignee address" />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn columns={6}>
                            <InputField name="shipperName" labelText="Shipper name" />
                        </FormColumn>
                        <FormColumn columns={6}>
                            <InputField name="shipperAddress" labelText="Shipper address" />
                        </FormColumn>
                    </FormRow>
                    <div className="text-right">
                        <button type="button" className="btn" onClick={() => {
                            formik.resetForm({
                                values: createBlankShipmentInfoSearchQuery()
                            });
                        }}>Reset</button>
                        <button type="submit" className="btn btn-primary">Search</button>
                    </div>
                </fieldset>
            </Form>}
        </Formik> : <LoadingSpinner />
    );
};

export default SearchShipmentsByInfo;
