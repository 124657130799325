import React from 'react';
import OpenShipment from '../models/OpenShipment';

export interface ViewOpenShipmentsContextDataModel {
    data?: OpenShipment[];
    errorMessage?: React.ReactNode;
    filter?: any;
    page?: number;
    sortColumn?: string;
    sortDirection?: string;
}

export interface ViewOpenShipmentsContextData extends ViewOpenShipmentsContextDataModel {
    update: (openShipments?: OpenShipment[], errorMessage?: React.ReactNode) => Promise<void>;
    updateFilter: (filter?: any) => Promise<void>;
    updatePage: (page?: number) => Promise<void>;
    updateSort: (sortColumn?: string, sortDirection?: string) => Promise<void>;
}

const ViewOpenShipmentsContext = React.createContext<ViewOpenShipmentsContextData>({
    update: () => Promise.resolve(),
    updateFilter: () => Promise.resolve(),
    updatePage: () => Promise.resolve(),
    updateSort: () => Promise.resolve()
});

export default ViewOpenShipmentsContext;
