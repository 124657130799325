import React from 'react';
import ProbillSummary from '../models/ProbillSummary';

export interface SearchShipmentsByReferenceResultsContextDataModel {
    query: string;
    data?: ProbillSummary[];
    errorMessage?: React.ReactNode;
    warningMessage?: React.ReactNode;
    filter?: any;
    page?: number;
    sortColumn?: string;
    sortDirection?: string;
}

export interface SearchShipmentsByReferenceResultsContextData extends SearchShipmentsByReferenceResultsContextDataModel {
    update: (query?: string, searchResults?: ProbillSummary[], errorMessage?: React.ReactNode, warningMessage?: React.ReactNode) => Promise<void>;
    updateFilter: (filter?: any) => Promise<void>;
    updatePage: (page?: number) => Promise<void>;
    updateSort: (sortColumn?: string, sortDirection?: string) => Promise<void>;
}

export const createBlankSearchShipmentsByReferenceResultsContextDataModel = () => ({
    query: ""
} as SearchShipmentsByReferenceResultsContextDataModel);

const SearchShipmentsByReferenceResultsContext = React.createContext<SearchShipmentsByReferenceResultsContextData>({
    ...createBlankSearchShipmentsByReferenceResultsContextDataModel(),
    update: () => Promise.resolve(),
    updateFilter: () => Promise.resolve(),
    updatePage: () => Promise.resolve(),
    updateSort: () => Promise.resolve()
});

export default SearchShipmentsByReferenceResultsContext;
