import React from 'react';

export interface EmailBillPrintImagesContextDataModel {
    errorMessage?: React.ReactNode;
}

export interface EmailBillPrintImagesContextData extends EmailBillPrintImagesContextDataModel {
    updateErrorMessage: (errorMessage?: React.ReactNode) => Promise<void>;
}

const EmailBillPrintImagesContext = React.createContext<EmailBillPrintImagesContextData>({
    updateErrorMessage: () => Promise.resolve()
});

export default EmailBillPrintImagesContext;
