import URLSearchParams from '@ungap/url-search-params';
import Moment from 'moment';

export default interface BillPrintSearchQuery {
    searchType: string;
    billingPeriod: string;
    podStartDate: string;
    podEndDate: string;
    formatType: string;
    imageType: string;
}

export const createBlankBillPrintSearchQuery = () => ({
    searchType: "billingPeriod",
    billingPeriod: "",
    podStartDate: `${new Date().getFullYear()}-01-01`,
    podEndDate: Moment().format("YYYY-MM-DD"),
    formatType: "pdf",
    imageType: "all"
} as BillPrintSearchQuery);

export const billPrintSearchQueryFromQueryString = (search: string) => {
    const searchParams = new URLSearchParams(search);

    return {
        searchType: searchParams.get("searchType") || "billingPeriod",
        billingPeriod: searchParams.get("billingPeriod") || "",
        podStartDate: searchParams.get("podStartDate") || `${new Date().getFullYear()}-01-01`,
        podEndDate: searchParams.get("podEndDate") || Moment().format("YYYY-MM-DD"),
        formatType: searchParams.get("formatType") || "pdf",
        imageType: searchParams.get("imageType") || "all"
    } as BillPrintSearchQuery;
};

export const billPrintSearchQueryToQueryString = (query: BillPrintSearchQuery) => `searchType=${encodeURIComponent(query.searchType)}&billingPeriod=${encodeURIComponent(query.billingPeriod)}&podStartDate=${encodeURIComponent(query.podStartDate)}&podEndDate=${encodeURIComponent(query.podEndDate)}&formatType=${encodeURIComponent(query.formatType)}&imageType=${encodeURIComponent(query.imageType)}`;
