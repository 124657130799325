import React from 'react';
import CarbonLevySurcharges from '../models/CarbonLevySurcharges';

export interface ViewCarbonLevySurchargesContextDataModel {
    data?: CarbonLevySurcharges;
    errorMessage?: React.ReactNode;
}

export interface ViewCarbonLevySurchargesContextData extends ViewCarbonLevySurchargesContextDataModel {
    update: (carbonLevySurcharges?: CarbonLevySurcharges, errorMessage?: React.ReactNode) => Promise<void>;
}

const ViewCarbonLevySurchargesContext = React.createContext<ViewCarbonLevySurchargesContextData>({
    update: () => Promise.resolve()
});

export default ViewCarbonLevySurchargesContext;
