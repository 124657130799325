import URLSearchParams from '@ungap/url-search-params';

export default interface ShipmentInfoSearchQuery {
    startDate: string;
    endDate: string;
    origin: string;
    originName: string;
    destination: string;
    destinationName: string;
    minimumPounds?: number;
    maximumPounds?: number;
    consigneeName: string;
    consigneeAddress: string;
    shipperName: string;
    shipperAddress: string;
}

export const createBlankShipmentInfoSearchQuery = () => ({
    startDate: `${new Date().getFullYear()}-01-01`,
    endDate: "",
    origin: "",
    originName: "",
    destination: "",
    destinationName: "",
    minimumPounds: "" as any,
    maximumPounds: "" as any,
    consigneeName: "",
    consigneeAddress: "",
    shipperName: "",
    shipperAddress: ""
} as ShipmentInfoSearchQuery);

export const shipmentInfoSearchQueryFromQueryString = (search: string) => {
    const searchParams = new URLSearchParams(search);

    return {
        startDate: searchParams.get("startDate") || "",
        endDate: searchParams.get("endDate") || "",
        origin: searchParams.get("origin") || "",
        originName: searchParams.get("originName") || "",
        destination: searchParams.get("destination") || "",
        destinationName: searchParams.get("destinationName") || "",
        minimumPounds: searchParams.get("minimumPounds") ? parseInt(searchParams.get("minimumPounds") || "") : "" as any,
        maximumPounds: searchParams.get("maximumPounds") ? parseInt(searchParams.get("maximumPounds") || "") : "" as any,
        consigneeName: searchParams.get("consigneeName") || "",
        consigneeAddress: searchParams.get("consigneeAddress") || "",
        shipperName: searchParams.get("shipperName") || "",
        shipperAddress: searchParams.get("shipperAddress") || ""
    } as ShipmentInfoSearchQuery;
};

export const shipmentInfoSearchQueryToQueryString = (query: ShipmentInfoSearchQuery) => `startDate=${encodeURIComponent(query.startDate)}&endDate=${encodeURIComponent(query.endDate)}&origin=${encodeURIComponent(query.origin)}&originName=${encodeURIComponent(query.originName)}&destination=${encodeURIComponent(query.destination)}&destinationName=${encodeURIComponent(query.destinationName)}&minimumPounds=${encodeURIComponent(query.minimumPounds ? query.minimumPounds.toFixed(0) : "")}&maximumPounds=${encodeURIComponent(query.maximumPounds ? query.maximumPounds.toFixed(0) : "")}&consigneeName=${encodeURIComponent(query.consigneeName)}&consigneeAddress=${encodeURIComponent(query.consigneeAddress)}&shipperName=${encodeURIComponent(query.shipperName)}&shipperAddress=${encodeURIComponent(query.shipperAddress)}`;
