export default interface PayInvoicesFormData {
    emailAddress: string;
    cardNumber: string;
    expiryDate: string;
    cvd: string;
}

export const createBlankPayInvoicesFormData = () => ({
    emailAddress: "",
    cardNumber: "",
    expiryDate: "",
    cvd: ""
} as PayInvoicesFormData);
