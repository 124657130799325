import PaymentRequestInvoice from './PaymentRequestInvoice';

export type TransactionType = "Purchase" | "PreAuthorization" | "PreAuthorizationCompletion" | "Refund" | "PurchaseCorrection" | "Unknown";

export const getTransactionTypeDescription = (transactionType: TransactionType) => {
    switch (transactionType) {
        case "Purchase":
            return "Purchase";
        case "PreAuthorization":
            return "Pre-authorization";
        case "PreAuthorizationCompletion":
            return "Pre-authorization completion";
        case "Refund":
            return "Refund";
        case "PurchaseCorrection":
            return "Purchase correction";
        default:
            return "Unknown";
    }
};

export type CardType = "MasterCard" | "Visa" | "AmericanExpress" | "NovusDiscover" | "Debit" | "JCB" | "Sears" | "Unknown";

export const getCardTypeDescription = (cardType: CardType) => {
    switch (cardType) {
        case "MasterCard":
            return "MasterCard";
        case "Visa":
            return "Visa";
        case "AmericanExpress":
            return "American Express";
        case "NovusDiscover":
            return "Novus/Discover";
        case "Debit":
            return "Debit";
        case "JCB":
            return "JCB";
        case "Sears":
            return "Sears";
        default:
            return "Unknown";
    }
};

export type PaymentResponseStatus = "Approved" | "PartiallyApproved" | "Declined" | "NotSent" | "TimedOut" | "NotCompleted" | "Error" | "Exception" | "Unknown";

export const getPaymentResponseStatusDescription = (status: PaymentResponseStatus) => {
    switch (status) {
        case "Approved":
            return "Approved"
        case "PartiallyApproved":
            return "Partially approved";
        case "Declined":
            return "Declined";
        case "NotSent":
            return "Not sent";
        case "TimedOut":
            return "Timed out";
        case "NotCompleted":
            return "Not completed";
        case "Exception":
            return "Exception";
        default:
            return "An unknown error has occurred.";
    }
};

export const getPaymentResponseStatusAlertClassName = (status: PaymentResponseStatus) => {
    switch (status) {
        case "Approved":
        case "PartiallyApproved":
            return "payment-response alert alert-success";
        case "Declined":
            return "payment-response alert alert-danger";
        default:
            return "payment-response alert alert-warning";
    }
};

export default interface PaymentResponse {
    token: string;
    webUserID: string;
    emailAddress: string;
    orderID: string;
    transactionTypeCode: string;
    transactionType: TransactionType;
    transactionNumber: string;
    referenceNumber: string;
    cardEntryMethod: string;
    cardTypeCode: string;
    cardType: CardType;
    cardNumberLength: number;
    maskedCardNumber: string;
    requestedAmount: number;
    transactionAmount?: number;
    transactionTimestamp: string;
    status: PaymentResponseStatus;
    authorizationNumber: string;
    isoCode: string;
    responseCode: string;
    message: string;
    exceptionMessage: string;
    exceptionType: string;
    exceptionStackTrace: string;
    invoices: PaymentRequestInvoice[];
}
