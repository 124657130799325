export default function getImageTypeTranslation(imageType: string) {
    switch (imageType) {
        case "BOL":
            return "Bill of Lading (BOL)";
        case "POD":
            return "Proof of Delivery (POD)";
        case "PDAPOD":
            return "Mobile Proof of Delivery (POD) Receipt";
        case "MPOD":
        case "M-POD":
            return "Multi-Page Proof of Delivery (POD)";
        case "MBOL":
        case "M-BOL":
            return "Multi-Page Bill of Lading (BOL)";
        case "SCALE":
            return "Scale Ticket";
        case "B&P":
            return "Bill of Lading (BOL) and Proof of Delivery (POD)";
        case "DGS":
            return "Bill of lading (BOL) for Dangerous Goods";
        case "CHEM":
            return "Bill of Lading (BOL) with Shipment of Chemicals";
        default:
            return imageType;
    }
}
