/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import SearchShipmentsByProbill from './SearchShipmentsByProbill';
import SearchShipmentsByReference from './SearchShipmentsByReference';
import SearchShipmentsByInfo from './SearchShipmentsByInfo';
import SearchShipmentsBillPrint from './SearchShipmentsBillPrint';
import { CustomerPortalProps } from './CustomerPortal';
import LoginContext from '../contexts/LoginContext';
import SearchShipmentsBillPrintContext from '../contexts/SearchShipmentsBillPrintContext';
import SearchShipmentsByInfoContext from '../contexts/SearchShipmentsByInfoContext';
import SearchShipmentsContext from '../contexts/SearchShipmentsContext';
import APIResponse from '../models/APIResponse';
import BillingPeriod from '../models/BillingPeriod';
import City from '../models/City';
import { endpointBaseURL } from '../utils/Constants';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import isSessionExpired from '../utils/isSessionExpired';

const tabs = [
    { id: "probill", name: "By probill number" },
    { id: "reference", name: "By reference number" },
    { id: "shipment-info", name: "By shipment information"},
    { id: "bill-print", name: "Bill print" }
];

const SearchShipments = (props: CustomerPortalProps) => {
    const context = useContext(SearchShipmentsContext);
    const loginContext = useContext(LoginContext);
    const searchShipmentsBillPrintContext = useContext(SearchShipmentsBillPrintContext);
    const searchShipmentsByInfoContext = useContext(SearchShipmentsByInfoContext);

    const { searchType, updateSearchType } = context;
    const { auth, sessionExpired } = loginContext;
    const { billingPeriods, updateBillingPeriods, updateBillingPeriodsErrorMessage } = searchShipmentsBillPrintContext;
    const { cities, updateCities, updateCitiesErrorMessage } = searchShipmentsByInfoContext;

    const getTabComponent = () => {
        switch (searchType) {
            case "probill":
                return <SearchShipmentsByProbill {...props} />;
            case "reference":
                return <SearchShipmentsByReference {...props} />;
            case "shipment-info":
                return <SearchShipmentsByInfo {...props} />;
            case "bill-print":
                return <SearchShipmentsBillPrint {...props} />;
            default:
                throw new Error("Invalid search type.");
        }
    };

    const loadBillingPeriods = async () => { 
        if (!auth.session) {
            return;
        }

        if (billingPeriods) {
            return;
        }

        try {
            const response = await Axios.post<APIResponse<BillingPeriod[]>>(`${endpointBaseURL}/customerportal/billing/getbillingperiods`, {
                accessToken: auth.session.accessToken,
                customer: "",
                fromDate: "20010101",
                toDate: "",
                probill: ""
            });

            if (isSessionExpired(response.data)) {
                sessionExpired();

                return;
            }

            if (response.data.status === "ERROR" || !response.data.body) {
                updateBillingPeriodsErrorMessage(<React.Fragment>Could not load billing periods: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);

                return;
            }

            updateBillingPeriods(response.data.body);
        } catch (error) {
            updateBillingPeriodsErrorMessage(<React.Fragment>Could not load billing periods: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    const loadCities = async () => {
        if (!auth.session) {
            return;
        }

        if (cities) {
            return;
        }

        try {
            const response = await Axios.post<APIResponse<City[]>>(`${endpointBaseURL}/customerportal/cities/getcities`, {
                accessToken: auth.session.accessToken,
                country: "CAN",
                fromCode: "",
                toCode: "",
                partName: "",
                province: ""
            });

            if (isSessionExpired(response.data)) {
                sessionExpired();

                return;
            }

            if (response.data.status === "ERROR" || !response.data.body) {
                updateCitiesErrorMessage(<React.Fragment>Could not load cities: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);
            }

            updateCities(response.data.body);
        } catch (error) {
            updateCitiesErrorMessage(<React.Fragment>Could not load cities: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    useEffect(() => {
        loadBillingPeriods();
        loadCities();
    }, []);

    return <div className="card">
        <div className="card-header bg-primary text-white">
            Search shipments
        </div>
        <div className="card-body">
            <ul className="nav nav-tabs mb-3">
                {tabs.map(tab => <li className="nav-item">
                    <a href="#" className={tab.id === context.searchType ? "nav-link active" : "nav-link"} onClick={event => {
                        updateSearchType(tab.id);

                        event.stopPropagation();
                        event.preventDefault();

                        return false;
                    }}>{tab.name}</a>
                </li>)}
            </ul>
            {getTabComponent()}
        </div>
    </div>;
};

export default SearchShipments;
