/* eslint-disable react-hooks/exhaustive-deps */

import React, {useContext, useEffect, useState} from 'react';
import Axios from 'axios';
import { RouteComponentProps, StaticContext } from 'react-router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NotFound } from '@rosenau/rosenau-template-components';
import { getErrorMessage } from '@rosenau/rosenau-ui';
import Login from './Login';
import ViewCarbonLevySurcharges from './ViewCarbonLevySurcharges';
import ViewFuelSurcharge from './ViewFuelSurcharge';
import ViewOpenShipments from './ViewOpenShipments';
import SearchShipments from './SearchShipments';
import UnpaidInvoicesSummary from './UnpaidInvoicesSummary';
import AppContext from '../contexts/AppContext';
import LoginContext from '../contexts/LoginContext';
import PaymentsContext from '../contexts/PaymentsContext';
import UnpaidInvoicesContext from '../contexts/UnpaidInvoicesContext';
import { getStrictMatch } from '../navigation/NavItems';
import { azureEndpointBaseURL } from '../utils/Constants';
import './CustomerPortal.css';
import ServiceReport, {ServiceReportProps} from "./ServiceReport";

interface CustomerPortalParams {
    probillNumber?: string;
    probillViewImageFilename?: string;
    probillEmailImageFilename?: string;
}

export interface CustomerPortalNavState {
    viewedProbills?: string;
}

export type CustomerPortalProps = RouteComponentProps<CustomerPortalParams, StaticContext, CustomerPortalNavState>;

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_ApplicationInsightsKey,
        enableCorsCorrelation: true
    }
});

const CustomerPortal = (props: CustomerPortalProps) => {
    const context = useContext(AppContext);
    const loginContext = useContext(LoginContext);
    const paymentsContext = useContext(PaymentsContext);
    const unpaidInvoicesContext = useContext(UnpaidInvoicesContext);

    const { cancelTokenSource, updateCancelTokenSource } = context;
    const { auth } = loginContext;
    const { enablePayments, update: updatePayments } = paymentsContext;
    const { errorMessage: unpaidInvoicesErrorMessage, update: updateUnpaidInvoices } = unpaidInvoicesContext;

    const [serviceReportProps, setServiceReportProps] = useState<ServiceReportProps | undefined>(undefined);


    const checkPaymentEnabled = async () => {
        if (!auth.session) {
            return;
        }

        try {
            const response = await Axios.get<boolean>(`${azureEndpointBaseURL}/enable-payments?token=${encodeURIComponent(auth.session.accessToken)}`);

            updatePayments(response.data);
        } catch (error) {
            updateUnpaidInvoices(undefined, <React.Fragment>Could not check payment status: {getErrorMessage(error)}</React.Fragment>);
        }
    };

    const getServiceReportEmbedToken = async () => {
        if (!auth.session) {
            setServiceReportProps(undefined);
            return;
        }
        const response = await Axios.get<ServiceReportProps>(`${azureEndpointBaseURL}/service-report/embed-token?token=${encodeURIComponent(auth.session.accessToken)}`);
        setServiceReportProps(response.data);
    };

    useEffect(() => {
        appInsights.loadAppInsights();
    }, []);

    useEffect(() => {
        checkPaymentEnabled();
        getServiceReportEmbedToken();

        return () => {
            cancelTokenSource?.cancel("Operation cancelled by navigation.");

            updateCancelTokenSource(Axios.CancelToken.source());
        }
    }, [auth, auth.session]);

    useEffect(() => {
        const match = getStrictMatch(props.location.pathname);
        const title = match?.navItem.title;

        if (match?.navItem.path !== "/" && title) {
            document.title = `${title} - Driven by Rosenau | Rosenau Transport Ltd.`;
        } else {
            document.title = "Driven by Rosenau | Rosenau Transport Ltd.";
        }

        appInsights.trackPageView();
    }, [props.location.pathname]);

    const showServiceReport = () => {
        if (!auth.session) {
            return;
        }

       if (!serviceReportProps || !serviceReportProps.embedToken) {
            return;
        }

        return <div id="service-report" className="portal-component">
            <div className="card">
                <div className="card-header bg-primary text-white">
                    Service Report
                </div>
                <div className="card-body">
                    <ServiceReport {...serviceReportProps}/>
                </div>
            </div>;
        </div>;
    };

    return props.match ? (
        auth.session ? <div id="customer-portal">
            <div className="row">
                <div className="col-lg-9 p-0 pr-lg-4">
                    <div id="open-shipments" className="portal-component">
                        <ViewOpenShipments {...props} />
                    </div>
                    <div id="shipment-search" className="portal-component mt-4 mb-0 mb-lg-4">
                        <SearchShipments {...props} />
                    </div>

                    {showServiceReport()}
                </div>
                <div className="col-lg-3 p-0 position-static">
                    <div className="d-flex form-buttons mb-4">
                        <div className="flex-fill mr-2 bg-white shadow">
                            <a className="btn btn-outline-secondary w-100" href="/book-a-shipment" style={{border: "1px solid rgba(0, 0, 0, 0.125)"}} onClick={event => {
                                props.history.push("/book-a-shipment");

                                event.stopPropagation();
                                event.preventDefault();

                                return false;
                            }}><i className="material-icons mr-1">local_shipping</i> Book a shipment</a>
                        </div>
                        <div className="flex-fill ml-2 bg-white shadow">
                            <a className="btn btn-outline-secondary w-100" href="/quick-quote" style={{border: "1px solid rgba(0, 0, 0, 0.125)"}} onClick={event => {
                                props.history.push("/quick-quote");

                                event.stopPropagation();
                                event.preventDefault();

                                return false;
                            }}><i className="material-icons ml-n1">request_quote</i> Quick Quote</a>
                        </div>
                    </div>
                    {(enablePayments || unpaidInvoicesErrorMessage) ? <div id="unpaid-invoices-summary" className="portal-component mb-4 mt-4 mt-lg-0">
                        <UnpaidInvoicesSummary {...props} />
                    </div> : undefined}
                    <div id="fuel-surcharge" className="portal-component mt-4 mb-4">
                        <ViewFuelSurcharge />
                    </div>
                    <div id="carbon-levy-surcharges" className="portal-component mb-4">
                        <ViewCarbonLevySurcharges />
                    </div>
                </div>
            </div>
        </div> : <Login />
    ) : <NotFound />;


};

export default CustomerPortal;
