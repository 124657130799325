import React from 'react';

export interface SearchShipmentsByReferenceContextDataModel {
    query: string;
    errorMessage?: React.ReactNode;
}

export interface SearchShipmentsByReferenceContextData extends SearchShipmentsByReferenceContextDataModel {
    updateQuery: (query: string) => Promise<void>;
    updateErrorMessage: (errorMessage?: React.ReactNode) => Promise<void>;
}

export const createBlankSearchShipmentsByReferenceContextDataModel = () => ({
    query: ""
} as SearchShipmentsByReferenceContextDataModel);

const SearchShipmentsByReferenceContext = React.createContext<SearchShipmentsByReferenceContextData>({
    ...createBlankSearchShipmentsByReferenceContextDataModel(),
    updateQuery: () => Promise.resolve(),
    updateErrorMessage: () => Promise.resolve()
});

export default SearchShipmentsByReferenceContext;
