import React from 'react';

export interface SearchShipmentsContextDataModel {
    searchType: string;
}

export interface SearchShipmentsContextData extends SearchShipmentsContextDataModel {
    updateSearchType: (searchType: string) => Promise<void>;
}

export const createBlankSearchShipmentsContextDataModel = () => ({
    searchType: "probill"
} as SearchShipmentsContextDataModel);

const SearchShipmentsContext = React.createContext<SearchShipmentsContextData>({
    ...createBlankSearchShipmentsContextDataModel(),
    updateSearchType: () => Promise.resolve()
});

export default SearchShipmentsContext;
