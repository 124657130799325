import React, { useContext } from 'react';
import LoginContext from '../contexts/LoginContext';
import BookShipmentModalForm from './BookShipmentModalForm';
import { CustomerPortalProps } from './CustomerPortal';

const environment = process.env.REACT_APP_Environment!!;
const bookShipmentEndpoint = process.env.REACT_APP_BookShipmentEndpointURL!!;
const quoteRequestsEndpoint = process.env.REACT_APP_QuoteRequestsEndpointURL!!;

const BookShipment = (props: CustomerPortalProps) => {
    const loginContext = useContext(LoginContext);

    const { auth } = loginContext;

    const onClose = () => {
        props.history.push("/");
    };

    if (!auth.session) {
        return null;
    }

    return <BookShipmentModalForm environment={environment} bookShipmentEndpointURL={bookShipmentEndpoint} quoteRequestsEndpointURL={quoteRequestsEndpoint} renderButtons={false} onClose={onClose} />;
};

export default BookShipment;
