import Probill from '../models/Probill';

const getImageFromProbill = (probill: Probill | undefined, path: string) => {
    if (!probill) {
        return undefined;
    }

    const matches = probill.images.filter(x => x.path === path);

    if (!matches.length) {
        return undefined;
    }

    return matches[0];
};

export default getImageFromProbill;
