import React from 'react';
import BookShipmentForm, { BookShipmentFormProps } from '@rosenau/book-shipment-form-component';
import { FormikProps } from 'formik';
import { WebShipmentRequest } from '@rosenau/book-shipment-models';
import { modalConfirmation, showToast } from '@rosenau/rosenau-ui';
import removeModalBackdrop from '../utils/removeModalBackdrop';

export interface BookShipmentModalFormProps extends BookShipmentFormProps {
    onClose: () => void;
}

export default class BookShipmentModalForm extends BookShipmentForm<BookShipmentModalFormProps> {
    initialScroll: number = window.pageYOffset;

    async componentDidMount() {
        await super.componentDidMount();

        $("#book-shipment-modal").modal();

        $("#book-shipment-modal").on("hide.bs.modal", event => {
            this.close();

            event.stopPropagation();
            event.preventDefault();

            return false;
        });
    }

    componentWillUnmount() {
        removeModalBackdrop();

        // Scrolling gets jumbled on mobile, so go back to the original position on unmount:
        window.requestAnimationFrame(() => window.scrollTo(0, this.initialScroll));
    }

    renderFormLayout(formik: FormikProps<WebShipmentRequest>) {
        const { currentPage, pageCount } = this.state;

        return <div id="book-shipment-modal" className="modal" tabIndex={-1} data-backdrop="static" role="dialog">
            <div className="modal-dialog modal-dialog-scrollable" style={{maxWidth: "1024px"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Book a shipment</h5>
                        <button type="button" className="close" aria-label="Close" onClick={() => this.close()} disabled={formik.isSubmitting || formik.isValidating}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="mt-n3">
                            {super.renderFormLayout(formik)}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn" onClick={() => this.close()} disabled={formik.isSubmitting || formik.isValidating}>Close</button>
                        {currentPage && pageCount && <React.Fragment>
                            {currentPage > 1 && <button type="button" className="btn btn-default" onClick={() => {
                                this.formPagesRef.current?.previousPage.call(this.formPagesRef.current);
                            }} disabled={formik.isSubmitting || formik.isValidating}>Back</button>}
                            {currentPage < pageCount && <button type="submit" className="btn btn-primary ml-2" onClick={event => {
                                this.formPagesRef.current?.nextPage.call(this.formPagesRef.current, event);
                            }} disabled={formik.isSubmitting || formik.isValidating}>Next</button>}
                            {currentPage === pageCount && <button type="submit" className="btn btn-primary ml-2" disabled={formik.isSubmitting || formik.isValidating}>Submit</button>}
                        </React.Fragment>}
                    </div>
                </div>
            </div>
        </div>;
    }

    renderConfirmationMessage() {
        return null;
    }

    close() {
        if (!this.state.formComplete && this.state.formDirty) {
            modalConfirmation("Are you sure?", "You have entered details but haven't yet submitted your request. Are you sure you want to exit the form?", () => this.props.onClose(), undefined, undefined, undefined, 1052);
        } else {
            this.props.onClose();
        }
    }

    onSubmitComplete() {
        showToast("Booking request submitted", "Thank you for booking with Rosenau Transport. You will receive 2 emails shortly with confirmation details of your shipment, and a pre-populated bill of lading. If you do not receive these emails within 5 minutes, or have a question about your shipment, please call us at <strong>1-866-225-9816</strong>.", "success");

        this.props.onClose();
    }
}
