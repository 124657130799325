/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { Form, Formik } from 'formik';
import { FormColumn, FormInputField, FormRow, LoadingSpinner, showToast } from '@rosenau/rosenau-ui';
import AppContext from '../contexts/AppContext';
import getImageTypeTranslation from '../utils/getImageTypeTranslation';
import { billPrintSearchQueryToQueryString } from '../models/BillPrintSearchQuery';
import EmailBillPrintImagesContext from '../contexts/EmailBillPrintImagesContext';
import SearchShipmentsBillPrintContext from '../contexts/SearchShipmentsBillPrintContext';
import LoginContext from '../contexts/LoginContext';
import { CustomerPortalProps } from './CustomerPortal';
import { endpointBaseURL } from '../utils/Constants';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import removeModalBackdrop from '../utils/removeModalBackdrop';
import SearchShipmentsBillPrintResultsContext from '../contexts/SearchShipmentsBillPrintResultsContext';

export interface SearchShipmentsBillPrintEmailViewFormData {
    emailTo: string;
    emailToName: string;
}

class InputField extends FormInputField<SearchShipmentsBillPrintEmailViewFormData> {}

const SearchShipmentsBillPrintEmail = (props: CustomerPortalProps) => {
    const context = useContext(EmailBillPrintImagesContext);
    const appContext = useContext(AppContext);
    const loginContext = useContext(LoginContext);
    const searchShipmentsBillPrintContext = useContext(SearchShipmentsBillPrintContext);
    const searchShipmentsBillPrintResultsContext = useContext(SearchShipmentsBillPrintResultsContext);

    const { errorMessage, updateErrorMessage } = context;
    const { cancelTokenSource } = appContext;
    const { auth, sessionExpired } = loginContext;
    const { query } = searchShipmentsBillPrintContext;
    const { selectedProbills, data: searchResults, updateSelectedProbills } = searchShipmentsBillPrintResultsContext;

    const load = () => {
        if (!auth.session) {
            return;
        }

        if (!searchResults) {
            return;
        }

        updateSelectedProbills();

        const searchParams = new URLSearchParams(props.location.search);
        const selectedProbills = (searchParams.get("selectedProbills") || "").split(",").filter(x => searchResults?.some(y => y.probillNumber === x));

        updateSelectedProbills(selectedProbills);

    };

    const submit = async (formData: SearchShipmentsBillPrintEmailViewFormData) => {
        updateErrorMessage();

        if (!auth.session) {
            return;
        }

        try {
            const response = await Axios.post(`${endpointBaseURL}/customerportal/probills/documents/emailprobilldocuments`, {
                accessToken: auth.session.accessToken,
                emailTo: formData.emailTo,
                emailToName: formData.emailToName,
                formatType: query.formatType,
                imageType: query.imageType,
                searchType: query.searchType === "billingPeriod" ? `Billing period: ${query.billingPeriod}` : `POD date range: ${query.podStartDate} to ${query.podEndDate}`,
                selectedProbills: selectedProbills
            }, {
                cancelToken: cancelTokenSource?.token
            });

            if (response.data && sessionExpired(response.data)) {
                sessionExpired();

                return;
            }

            if (response.data?.status === "ERROR") {
                updateErrorMessage(<React.Fragment>Could not send email: <strong>{response.data?.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);
            }

            showToast("Email sent", `The email is being sent to ${formData.emailTo}. Please allow up to 15 minutes for it to arrive.`, "success");

            close();
        } catch (error) {
            if (Axios.isCancel(error)) {
                return;
            }
            
            updateErrorMessage(<React.Fragment>Could not send email: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    const close = () => {
        props.history.push(`/search/bill-print?${billPrintSearchQueryToQueryString(query)}&selectedProbills=${selectedProbills?.join(",") || ""}`);
    };

    useEffect(() => {
        $("#bill-print-email-modal").modal();

        $("#bill-print-email-modal").on("hide.bs.modal", event => {
            close();

            event.stopPropagation();
            event.preventDefault();

            return false;
        });

        return removeModalBackdrop;
    }, []);

    useEffect(() => {
        load();
    }, [searchResults]);

    useEffect(() => {
        if (selectedProbills?.length && query) {
            $("#bill-print-email-modal input[name=emailToName]").trigger("focus");
        }
    }, [selectedProbills, query]);

    return <Formik
        initialValues={{
            emailTo: "",
            emailToName: ""
        }}
        onSubmit={submit}
        validateOnBlur={false}
        validateOnChange={false}
    >
        {formik => <Form>
            <div id="bill-print-email-modal" className="modal" tabIndex={-1} role="dialog" data-backdrop="static" style={{zIndex: 1052}}>
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{selectedProbills?.length && query ? `Email ${getImageTypeTranslation(query.imageType)} images for ${selectedProbills.length} probill${selectedProbills.length !== 1 ? "s" : ""}` : "Loading..."}</h5>
                            <button type="button" className="close" aria-label="Close" onClick={() => close()} disabled={formik.isValidating || formik.isSubmitting}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {selectedProbills?.length && query ? <React.Fragment>
                               {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                               <FormRow>
                                    <FormColumn columns={12}>
                                        <InputField name="emailToName" labelText="Name" type="text" required={true} />
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn columns={12}>
                                        <InputField name="emailTo" labelText="Email address" type="text" required={true} />
                                    </FormColumn>
                                </FormRow>

                            </React.Fragment> : (errorMessage ? <div className="alert alert-danger">errorMessage</div> : <LoadingSpinner />)}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn" onClick={() => close()} disabled={formik.isValidating || formik.isSubmitting}>Cancel</button>
                            {selectedProbills?.length && query ? <button type="submit" className="btn btn-primary" disabled={formik.isValidating || formik.isSubmitting}>Send email</button> : undefined}
                        </div>
                    </div>
                </div>
            </div>
        </Form>}
    </Formik>;
};

export default SearchShipmentsBillPrintEmail;
