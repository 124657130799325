import React from 'react';
import ProbillSummary from '../models/ProbillSummary';

export interface SearchShipmentsByProbillResultsContextDataModel {
    query: string;
    data?: ProbillSummary[];
    errorMessage?: React.ReactNode;
    warningMessage?: React.ReactNode;
    filter?: any;
    page?: number;
    sortColumn?: string;
    sortDirection?: string;
}

export interface SearchShipmentsByProbillResultsContextData extends SearchShipmentsByProbillResultsContextDataModel {
    update: (query?: string, searchResults?: ProbillSummary[], errorMessage?: React.ReactNode, warningMessage?: React.ReactNode) => Promise<void>;
    updateFilter: (filter?: any) => Promise<void>;
    updatePage: (page?: number) => Promise<void>;
    updateSort: (sortColumn?: string, sortDirection?: string) => Promise<void>;
}

export const createBlankSearchShipmentsByProbillResultsContextDataModel = () => ({
    query: ""
} as SearchShipmentsByProbillResultsContextDataModel);

const SearchShipmentsByProbillResultsContext = React.createContext<SearchShipmentsByProbillResultsContextData>({
    ...createBlankSearchShipmentsByProbillResultsContextDataModel(),
    update: () => Promise.resolve(),
    updateFilter: () => Promise.resolve(),
    updatePage: () => Promise.resolve(),
    updateSort: () => Promise.resolve()
});

export default SearchShipmentsByProbillResultsContext;
