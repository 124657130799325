import { saveAs } from 'file-saver';
import Base64Document from '../models/Base64Document';
import base64ToBlob from '../utils/base64ToBlob';

const getContentType = (formatType: string): string => {
    switch (formatType) {
        case "pdf":
            return "application/pdf";
        case "tiff":
            return "image/tiff";
        case "jpeg":
            return "image/jpeg";
        case "zip":
            return "application/zip";
        default:
            throw new Error("Invalid format type.");
    }
}

const saveImage = (imageData: Base64Document, formatType: string) => {
    const fileBlob = base64ToBlob(imageData.base64Document, getContentType(formatType));

    saveAs(fileBlob, imageData.fileName);
};

export default saveImage;
