/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { Form, Formik } from 'formik';
import { FormColumn, FormInputField, FormRow, LoadingSpinner, showToast } from '@rosenau/rosenau-ui';
import { CustomerPortalProps } from './CustomerPortal';
import AppContext from '../contexts/AppContext';
import EmailProbillImageContext from '../contexts/EmailProbillImageContext';
import LoginContext from '../contexts/LoginContext';
import ViewProbillContext from '../contexts/ViewProbillContext';
import { isSearch } from '../navigation/NavItems';
import { endpointBaseURL } from '../utils/Constants';
import getImageFromProbill from '../utils/getImageFromProbill';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import getImageTypeTranslation from '../utils/getImageTypeTranslation';
import isSessionExpired from '../utils/isSessionExpired';
import removeModalBackdrop from '../utils/removeModalBackdrop';

export interface ProbillImageEmailViewFormData {
    emailTo: string;
    emailToName: string;
}

class InputField extends FormInputField<ProbillImageEmailViewFormData> {}

const EmailProbillImage = (props: CustomerPortalProps) => {
    const context = useContext(EmailProbillImageContext);
    const appContext = useContext(AppContext);
    const loginContext = useContext(LoginContext);
    const viewProbillContext = useContext(ViewProbillContext);

    const { updateErrorMessage, formatType, image: probillImage, errorMessage, update } = context;
    const { cancelTokenSource } = appContext;
    const { auth, sessionExpired } = loginContext;
    const { formatType: viewProbillFormatType, onImageClose: onClose, onViewProbill, probill, viewedProbills, updateViewedProbills } = viewProbillContext;

    const probillNumber = probill?.probillNumber;

    const load = async () => {
        await updateViewedProbills(props.location.state?.viewedProbills?.split(",").filter(x => x) || []);

        const probillEmailImageFilename = decodeURIComponent(props.match.params.probillEmailImageFilename || "");

        if (!probill || (probillImage?.path && probillImage.path === probillEmailImageFilename)) {
            return;
        }

        update();

        if (!auth.session) {
            return;
        }

        if (!probillEmailImageFilename) {
            return;
        }
        
        const image = getImageFromProbill(probill, probillEmailImageFilename);

        if (!image) {
            showToast("Error", "Could not load probill image: File not found", "danger");

            return;
        }

        const searchParams = new URLSearchParams(props.location.search);

        update(image, undefined, searchParams.get("formatType") || viewProbillFormatType);
    };

    const submit = async (formData: ProbillImageEmailViewFormData) => {
        updateErrorMessage();

        if (!auth.session) {
            return;
        }

        if (!probill || !probillImage) {
            return;
        }

        try {
            const response = await Axios.post(`${endpointBaseURL}/customerportal/probills/documents/emailprobilldocument`, {
                accessToken: auth.session.accessToken,
                emailTo: formData.emailTo,
                emailToName: formData.emailToName,
                formatType: formatType,
                imageType: probillImage.type,
                imageUri: probillImage.path,
                probillNumber: probill.probillNumber
            }, {
                cancelToken: cancelTokenSource?.token
            });

            if (isSessionExpired(response.data)) {
                sessionExpired();

                return;
            }

            if (response.data?.status === "ERROR") {
                updateErrorMessage(<React.Fragment>Could not send email: <strong>{response.data?.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);
            }

            showToast("Email sent", `The email is being sent to ${formData.emailTo}. Please allow up to 15 minutes for it to arrive.`, "success");

            onViewProbill(props, probill.probillNumber);
        } catch (error) {
            if (Axios.isCancel(error)) {
                return;
            }
            
            context.updateErrorMessage(<React.Fragment>Could not send email: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    const close = () => probillNumber && onClose(props, probillNumber, { viewedProbills: viewedProbills.length ? viewedProbills.join(",") : undefined });

    useEffect(() => {
        $("#probill-image-email-view-modal").modal();

        $("#probill-image-email-view-modal").on("hide.bs.modal", event => {
            close();

            event.stopPropagation();
            event.preventDefault();

            return false;
        });

        return removeModalBackdrop;
    }, []);

    useEffect(() => {
        load();
    }, [props.location.pathname, probill, probillNumber, probillImage]);

    useEffect(() => {
        if (probillNumber && probillImage) {
            $("#probill-image-email-view-modal input[name=emailToName]").trigger("focus");
        }
    }, [probill, probillNumber, probillImage]);

    return <Formik
        initialValues={{
            emailTo: "",
            emailToName: ""
        } as ProbillImageEmailViewFormData}
        onSubmit={submit}
        validateOnBlur={false}
        validateOnChange={false}>
            {formik => <Form>
                <div id="probill-image-email-view-modal" className="modal" tabIndex={-1} role="dialog" data-backdrop="static" style={{zIndex: (isSearch(props.match.path) ? 1053 : 1052)}}>
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{probillNumber && probillImage ? `Email ${getImageTypeTranslation(probillImage.type)} image for probill ${probillNumber}` : "Loading..."}</h5>
                                <button type="button" className="close" aria-label="Close" onClick={() => close()} disabled={formik.isValidating || formik.isSubmitting}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {probillNumber && probillImage ? <React.Fragment>
                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                    <FormRow>
                                        <FormColumn columns={12}>
                                            <InputField name="emailToName" labelText="Name" type="text" required={true} />
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn columns={12}>
                                            <InputField name="emailTo" labelText="Email address" type="email" required={true} />
                                        </FormColumn>
                                    </FormRow>
                                </React.Fragment> : (errorMessage ? <div className="alert alert-danger">errorMessage</div> : <LoadingSpinner />)}
                            </div>
                            <div className="modal-footer">
                                <div className="text-right">
                                    <button type="button" className="btn" onClick={() => close()} disabled={formik.isValidating || formik.isSubmitting}>Cancel</button>
                                    {probillNumber && probillImage && <button type="submit" className="btn btn-primary" disabled={formik.isValidating || formik.isSubmitting}>Send email</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>}
    </Formik>;
};

export default EmailProbillImage;
