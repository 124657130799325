import React, { useContext, useState } from 'react';
import { CustomerPortalProps } from './CustomerPortal';
import AppContext from '../contexts/AppContext';
import LoginContext from '../contexts/LoginContext';
import SearchShipmentsByProbillContext from '../contexts/SearchShipmentsByProbillContext';
import SearchShipmentsContext from '../contexts/SearchShipmentsContext';
import ViewProbillContext, { getDefaultProbillCallbacks } from '../contexts/ViewProbillContext';
import loadProbillNumberSearchResults from '../utils/loadProbillNumberSearchResults';
import SearchShipmentsByProbillResultsContext from '../contexts/SearchShipmentsByProbillResultsContext';

const SearchShipmentsByProbill = (props: CustomerPortalProps) => {
    const context = useContext(SearchShipmentsByProbillContext);
    const appContext = useContext(AppContext);
    const loginContext = useContext(LoginContext);
    const searchShipmentsByProbillResultsContext = useContext(SearchShipmentsByProbillResultsContext);
    const searchShipmentsContext = useContext(SearchShipmentsContext);
    const viewProbillContext = useContext(ViewProbillContext);
    
    const { query, errorMessage, updateQuery, updateErrorMessage } = context;
    const { updateCallbacks } = viewProbillContext;

    const [ submitting, setSubmitting ] = useState(false);

    const search = async (query: string) => {
        updateErrorMessage();

        const searchResults = await loadProbillNumberSearchResults(props, searchShipmentsByProbillResultsContext, appContext, loginContext, context, searchShipmentsContext, viewProbillContext, query);

        if (searchResults?.length === 1) {
            await updateCallbacks(getDefaultProbillCallbacks());

            props.history.push(`/probill/${encodeURIComponent(searchResults[0].probillNumber)}`);
        } else if (searchResults?.length === 0) {
            updateErrorMessage("No probill was found matching your search.");
        } else {
            props.history.push(`/search/probill?query=${encodeURIComponent(query)}`);
        }
    };

    return <React.Fragment>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <form className="form-inline" onSubmit={event => {
            (async () => {
                setSubmitting(true);

                try {
                    await search(query);
                } catch {}

                setSubmitting(false);
            })();

            event.stopPropagation();
            event.preventDefault();

            return false;
        }}>
            <fieldset disabled={submitting} style={{width: "300px"}}>
                <div className="input-group">
                    <input className="form-control" type="text" name="query" placeholder="Search by probill number" value={query} onChange={event => updateQuery(event.target.value)} required={true} maxLength={10} style={{height: "35px"}} />
                    <div className="input-group-append">
                        <button type="submit" className="btn btn-primary">Search</button>
                    </div>
                </div>
            </fieldset>
        </form>
    </React.Fragment>;
};

export default SearchShipmentsByProbill;
