/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { LoadingSpinner } from '@rosenau/rosenau-ui';
import ViewCarbonLevySurchargesContext from '../contexts/ViewCarbonLevySurchargesContext';
import LoginContext from '../contexts/LoginContext';
import APIResponse from '../models/APIResponse';
import CarbonLevySurcharges from '../models/CarbonLevySurcharges';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import isSessionExpired from '../utils/isSessionExpired';

const endpointBaseURL = process.env.REACT_APP_EndpointBaseURL;

const ViewCarbonLevySurcharges = () => {
    const context = useContext(ViewCarbonLevySurchargesContext);
    const loginContext = useContext(LoginContext);
    
    const { data, errorMessage, update: updateCarbonLevySurcharges } = context;
    const { auth, sessionExpired } = loginContext;

    const load = async () => {
        if (context.data) {
            return;
        }

        updateCarbonLevySurcharges();

        if (!auth.session) {
            return;
        }

        try {
            const response = await Axios.get<APIResponse<CarbonLevySurcharges>>(`${endpointBaseURL}/customerportal/fuel/getcustomercarbonlevysurcharge?accessToken=${encodeURIComponent(auth.session.accessToken)}`);

            if (isSessionExpired(response.data)) {
                sessionExpired();

                return;
            }

            if (response.data.status === "ERROR" || !response.data.body) {
                updateCarbonLevySurcharges(undefined, <React.Fragment>Could not load carbon levy surcharges: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);

                return;
            }

            updateCarbonLevySurcharges(response.data.body);
        } catch (error) {
            updateCarbonLevySurcharges(undefined, <React.Fragment>Could not load carbon levy surcharges: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    useEffect(() => {
        load();
    }, []);

    return <div className="card">
        <div className="card-header bg-info text-white">
            Carbon levy surcharge
        </div>
        <div className="card-body">
            {errorMessage ? <div className="alert alert-danger m-n3">{errorMessage}</div> : (
                data ? <React.Fragment>
                    {data.carbonLevyApplies ? (
                        data.surcharges.length ? <React.Fragment>
                            <div className="alert alert-info mx-n3 mt-n3 mb-0">Surcharge will apply at the province with the highest rate that the freight travels through.</div>
                            <table className="table table-sm">
                                <tbody>
                                    {data.surcharges.map((surcharge, index) => <tr key={index}>
                                        <td>
                                            <strong className="text-break">{surcharge.province}</strong><br />
                                            Surcharge: {surcharge.surchargePercentage}%
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </React.Fragment> : <div className="alert alert-danger m-n3">There is no surcharge information available to display.</div>
                     ) : <div className="alert alert-info m-n3">You are exempt from the carbon levy surcharge.</div>}
                </React.Fragment> : <LoadingSpinner />
            )}
        </div>
    </div>;
};

export default ViewCarbonLevySurcharges;
