/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { LoadingSpinner } from '@rosenau/rosenau-ui';
import UnpaidInvoicesContext from '../contexts/UnpaidInvoicesContext';
import LoginContext from '../contexts/LoginContext';
import Invoice from '../models/Invoice';
import { azureEndpointBaseURL } from '../utils/Constants';
import { formatMoney } from '../utils/Formatters';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import { CustomerPortalProps } from './CustomerPortal';
import PaidInvoicesContext from '../contexts/PaidInvoicesContext';
import Decimal from 'decimal.js';

const UnpaidInvoicesSummary = (props: CustomerPortalProps) => {
    const context = useContext(UnpaidInvoicesContext);
    const loginContext = useContext(LoginContext);
    const paidInvoicesContext = useContext(PaidInvoicesContext);

    const { data, errorMessage, warningMessage, update, updateSelectedItems } = context;
    const { auth } = loginContext;
    const { keys: paidInvoiceKeys, removeProcessedInvoices } = paidInvoicesContext;

    const load = async () => {
        if (context.data) {
            return;
        }

        update();

        if (!auth.session) {
            return;
        }

        try {
            const response = await Axios.get<Invoice[]>(`${azureEndpointBaseURL}/invoices/unpaid?token=${auth.session.accessToken}`);

            update(response.data);

            removeProcessedInvoices(response.data.map(x => x.primaryKey) || []);
        } catch (error) {
            update(undefined, <React.Fragment>Could not load unpaid invoices: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    const filteredOpenItems = data?.filter(x => paidInvoiceKeys.indexOf(x.primaryKey) === -1);

    const payAll = async () => {
        if (!auth.session) {
            return;
        }

        if (!filteredOpenItems?.length) {
            return;
        }

        await updateSelectedItems(filteredOpenItems.map(x => x.primaryKey));

        props.history.push("/pay-all");
    }

    const viewInvoices = async () => {
        if (!auth.session) {
            return;
        }

        if (!filteredOpenItems?.length) {
            return;
        }

        await updateSelectedItems([]);

        props.history.push("/unpaid-invoices");
    }

    useEffect(() => {
        load();
    }, []);

    return <div className="card">
        <div className="card-header bg-danger text-white">
            Unpaid invoices
        </div>
        <div className="card-body">
            {data ? (
                filteredOpenItems?.length ? <React.Fragment>
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    {warningMessage && <div className="alert alert-warning">{warningMessage}</div>}
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <td>
                                    <h1 className="d-none d-xl-block m-0 p-0">${formatMoney(filteredOpenItems.map(x => x.balanceDue).reduce((a, b) => a.add(new Decimal(b)), new Decimal(0)))}</h1>
                                    <h2 className="d-xl-none m-0 p-0">${formatMoney(filteredOpenItems.map(x => x.balanceDue).reduce((a, b) => a.add(new Decimal(b)), new Decimal(0)))}</h2>
                                    <div className="d-lg-none mx-0 my-3">
                                        <button type="button" className="btn btn-primary mr-3" onClick={() => payAll()}>Pay now</button>
                                        <button type="button" className="btn btn-secondary" onClick={() => viewInvoices()}>View invoices</button>
                                    </div>
                                    <div className="d-none d-xl-block mx-0 my-3 text-center">
                                        <button type="button" className="btn btn-primary mr-3" onClick={() => payAll()}>Pay now</button>
                                        <button type="button" className="btn btn-secondary" onClick={() => viewInvoices()}>View invoices</button>
                                    </div>
                                    <div className="d-none d-lg-block d-xl-none mx-0 my-3 text-center">
                                        <button type="button" className="btn btn-sm btn-primary mr-3" onClick={() => payAll()}>Pay now</button>
                                        <button type="button" className="btn btn-sm btn-secondary" onClick={() => viewInvoices()}>View invoices</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </React.Fragment> : <p className="mb-0">There are no unpaid invoices to display.</p>
            ) : <LoadingSpinner />}
        </div>
    </div>;
};

export default UnpaidInvoicesSummary;
