/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Moment from 'moment';
import { FormColumn, FormDateField, FormRadioButtonListField, FormRow, FormSelectField, LoadingSpinner, SelectOption } from '@rosenau/rosenau-ui';
import { CustomerPortalProps } from './CustomerPortal';
import SearchShipmentsBillPrintContext from '../contexts/SearchShipmentsBillPrintContext';
import BillPrintSearchQuery, { billPrintSearchQueryToQueryString, createBlankBillPrintSearchQuery } from '../models/BillPrintSearchQuery';

class DateField extends FormDateField<BillPrintSearchQuery> {}
class SelectField extends FormSelectField<BillPrintSearchQuery> {}
class RadioButtonListField extends FormRadioButtonListField<BillPrintSearchQuery> {}

interface BillPrintSearchQueryObserverProps {
    query: BillPrintSearchQuery;
}

const BillPrintSearchQueryObserver = (props: BillPrintSearchQueryObserverProps) => {
    const { query: newQuery } = props;

    const context = useContext(SearchShipmentsBillPrintContext);
    
    const { query } = context;

    useEffect(() => {
        if (!newQuery || !query || newQuery.billingPeriod !== query.billingPeriod || newQuery.podStartDate !== query.podStartDate || newQuery.podEndDate !== query.podEndDate || newQuery.formatType !== query.formatType || newQuery.imageType !== query.imageType) {
            context.updateQuery(newQuery);
        }
    }, [context, query, newQuery, newQuery.billingPeriod, newQuery.podStartDate, newQuery.podEndDate, newQuery.formatType, newQuery.imageType]);

    return null;
};

const SearchShipmentsBillPrint = (props: CustomerPortalProps) => {
    const context = useContext(SearchShipmentsBillPrintContext);

    const { billingPeriods, billingPeriodsErrorMessage, query, errorMessage } = context;

    const [ submitting, setSubmitting ] = useState<boolean>(false);

    const billingPeriodOptions = billingPeriods?.map(x => ({ value: x.code, description: x.billingPeriod.replace(/\//g, "-") } as SelectOption));

    const validate = (query: BillPrintSearchQuery) => {
        const result: any = {};

        if (query.searchType === "podDate" && Moment(query.podStartDate, "YYYY-MM-DD").toDate() > Moment(query.podEndDate, "YYYY-MM-DD").toDate()) {
            result.startDate = "Start date must be no later than end date.";
        }

        return result;
    };

    const submit = (query: BillPrintSearchQuery) => {
        setSubmitting(true);

        props.history.push(`/search/bill-print?${billPrintSearchQueryToQueryString(query)}`);
    };

    useEffect(() => {
        setSubmitting(false);
    }, [props.location.pathname]);

    return billingPeriodsErrorMessage ? <div className="alert alert-danger m-n3">{billingPeriodsErrorMessage}</div> : (
        billingPeriods ? <Formik
            initialValues={query}
            onSubmit={submit}
            validate={validate}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {formik => <Form>
                <BillPrintSearchQueryObserver query={formik.values} />
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <fieldset disabled={submitting}>
                    <FormRow>
                        <FormColumn columns={12}>
                            <RadioButtonListField name="searchType" labelText="Search by" options={[
                                { value: "billingPeriod", description: "Billing period" },
                                { value: "podDate", description: "POD date" }
                            ]} inline={true} required={true} />
                        </FormColumn>
                    </FormRow>
                    {formik.values.searchType === "billingPeriod" && <FormRow>
                        <FormColumn columns={6}>
                            <SelectField name="billingPeriod" labelText="Billing period" options={billingPeriodOptions} blankOption={true} required={true} />
                        </FormColumn>
                    </FormRow>}
                    {formik.values.searchType === "podDate" && <FormRow>
                        <FormColumn columns={6}>
                            <DateField name="podStartDate" labelText="Start date" required={true} />
                        </FormColumn>
                        <FormColumn columns={6}>
                            <DateField name="podEndDate" labelText="End date" required={true} />
                        </FormColumn>
                    </FormRow>}
                    <FormRow>
                        <FormColumn columns={12}>
                            <RadioButtonListField name="formatType" labelText="Format type" options={[
                                { value: "pdf", description: "PDF" },
                                { value: "jpeg", description: "JPEG" },
                                { value: "tiff", description: "TIFF" }
                            ]} inline={true} required={true} />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn columns={12}>
                            <RadioButtonListField name="imageType" labelText="Image type" options={[
                                { value: "bol", description: "BOL" },
                                { value: "pod", description: "POD" },
                                { value: "all", description: "All" }
                            ]} inline={true} required={true} />
                        </FormColumn>
                    </FormRow>
                    <div className="text-right">
                        <button type="button" className="btn" onClick={() => {
                            formik.resetForm({
                                values: createBlankBillPrintSearchQuery()
                            });
                        }}>Reset</button>
                        <button type="submit" className="btn btn-primary">Search</button>
                    </div>
                </fieldset>
            </Form>}
        </Formik> : <LoadingSpinner />
    );
};

export default SearchShipmentsBillPrint;
