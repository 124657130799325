import React from 'react';
import Axios, { CancelTokenSource } from 'axios';

export interface AppContextDataModel {
    cancelTokenSource: CancelTokenSource;
}

export interface AppContextData extends AppContextDataModel {
    updateCancelTokenSource: (cancelTokenSource: CancelTokenSource) => Promise<void>;
}

export const createBlankAppContextDataModel = () => ({
    auth: {},
    cancelTokenSource: Axios.CancelToken.source()
} as AppContextDataModel);

const AppContext = React.createContext<AppContextData>({
    ...createBlankAppContextDataModel(),
    updateCancelTokenSource: () => Promise.resolve()
});

export default AppContext;
