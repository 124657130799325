import React from 'react';
import Base64Document from '../models/Base64Document';
import ProbillImage from '../models/ProbillImage';

export interface ViewProbillImageContextDataModel {
    image?: ProbillImage;
    imageData?: Base64Document[];
    errorMessage?: React.ReactNode;
}

export interface ViewProbillImageContextData extends ViewProbillImageContextDataModel {
    update: (probillImage?: ProbillImage, imageData?: Base64Document[], errorMessage?: React.ReactNode) => Promise<void>;
}

const ViewProbillImageContext = React.createContext<ViewProbillImageContextData>({
    update: () => Promise.resolve()
});

export default ViewProbillImageContext;
