import React from 'react';
import BillingPeriod from '../models/BillingPeriod';
import BillPrintSearchQuery, { createBlankBillPrintSearchQuery } from '../models/BillPrintSearchQuery';

export interface SearchShipmentsBillPrintContextDataModel {
    billingPeriods?: BillingPeriod[];
    billingPeriodsErrorMessage?: React.ReactNode;
    query: BillPrintSearchQuery;
    errorMessage?: React.ReactNode;
}

export interface SearchShipmentsBillPrintContextData extends SearchShipmentsBillPrintContextDataModel {
    updateBillingPeriods: (billingPeriods?: BillingPeriod[]) => Promise<void>;
    updateBillingPeriodsErrorMessage: (errorMessage?: React.ReactNode) => Promise<void>;
    updateQuery: (query: BillPrintSearchQuery) => Promise<void>;
    updateErrorMessage: (errorMessage?: React.ReactNode) => Promise<void>;
}

export const createBlankSearchShipmentsBillPrintContextDataModel = () => ({
    query: createBlankBillPrintSearchQuery(),
    searchResultsDownloading: false
} as SearchShipmentsBillPrintContextDataModel);

const SearchShipmentsBillPrintContext = React.createContext<SearchShipmentsBillPrintContextData>({
    ...createBlankSearchShipmentsBillPrintContextDataModel(),
    updateBillingPeriods: () => Promise.resolve(),
    updateBillingPeriodsErrorMessage: () => Promise.resolve(),
    updateQuery: () => Promise.resolve(),
    updateErrorMessage: () => Promise.resolve()
});

export default SearchShipmentsBillPrintContext;
