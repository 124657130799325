import { matchPath } from 'react-router';
import { NavItem } from '@rosenau/rosenau-template-components';
import BookShipment from '../components/BookShipment';
import EmailProbillImageComponent from '../components/EmailProbillImage';
import ViewProbillImageComponent from '../components/ViewProbillImage';
import ViewProbillComponent from '../components/ViewProbill';
import SearchShipmentsBillPrintEmailComponent from '../components/SearchShipmentsBillPrintEmail';
import SearchShipmentsBillPrintResultsComponent from '../components/SearchShipmentsBillPrintResults';
import SearchShipmentsByProbillResultsComponent from '../components/SearchShipmentsByProbillResults';
import SearchShipmentsByReferenceResultsComponent from '../components/SearchShipmentsByReferenceResults';
import SearchShipmentsByInfoResultsComponent from '../components/SearchShipmentsByInfoResults';
import CustomerPortalComponent from '../components/CustomerPortal';
import UnpaidInvoices from '../components/UnpaidInvoices';
import PayInvoices from '../components/PayInvoices';
import QuoteRequest from '../components/QuoteRequest';

export const searchByProbillViewProbillImageNavigation: NavItem = {
path: "/search/probill/view/:probillNumber/view-image/:probillViewImageFilename",
    title: "View probill image",
    component: ViewProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const searchByProbillEmailProbillImageNavigation: NavItem = {
    path: "/search/probill/view/:probillNumber/email-image/:probillEmailImageFilename",
    title: "Email probill image",
    component: EmailProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const searchByProbillViewProbillNavigation: NavItem = {
    path: "/search/probill/view/:probillNumber",
    title: "View probill",
    component: ViewProbillComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [searchByProbillViewProbillImageNavigation, searchByProbillEmailProbillImageNavigation],
    exact: false
};

export const searchByProbillNavigation: NavItem = {
    path: "/search/probill",
    title: "Search by probill number",
    component: SearchShipmentsByProbillResultsComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [searchByProbillViewProbillNavigation],
    exact: false
};

export const searchByReferenceViewProbillImageNavigation: NavItem = {
    path: "/search/reference/view/:probillNumber/view-image/:probillViewImageFilename",
    title: "View probill image",
    component: ViewProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const searchByReferenceEmailProbillImageNavigation: NavItem = {
    path: "/search/reference/view/:probillNumber/email-image/:probillEmailImageFilename",
    title: "Email probill image",
    component: EmailProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const searchByReferenceViewProbillNavigation: NavItem = {
    path: "/search/reference/view/:probillNumber",
    title: "View probill",
    component: ViewProbillComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [searchByReferenceViewProbillImageNavigation, searchByReferenceEmailProbillImageNavigation],
    exact: false
};

export const searchByReferenceNavigation: NavItem = {
    path: "/search/reference",
    title: "Search by reference number",
    component: SearchShipmentsByReferenceResultsComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [searchByReferenceViewProbillNavigation],
    exact: false
};

export const searchByShipmentInfoViewProbillImageNavigation: NavItem = {
    path: "/search/shipment-info/view/:probillNumber/view-image/:probillViewImageFilename",
    title: "View probill image",
    component: ViewProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const searchByShipmentInfoEmailProbillImageNavigation: NavItem = {
    path: "/search/shipment-info/view/:probillNumber/email-image/:probillEmailImageFilename",
    title: "Email probill image",
    component: EmailProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const searchByShipmentInfoViewProbillNavigation: NavItem = {
    path: "/search/shipment-info/view/:probillNumber",
    title: "View probill",
    component: ViewProbillComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [searchByShipmentInfoViewProbillImageNavigation, searchByShipmentInfoEmailProbillImageNavigation],
    exact: false
};

export const searchByShipmentInfoNavigation: NavItem = {
    path: "/search/shipment-info",
    title: "Search by shipment information",
    component: SearchShipmentsByInfoResultsComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [searchByShipmentInfoViewProbillNavigation],
    exact: false
};

export const searchBillPrintEmailNavigation: NavItem = {
    path: "/search/bill-print/email",
    title: "Email selected images",
    component: SearchShipmentsBillPrintEmailComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const searchBillPrintViewProbillImageNavigation: NavItem = {
    path: "/search/bill-print/view/:probillNumber/view-image/:probillViewImageFilename",
    title: "View probill image",
    component: ViewProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const searchBillPrintEmailProbillImageNavigation: NavItem = {
    path: "/search/bill-print/view/:probillNumber/email-image/:probillEmailImageFilename",
    title: "Email probill image",
    component: EmailProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const searchBillPrintViewProbillNavigation: NavItem = {
    path: "/search/bill-print/view/:probillNumber",
    title: "View probill",
    component: ViewProbillComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [searchBillPrintViewProbillImageNavigation, searchBillPrintEmailProbillImageNavigation],
    exact: false
};

export const searchBillPrintNavigation: NavItem = {
    path: "/search/bill-print",
    title: "Bill print search",
    component: SearchShipmentsBillPrintResultsComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [searchBillPrintViewProbillNavigation, searchBillPrintEmailNavigation],
    exact: false
};

export const viewProbillImageNavigation: NavItem = {
    path: "/probill/:probillNumber/view-image/:probillViewImageFilename",
    title: "View probill image",
    component: ViewProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const emailProbillImageNavigation: NavItem = {
    path: "/probill/:probillNumber/email-image/:probillEmailImageFilename",
    title: "Email probill image",
    component: EmailProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const viewProbillNavigation: NavItem = {
    path: "/probill/:probillNumber",
    title: "View probill",
    component: ViewProbillComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [viewProbillImageNavigation, emailProbillImageNavigation],
    exact: false
};

export const payAllInvoicesNavigation: NavItem = {
    path: "/pay-all",
    title: "Pay all invoices",
    component: PayInvoices,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const payInvoicesNavigation: NavItem = {
    path: "/unpaid-invoices/pay",
    title: "Pay invoices",
    component: PayInvoices,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const unpaidInvoicesViewProbillImageNavigation: NavItem = {
    path: "/unpaid-invoices/view/:probillNumber/view-image/:probillViewImageFilename",
    title: "View probill image",
    component: ViewProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const unpaidInvoicesEmailProbillImageNavigation: NavItem = {
    path: "/unpaid-invoices/view/:probillNumber/email-image/:probillEmailImageFilename",
    title: "Email probill image",
    component: EmailProbillImageComponent,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const unpaidInvoicesViewProbillNavigation: NavItem = {
    path: "/unpaid-invoices/view/:probillNumber",
    title: "View probill",
    component: ViewProbillComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [unpaidInvoicesViewProbillImageNavigation, unpaidInvoicesEmailProbillImageNavigation],
    exact: false
};

export const unpaidInvoicesNavigation: NavItem = {
    path: "/unpaid-invoices",
    title: "Unpaid invoices",
    component: UnpaidInvoices,
    displayFullWidth: true,
    includeInMenu: false,
    children: [payInvoicesNavigation, unpaidInvoicesViewProbillNavigation],
    exact: false
}

export const bookShipmentNavigation: NavItem = {
    path: "/book-a-shipment",
    title: "Book a shipment",
    component: BookShipment,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const quickQuoteNavigation: NavItem = {
    path: "/quick-quote",
    title: "Quick Quote",
    component: QuoteRequest,
    displayFullWidth: true,
    includeInMenu: false,
    exact: false
};

export const rootNavigation: NavItem = {
    path: "/",
    title: "Driven by Rosenau",
    component: CustomerPortalComponent,
    displayFullWidth: true,
    includeInMenu: false,
    children: [viewProbillNavigation, searchByProbillNavigation, searchByReferenceNavigation, searchByShipmentInfoNavigation, searchBillPrintNavigation, unpaidInvoicesNavigation, payAllInvoicesNavigation, bookShipmentNavigation, quickQuoteNavigation],
    exact: false
};

const allNavItems = [emailProbillImageNavigation, rootNavigation, searchBillPrintEmailNavigation, searchBillPrintEmailProbillImageNavigation, searchBillPrintNavigation, searchBillPrintViewProbillImageNavigation, searchBillPrintViewProbillNavigation, searchByProbillEmailProbillImageNavigation, searchByProbillNavigation, searchByProbillViewProbillImageNavigation, searchByProbillViewProbillNavigation, searchByReferenceEmailProbillImageNavigation, searchByReferenceNavigation, searchByReferenceViewProbillImageNavigation, searchByReferenceViewProbillNavigation, searchByShipmentInfoEmailProbillImageNavigation, searchByShipmentInfoNavigation, searchByShipmentInfoViewProbillImageNavigation, searchByShipmentInfoViewProbillNavigation, viewProbillImageNavigation, viewProbillNavigation, payInvoicesNavigation, unpaidInvoicesNavigation, unpaidInvoicesViewProbillNavigation, unpaidInvoicesViewProbillImageNavigation, unpaidInvoicesEmailProbillImageNavigation, bookShipmentNavigation, quickQuoteNavigation];

export const isSearch = (path: string) => [searchBillPrintEmailNavigation, searchBillPrintEmailProbillImageNavigation, searchBillPrintNavigation, searchBillPrintViewProbillImageNavigation, searchBillPrintViewProbillNavigation, searchByProbillEmailProbillImageNavigation, searchByProbillNavigation, searchByReferenceEmailProbillImageNavigation, searchByReferenceEmailProbillImageNavigation, searchByReferenceNavigation, searchByReferenceViewProbillImageNavigation, searchByReferenceViewProbillNavigation, searchByShipmentInfoEmailProbillImageNavigation, searchByShipmentInfoNavigation, searchByShipmentInfoViewProbillImageNavigation, searchByShipmentInfoViewProbillNavigation, searchByProbillEmailProbillImageNavigation, searchByProbillViewProbillImageNavigation, searchByProbillViewProbillNavigation, unpaidInvoicesViewProbillNavigation, unpaidInvoicesViewProbillImageNavigation, unpaidInvoicesEmailProbillImageNavigation].some(x => x.path === path);

export const getStrictMatch = (path: string) => {
    const result = allNavItems.map(x => ({
        navItem: x,
        match: matchPath(path, {
            path: Array.isArray(x) ? x.path[0] : x.path,
            exact: x.exact,
            strict: true
        })
    }));
    
    const matches = result.filter(x => x.match?.isExact);

    if (matches && matches.length) {
        return matches[0];
    } else {
        return undefined;
    }
};
