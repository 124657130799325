import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { Form, Formik } from 'formik';
import { FormColumn, FormInputField, FormRow } from '@rosenau/rosenau-ui';
import LoginContext from '../contexts/LoginContext';
import APIResponse from '../models/APIResponse';
import AuthSession from '../models/AuthSession';
import { endpointBaseURL, websiteURL } from '../utils/Constants';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import removeModalBackdrop from '../utils/removeModalBackdrop';

class InputField extends FormInputField<LoginViewFormData> {}

export interface LoginViewFormData {
    userName: string;
    password: string;
}

const Login = () => {
    const context = useContext(LoginContext);

    const { auth: { userID }, errorMessage, setErrorMessage } = context;

    const submit = async (formData: LoginViewFormData) => {
        setErrorMessage();

        try {
            const response = await Axios.post<APIResponse<AuthSession>>(`${endpointBaseURL}/customerportal/auth/login`, formData);
            
            if (response.data.status !== "OK" || !response.data.body) {
                setErrorMessage(<React.Fragment>Could not log in: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);

                return;
            }

            localStorage.setItem("authSession", JSON.stringify(response.data.body));

            context.setAuth({
                userID: response.data.body.userID,
                session: response.data.body
            });

            removeModalBackdrop();
        } catch (error) {
            context.setErrorMessage(<React.Fragment>Could not log in: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    const cancel = () => {
        window.location.href = websiteURL;
    };

    useEffect(() => {
        $("#login-modal").modal();
    }, []);

    useEffect(() => {
        if (!userID) {
            $("#login-modal input[name=userName]").trigger("focus");
        } else {
            $("#login-modal input[name=password]").trigger("focus");
        }
    }, [userID]);

    return <Formik
        initialValues={{
            userName: userID || "",
            password: ""
        } as LoginViewFormData}
        onSubmit={submit}
        validateOnBlur={false}
        validateOnChange={false}
    >
        {formik => <Form>
            <div id="login-modal" className="modal" tabIndex={-1} role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Login</h5>
                        </div>
                        <div className="modal-body">
                            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                            <FormRow>
                                <FormColumn columns={12}>
                                    <InputField labelText="User ID" name="userName" required={true} maxLength={10} />
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn columns={12}>
                                    <InputField labelText="Password" name="password" type="password" required={true} maxLength={10} />
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn columns={12}>
                                    <a href={`${websiteURL}/driven-by-rosenau/forgot-password`} target="_blank" rel="noopener noreferrer">Forgot password?</a>
                                </FormColumn>
                            </FormRow>
                        </div>
                        <div className="modal-footer">
                            <div className="text-right">
                                <button type="button" className="btn" onClick={() => cancel()} disabled={formik.isValidating || formik.isSubmitting}>Cancel</button>
                                <button type="button" className="btn btn-secondary mr-2" disabled={formik.isValidating || formik.isSubmitting} onClick={() => window.open(`${websiteURL}/driven-by-rosenau/register`)}>Register</button>
                                <button type="submit" className="btn btn-primary" disabled={formik.isValidating || formik.isSubmitting}>Log in</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>}
    </Formik>;
}

export default Login;
