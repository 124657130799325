/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner, Pager } from '@rosenau/rosenau-ui';
import { CustomerPortalProps } from './CustomerPortal';
import AppContext from '../contexts/AppContext';
import ProbillSummaryTable from './ProbillSummaryTable';
import SearchShipmentsByReferenceContext from '../contexts/SearchShipmentsByReferenceContext';
import SearchShipmentsByReferenceResultsContext from '../contexts/SearchShipmentsByReferenceResultsContext';
import LoginContext from '../contexts/LoginContext';
import SearchShipmentsContext from '../contexts/SearchShipmentsContext';
import ViewProbillContext, { getDefaultProbillCallbacks } from '../contexts/ViewProbillContext';
import ProbillSummary from '../models/ProbillSummary';
import loadReferenceNumberSearchResults from '../utils/loadReferenceNumberSearchResults';
import removeModalBackdrop from '../utils/removeModalBackdrop';

const SearchShipmentsByReferenceResults = (props: CustomerPortalProps) => {
    const context = useContext(SearchShipmentsByReferenceResultsContext);
    const appContext = useContext(AppContext);
    const loginContext = useContext(LoginContext);
    const searchShipmentsContext = useContext(SearchShipmentsContext);
    const searchShipmentsByReferenceContext = useContext(SearchShipmentsByReferenceContext);
    const viewProbillContext = useContext(ViewProbillContext);

    const { data, errorMessage, warningMessage, filter, page, sortColumn, sortDirection, query, updateFilter, updatePage, updateSort } = context;
    const { updateCallbacks } = viewProbillContext;

    const [ filteredItems, setFilteredItems ] = useState<ProbillSummary[] | undefined>(data);

    const load = async () => await loadReferenceNumberSearchResults(props, context, appContext, loginContext, searchShipmentsByReferenceContext, searchShipmentsContext, viewProbillContext);

    const viewProbill = (probillNumber: string) => props.history.push(`/search/reference/view/${encodeURIComponent(probillNumber)}?query=${encodeURIComponent(query)}`);

    const close = async () => {
        await updateCallbacks(getDefaultProbillCallbacks());
        
        props.history.push("/");
    };
    
    useEffect(() => {
        $("#search-by-reference-number-results-modal").modal();

        $("#search-by-reference-number-results-modal").on("hide.bs.modal", event => {
            close();

            event.stopPropagation();
            event.preventDefault();

            return false;
        });

        return removeModalBackdrop;
    }, []);

    useEffect(() => {
        setFilteredItems(data);
    }, [data]);

    useEffect(() => {
        load();
    }, [props.location.pathname, props.location.search]);

    return <div id="search-by-reference-number-results-modal" className="modal" tabIndex={-1} data-backdrop="static" role="dialog" style={{zIndex: 1051}}>
        <div className="modal-dialog modal-dialog-scrollable" style={{maxWidth: data && data.length ? "1366px" : undefined}}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{data ? "Shipment search results" : (errorMessage ? "Error" : "Loading...")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => close()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : <React.Fragment>
                        {warningMessage && <div className="alert alert-warning">{warningMessage}</div>}
                        {data ? (
                            data.length ?
                                <ProbillSummaryTable data={data} filter={filter} page={page} renderPager={false} sortColumn={sortColumn} sortDirection={sortDirection as any} updateFilter={updateFilter} updatePage={updatePage} updateSort={updateSort} updateData={setFilteredItems} onViewProbill={viewProbill} />
                            : (!warningMessage && <p className="m-0 p-0">No probills were found matching your search.</p>)
                        ) : <LoadingSpinner />}
                    </React.Fragment>}
                </div>
                <div className="modal-footer ustify-content-between">
                    <div>
                        {filteredItems && filteredItems.length ? <Pager page={page || 1} pageSize={20} recordCount={filteredItems.length} onPageChange={page => updatePage(page)} /> : undefined}
                    </div>
                    <div>
                        <button type="button" className="btn" onClick={() => close()}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default SearchShipmentsByReferenceResults;
