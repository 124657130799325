/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import Decimal from 'decimal.js';
import { Form, Formik } from 'formik';
import Moment from 'moment';
import { FormInputField, FormRow, FormColumn, LoadingSpinner, getErrorMessage, getFormikErrorsFromValidationResult } from '@rosenau/rosenau-ui';
import { CustomerPortalProps } from './CustomerPortal';
import PayInvoicesContext from '../contexts/PayInvoicesContext';
import UnpaidInvoicesContext from '../contexts/UnpaidInvoicesContext';
import { formatMoney } from '../utils/Formatters';
import LoginContext from '../contexts/LoginContext';
import APIResponse from '../models/APIResponse';
import PayInvoicesFormData, { createBlankPayInvoicesFormData } from '../models/PayInvoicesFormData';
import PaymentRequest from '../models/PaymentRequest';
import PaymentRequestInvoice from '../models/PaymentRequestInvoice';
import PaymentResponse, { getPaymentResponseStatusAlertClassName, getPaymentResponseStatusDescription } from '../models/PaymentResponse';
import VerificationRequest from '../models/VerificationRequest';
import VerificationResponse from '../models/VerificationResponse';
import { azureEndpointBaseURL, paymentEndpointBaseURL } from '../utils/Constants';
import GetNextOrderIDResult from '../models/GetNextOrderIDResult';
import isSessionExpired from '../utils/isSessionExpired';
import removeModalBackdrop from '../utils/removeModalBackdrop';
import PaidInvoicesContext from '../contexts/PaidInvoicesContext';

class InputField extends FormInputField<PayInvoicesFormData> { }

interface PayInvoicesFormDataObserverProps {
    formData: PayInvoicesFormData;
}

const PayInvoicesFormDataObserver = (props: PayInvoicesFormDataObserverProps) => {
    const { formData: newFormData } = props;

    const context = useContext(PayInvoicesContext);

    const { formData } = context;

    useEffect(() => {
        if (formData.cardNumber !== newFormData.cardNumber || formData.emailAddress !== newFormData.emailAddress || formData.expiryDate !== newFormData.expiryDate || formData.cvd !== newFormData.cvd) {
            context.updateFormData(newFormData);
        }
    }, [context, formData, newFormData, newFormData.cardNumber, newFormData.emailAddress, newFormData.expiryDate, newFormData.cvd]);

    return null;
};

const expiryDateRegex = /^(\d{2})\/(\d{2})$/;

const PayInvoices = (props: CustomerPortalProps) => {
    const context = useContext(PayInvoicesContext);
    const loginContext = useContext(LoginContext);
    const paidInvoicesContext = useContext(PaidInvoicesContext);
    const unpaidInvoicesContext = useContext(UnpaidInvoicesContext);

    const { orderID, errorMessage, formData, response, reset, resetResponse, updateErrorMessage, updateFormData, updateOrderID, updateResponse } = context;
    const { auth, sessionExpired } = loginContext;
    const { payInvoices } = paidInvoicesContext;
    const { data, selectedItems, updateSelectedItems } = unpaidInvoicesContext;

    const invoices = data?.filter(x => selectedItems.indexOf(x.primaryKey) !== -1);
    const totalAmount = invoices?.reduce((a, b) => a.add(new Decimal(b.balanceDue)), new Decimal(0));

    const [ closing, setClosing ] = useState<boolean>(false);

    const updateErrorMessageForVerification = (errorMessage: React.ReactNode) =>
        updateErrorMessage(<React.Fragment>An error occurred while validating your card: <strong>{errorMessage}</strong></React.Fragment>);

    const updateErrorMessageForPayment = (errorMessage: React.ReactNode) =>
        updateErrorMessage(<React.Fragment>An error occurred while processing your transaction: <strong>{errorMessage}</strong></React.Fragment>);
    
    const updateErrorMessageForOrderID = (errorMessage: React.ReactNode) =>
        updateErrorMessage(<React.Fragment>An error occurred while creating a new order: <strong>{errorMessage}</strong></React.Fragment>);
    
    const close = async () => {
        setClosing(true);

        await reset();

        if (props.location.pathname === "/pay-all") {
            props.history.push("/");
        } else {
            props.history.push("/unpaid-invoices");
        }
    };

    const validate = async (formData: PayInvoicesFormData) => {
        await updateErrorMessage();

        if (!auth.session) {
            return;
        }

        const result: any = {};
        const expiryDateMatch = formData.expiryDate.match(expiryDateRegex);

        if (expiryDateMatch) {
            const month = parseInt(expiryDateMatch[1]);

            if (month < 1 || month > 12) {
                result.expiryDate = "Month must be between 1 and 12.";
                
                return result;
            }
        } else {
            result.expiryDate = "Expiry date must be in MM/YY format.";

            return result;
        }

        try {
            const response = await Axios.post<VerificationResponse>(`${paymentEndpointBaseURL}/verify`, {
                token: auth.session.accessToken,
                webUserID: auth.session.userID,
                emailAddress: formData.emailAddress,
                orderID: orderID,
                cardNumber: formData.cardNumber,
                expiryDate: `20${expiryDateMatch[2]}-${expiryDateMatch[1]}-01`,
                cvd: formData.cvd
            } as VerificationRequest);

            if (!response.data) {
                await updateErrorMessageForVerification("An unknown error has occurred.")

                return result;
            }

            switch (response.data.status) {
                case "NoMatch":
                    result.cvd = "CVD does not match.";

                    break;

                case "NotProcessed":
                    await updateErrorMessageForVerification("Could not verify your card. Please ensure that you have correctly entered your card number and try again.");

                    break;
                
                case "Unknown":
                    await updateErrorMessageForVerification("An unknown error has occurred.");

                    break;
            }
        } catch (error) {
            if (error.response?.status === 400) {
                return getFormikErrorsFromValidationResult(error.response.data);
            } else if (error.response?.status === 500 && error.response?.data?.status === "Exception") {
                await updateErrorMessageForVerification(error.response.data.exceptionMessage);

                await logVerificationFailure(error.response.data);
            } else {
                await updateErrorMessageForVerification(getErrorMessage(error));
            }
        }

        return result;
    };

    const submit = async (formData: PayInvoicesFormData) => {
        if (errorMessage) {
            return;
        }

        if (!auth.session) {
            return;
        }

        if (!invoices) {
            return;
        }

        const expiryDateMatch = formData.expiryDate.match(expiryDateRegex);

        if (!expiryDateMatch) {
            await updateErrorMessageForPayment("Expiry date is invalid.");

            return;
        }

        try {
            const response = await Axios.post<PaymentResponse>(`${paymentEndpointBaseURL}/purchase`, {
                token: auth.session.accessToken,
                webUserID: auth.session.userID,
                emailAddress: formData.emailAddress,
                orderID: orderID,
                cardNumber: formData.cardNumber,
                expiryDate: `20${expiryDateMatch[2]}-${expiryDateMatch[1]}-01`,
                cvd: formData.cvd,
                amount: totalAmount?.toNumber(),
                invoices: invoices.map(invoice => ({
                    customerNumber: invoice.customerNumber,
                    billingPeriod: parseInt(Moment(invoice.billingPeriod, "YYYY-MM-DD").format("YYYYMMDD")),
                    invoiceNumber: invoice.invoiceNumber,
                    probillNumber: invoice.probillNumber,
                    amount: invoice.balanceDue
                } as PaymentRequestInvoice))
            } as PaymentRequest);

            if (!response.data) {
                await updateErrorMessageForPayment("An unknown error has occurred.")

                return;
            }

            if (response.data.status === "Approved" || response.data.status === "PartiallyApproved") {
                await payInvoices(selectedItems);
    
                await updateSelectedItems([]);
        
                await updateFormData(createBlankPayInvoicesFormData());
            }

            await updateResponse(response.data);
        } catch (error) {
            if (error.response?.status === 400) {
                let errors: string[] = [];

                if (error.response.data) {
                    for (const key in error.response.data) {
                        errors = [
                            ...errors,
                            error.response.data[key]
                        ];
                    }
                }

                await updateErrorMessageForPayment(errors.length ? (
                    errors.length > 1 ? <ul className="ml-4 mr-0 mt-2 mb-0 p-0">
                        {errors.map(error => <li><strong>{error}</strong></li>)}
                    </ul> : <strong>{errors[0]}</strong>
                ) : "An unknown error has occurred.");
            } else if (error.response?.status === 500 && error.response.data?.responseType === "Exception") {
                if (error.response.data.status === "Exception") {
                    updateErrorMessageForPayment(error.response.data.exceptionMessage);
    
                    await logPaymentFailure(error.response.data);
    
                    return;
                }
            } else {
                await updateErrorMessageForPayment(getErrorMessage(error));
            }
        }
    };

    const logVerificationFailure = async (response: VerificationResponse) => {
        await Axios.post(`${azureEndpointBaseURL}/failure/verification`, response);
    };

    const logPaymentFailure = async (response: PaymentResponse) => {
        await Axios.post(`${azureEndpointBaseURL}/failure/payment`, response);
    };

    const getOrderID = async () => {
        if (orderID) {
            return;
        }

        if (!auth.session) {
            return;
        }

        try {
            const response = await Axios.post<APIResponse<GetNextOrderIDResult>>(`${azureEndpointBaseURL}/order?token=${auth.session.accessToken}`, {});

            if (isSessionExpired(response.data)) {
                sessionExpired();
            }
            
            if (!response.data.result) {
                await updateErrorMessageForOrderID("An unknown error has occurred.");

                return;
            }

            await updateOrderID(response.data.result.orderID);
        } catch (error) {
            await updateErrorMessageForPayment(getErrorMessage(error));
        }
    };

    const formatForNumbersOnly = (value: string) => {
        return value.replace(/[^0-9]/g, "");
    };

    const formatExpiryDate = (expiryDate: string, currentExpiryDate: string) => {
        if (expiryDate.length === 5 && expiryDate.indexOf("-") === 2) {
            // Handle Safari autofill
            const bits = expiryDate.split("-");

            if (parseInt(bits[0]) > 12) {
                // Assume YY-MM format
                return bits[1] + "/" + bits[0];
            } else {
                return bits[0] + "/" + bits[1];
            }
        }

        const restrictedValue = expiryDate.replace(/[^0-9/]/g, "");

        if (restrictedValue.length === 2 && currentExpiryDate.length === 1) {
            return restrictedValue + "/";
        } else if (restrictedValue.length === 2 && currentExpiryDate.length === 3) {
            return restrictedValue.substring(0, restrictedValue.length - 1);
        } else {
            return restrictedValue;
        }
    }

    useEffect(() => {
        if (!data || !invoices || !totalAmount) {
            props.history.push("/unpaid-invoices");

            return;
        }

        $("#pay-invoices-modal").modal();

        $("#pay-invoices-modal").on("hide.bs.modal", event => {
            close();

            event.stopPropagation();
            event.preventDefault();

            return false;
        });

        return () => {
            setClosing(false);
            
            removeModalBackdrop();
        };
    }, []);

    useEffect(() => {
        if (orderID) {
            $("#pay-invoices-modal input[name=emailAddress]").trigger("focus");
        } else if (!closing) {
            getOrderID();
        }
    }, [orderID]);

    if (!data) {
        return null;
    }

    return <Formik
        initialValues={formData}
        onSubmit={submit}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
    >
        {formik => <Form>
            <div id="pay-invoices-modal" className="modal" tabIndex={-1} data-backdrop="static" role="dialog" style={{ zIndex: 1052 }}>
                <div className="modal-dialog">
                    <div className="modal-content" style={{ maxWidth: "400px" }}>
                        <div className="modal-header">
                            <h5 className="modal-title">{response ? getPaymentResponseStatusDescription(response.status) : (
                                orderID ? `Pay ${invoices?.length !== 1 ? "invoices" : "invoice"}` : "Loading..."
                            )}</h5>
                            <button type="button" className="close" aria-label="Close" onClick={() => close()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {(formik.isValidating || formik.isSubmitting) && <div className="loading-overlay" style={{zIndex: 10000}}>
                                <div className="spinner-border text-muted" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>}
                            {orderID ? (
                                !response ? <React.Fragment>
                                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                    <div className="text-center mx-0 my-2">
                                        <div className="card-logo mastercard" />
                                        <div className="card-logo visa" />
                                    </div>
                                    <fieldset disabled={formik.isValidating || formik.isSubmitting}>
                                        <PayInvoicesFormDataObserver formData={formik.values} />
                                        <FormRow>
                                            <FormColumn columns={12}>
                                                <label>Payment amount</label>
                                                <h5>${formatMoney(totalAmount)}</h5>
                                            </FormColumn>
                                        </FormRow>
                                        <FormRow>
                                            <FormColumn columns={12}>
                                                <InputField name="emailAddress" type="email" labelText="Email address" required={true} maxLength={80} />
                                            </FormColumn>
                                        </FormRow>
                                        <FormRow>
                                            <FormColumn columns={12}>
                                                <InputField name="cardNumber" type="text" pattern="^(\d{13}|\d{16})$" title="1234567890123456" labelText="Card number" required={true} maxLength={16} onChange={event => {
                                                    formik.handleChange({
                                                        target: {
                                                            id: "cardNumber",
                                                            name: "cardNumber",
                                                            type: "text",
                                                            value: formatForNumbersOnly(event.target.value)
                                                        }
                                                    })
                                                }} autoComplete="cc-number" />
                                            </FormColumn>
                                        </FormRow>
                                        <FormRow>
                                            <FormColumn columns={6}>
                                                <InputField name="expiryDate" type="text" pattern="^\d{2}/\d{2}$" title="MM/YY" placeholder="MM/YY" labelText="Expiry date" required={true} maxLength={5} onChange={event => {
                                                    formik.handleChange({
                                                        target: {
                                                            id: "expiryDate",
                                                            name: "expiryDate",
                                                            type: "text",
                                                            value: formatExpiryDate(event.target.value, formik.values.expiryDate)
                                                        }
                                                    })
                                                }} autoComplete="cc-exp" />
                                            </FormColumn>
                                            <FormColumn columns={6}>
                                                <InputField name="cvd" type="text" pattern="^\d{3}$" title="123" labelText="Security code" required={true} maxLength={3} onChange={event => {
                                                    formik.handleChange({
                                                        target: {
                                                            id: "cvd",
                                                            name: "cvd",
                                                            type: "text",
                                                            value: formatForNumbersOnly(event.target.value)
                                                        }
                                                    });
                                                }} autoComplete="cc-csc" />
                                            </FormColumn>
                                        </FormRow>
                                    </fieldset>
                                </React.Fragment> : <div className={getPaymentResponseStatusAlertClassName(response.status)}>
                                    <h4 className="mx-0 my-3">{getPaymentResponseStatusDescription(response.status)}</h4>
                                    {(response.status === "Approved" || response.status === "PartiallyApproved") && <p className="mx-0 mt-0 mb-3 p-0">A receipt for your transaction will be emailed to {formData.emailAddress}.</p>}
                                    <table className="table table-sm">
                                        <tbody>
                                            <tr>
                                                <th>Transaction type</th>
                                                <td>{response.transactionType}</td>
                                            </tr>
                                            {!!response.orderID && <tr>
                                                <th>Order ID</th>
                                                <td>{response.orderID || ""}</td>
                                            </tr>}
                                            <tr>
                                                <th>Date/time</th>
                                                <td>{Moment(response.transactionTimestamp).format("YYYY-MM-DD HH:mm:ss")} EST</td>
                                            </tr>
                                            {!!response.referenceNumber && <tr>
                                                <th>Reference number</th>
                                                <td>{response.referenceNumber || ""}</td>
                                            </tr>}
                                            {!!response.authorizationNumber && <tr>
                                                <th>Approval code</th>
                                                <td>{response.authorizationNumber || ""}</td>
                                            </tr>}
                                            {!!response.responseCode && <tr>
                                                <th>Response/ISO code</th>
                                                <td>{response.responseCode || ""}/{response.isoCode || ""}</td>
                                            </tr>}
                                            {response.status !== "PartiallyApproved" ? (!!response.transactionAmount && <tr>
                                                <th>Amount</th>
                                                <td><strong>${formatMoney(response.transactionAmount)}</strong></td>
                                            </tr>) : <React.Fragment>
                                                <tr>
                                                    <th>Requested amount</th>
                                                    <td>${formatMoney(response.requestedAmount)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Approved amount</th>
                                                    <td><strong>${formatMoney(response.transactionAmount || 0)}</strong></td>
                                                </tr>
                                            </React.Fragment>}
                                        </tbody>
                                    </table>
                                    {response.status !== "Approved" && response.status !== "PartiallyApproved" && <div className="mt-3">
                                        <button type="button" className="btn btn-primary" onClick={() => resetResponse()}>Try again</button>
                                    </div>}
                                </div>
                            ) : (
                                errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : <LoadingSpinner />
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn" onClick={() => close()} disabled={formik.isValidating || formik.isSubmitting}>{response ? "Close" : "Cancel"}</button>
                            {orderID && !response && <button type="submit" className="btn btn-primary" disabled={formik.isValidating || formik.isSubmitting}>Pay ${formatMoney(totalAmount)}</button>}
                        </div>
                    </div>
                </div>
            </div>
        </Form>}
    </Formik>;
};

export default PayInvoices;
