import React from 'react';
import Invoice from '../models/Invoice';

export interface UnpaidInvoicesContextDataModel {
    data?: Invoice[];
    errorMessage?: React.ReactNode;
    warningMessage?: React.ReactNode;
    resultsErrorMessage?: React.ReactNode;
    resultsWarningMessage?: React.ReactNode;
    filter?: any;
    page?: number;
    selectedItems: string[];
    sortColumn?: string;
    sortDirection?: string;
}

export interface UnpaidInvoicesContextData extends UnpaidInvoicesContextDataModel {
    update: (data?: Invoice[], errorMessage?: React.ReactNode, warningMessage?: React.ReactNode) => Promise<void>;
    updateResults: (errorMessage?: React.ReactNode, warningMessage?: React.ReactNode) => Promise<void>;
    updateFilter: (filter?: any) => Promise<void>;
    updatePage: (page?: number) => Promise<void>;
    updateSelectedItems: (selectedItems?: string[]) => Promise<void>;
    updateSort: (sortColumn?: string, sortDirection?: string) => Promise<void>;
}

const UnpaidInvoicesContext = React.createContext<UnpaidInvoicesContextData>({
    selectedItems: [],
    update: () => Promise.resolve(),
    updateResults: () => Promise.resolve(),
    updateFilter: () => Promise.resolve(),
    updatePage: () => Promise.resolve(),
    updateSelectedItems: () => Promise.resolve(),
    updateSort: () => Promise.resolve()
});

export default UnpaidInvoicesContext;
