import React from 'react';
import ProbillSummary from '../models/ProbillSummary';
import ShipmentInfoSearchQuery from '../models/ShipmentInfoSearchQuery';

export interface SearchShipmentsByInfoResultsContextDataModel {
    query: ShipmentInfoSearchQuery;
    data?: ProbillSummary[];
    errorMessage?: React.ReactNode;
    warningMessage?: React.ReactNode;
    filter?: any;
    page?: number;
    sortColumn?: string;
    sortDirection?: string;
}

export interface SearchShipmentsByInfoResultsContextData extends SearchShipmentsByInfoResultsContextDataModel {
    update: (query?: ShipmentInfoSearchQuery, searchResults?: ProbillSummary[], errorMessage?: React.ReactNode, warningMessage?: React.ReactNode) => Promise<void>;
    updateFilter: (filter?: any) => Promise<void>;
    updatePage: (page?: number) => Promise<void>;
    updateSort: (sortColumn?: string, sortDirection?: string) => Promise<void>;
}

export const createBlankShipmentInfoResultsSearchQuery = () => ({
    startDate: "",
    endDate: "",
    origin: "",
    originName: "",
    destination: "",
    destinationName: "",
    minimumPounds: "" as any,
    maximumPounds: "" as any,
    consigneeName: "" as any,
    consigneeAddress: "" as any,
    shipperName: "" as any,
    shipperAddress: "" as any
} as ShipmentInfoSearchQuery);

export const createBlankSearchShipmentsByInfoResultsContextDataModel = () => ({
    query: createBlankShipmentInfoResultsSearchQuery()
} as SearchShipmentsByInfoResultsContextDataModel);

const SearchShipmentsByInfoResultsContext = React.createContext<SearchShipmentsByInfoResultsContextData>({
    ...createBlankSearchShipmentsByInfoResultsContextDataModel(),
    update: () => Promise.resolve(),
    updateFilter: () => Promise.resolve(),
    updatePage: () => Promise.resolve(),
    updateSort: () => Promise.resolve()
});

export default SearchShipmentsByInfoResultsContext;
