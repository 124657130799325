import React from 'react';
import PayInvoicesFormData, { createBlankPayInvoicesFormData } from '../models/PayInvoicesFormData';
import PaymentResponse from '../models/PaymentResponse';

export interface PayInvoicesContextDataModel {
    orderID?: string;
    errorMessage?: React.ReactNode;
    formData: PayInvoicesFormData;
    response?: PaymentResponse;
}

export interface PayInvoicesContextData extends PayInvoicesContextDataModel {
    reset: () => Promise<void>;
    resetResponse: () => Promise<void>;
    updateOrderID: (orderID?: string) => Promise<void>;
    updateErrorMessage: (errorMessage?: React.ReactNode) => Promise<void>;
    updateFormData: (formData: PayInvoicesFormData) => Promise<void>;
    updateResponse: (response?: PaymentResponse) => Promise<void>;
}

export const createBlankPayInvoicesContextDataModel = () => ({
    formData: createBlankPayInvoicesFormData()
} as PayInvoicesContextDataModel);

const PayInvoicesContext = React.createContext<PayInvoicesContextData>({
    ...createBlankPayInvoicesContextDataModel(),
    reset: () => Promise.resolve(),
    resetResponse: () => Promise.resolve(),
    updateOrderID: () => Promise.resolve(),
    updateErrorMessage: () => Promise.resolve(),
    updateFormData: () => Promise.resolve(),
    updateResponse: () => Promise.resolve()
});

export default PayInvoicesContext;
