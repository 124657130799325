import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { RosenauApp } from '@rosenau/rosenau-template-components';
import asCustomerPortalApp from './components/CustomerPortalApp';
import { rootNavigation } from './navigation/NavItems';
import * as serviceWorker from './serviceWorker';

if (window.location.hostname === "rosenau-customer-portal.azurewebsites.net") {
    window.location.replace("https://drivenby.rosenau.ca" + window.location.pathname + window.location.search);
}

const CustomerPortalApp = asCustomerPortalApp(RosenauApp);

ReactDOM.render(<CustomerPortalApp navigation={rootNavigation} activeDirectoryAuthentication={false} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
