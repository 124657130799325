import React from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

export class ServiceReportProps {
    embedToken?: string;
    embedUrl?: string;
    reportId?: string;
}

class ServiceReportState {
}

export default class ServiceReport extends React.Component<ServiceReportProps, ServiceReportState> {

    constructor(props: Readonly<ServiceReportProps>) {
        super(props);
    }

    render() {
        const embedConfig = {
            type: 'report',
            accessToken: this.props.embedToken,
            embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${this.props.reportId}`,
            id: this.props.reportId,
            tokenType: models.TokenType.Embed,
            settings: {
                filterPaneEnabled: true,
                navContentPaneEnabled: true,
            },
        };

        return (
            <PowerBIEmbed
                embedConfig = { embedConfig }
                cssClassName = { "report-style-class" }
            />
        );
    }
};
