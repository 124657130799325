/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { LoadingSpinner } from '@rosenau/rosenau-ui';
import ViewFuelSurchargeContext from '../contexts/ViewFuelSurchargeContext';
import LoginContext from '../contexts/LoginContext';
import APIResponse from '../models/APIResponse';
import FuelSurcharge from '../models/FuelSurcharge';
import { endpointBaseURL } from '../utils/Constants';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import isSessionExpired from '../utils/isSessionExpired';

const ViewFuelSurcharge = () => {
    const context = useContext(ViewFuelSurchargeContext);
    const loginContext = useContext(LoginContext);

    const { data, errorMessage, update } = context;
    const { auth, sessionExpired } = loginContext;

    const load = async () => {
        if (data) {
            return;
        }

        update();

        if (!auth.session) {
            return;
        }

        try {
            const response = await Axios.get<APIResponse<FuelSurcharge>>(`${endpointBaseURL}/customerportal/fuel/getcustomerfuelsurcharge?accessToken=${encodeURIComponent(auth.session.accessToken)}`);

            if (isSessionExpired(response.data)) {
                sessionExpired();

                return;
            }

            if (response.data.status === "ERROR" || !response.data.body) {
                update(undefined, <React.Fragment>Could not load fuel surcharge: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);

                return;
            }

            update(response.data.body);
        } catch (error) {
            update(undefined, <React.Fragment>Could not load fuel surcharge: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    useEffect(() => {
        load();
    }, []);

    return <div className="card">
        <div className="card-header bg-info text-white">
            Fuel surcharge
        </div>
        <div className="card-body">
            {errorMessage ? <div className="alert alert-danger m-n3">{errorMessage}</div> : (
                data ? <table className="table table-sm">
                    <tbody>
                        <tr>
                            <th>Last updated</th>
                            <td>Today</td>
                        </tr>
                        <tr>
                            <th>Less Than Truckload (LTL) rate (1 lb to 9,999 lbs)</th>
                            <td>{data.surcharge}%</td>
                        </tr>
                        <tr>
                            <th>Truck Load (TL) rate (10,000 lbs and over)</th>
                            <td>{data.overage}%</td>
                        </tr>
                    </tbody>
                </table> : <LoadingSpinner />
            )}
        </div>
    </div>;
};

export default ViewFuelSurcharge;
