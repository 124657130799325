import React from 'react';
import { CustomerPortalNavState, CustomerPortalProps } from '../components/CustomerPortal';
import Probill from '../models/Probill';
import ProbillImage from '../models/ProbillImage';

export interface ViewProbillContextDataModel {
    probill?: Probill;
    errorMessage?: React.ReactNode;
    formatType: string;
    downloading: boolean;
    viewedProbills: string[];
    onClose: (props: CustomerPortalProps, state?: CustomerPortalNavState) => void;
    onViewProbill: (props: CustomerPortalProps, probillNumber: string, state?: CustomerPortalNavState) => void;
    onViewImage: (props: CustomerPortalProps, probillNumber: string, image: ProbillImage, state?: CustomerPortalNavState) => void;
    onEmailImage: (props: CustomerPortalProps, probillNumber: string, image: ProbillImage, state?: CustomerPortalNavState) => void;
    onImageClose: (props: CustomerPortalProps, probillNumber: string, state?: CustomerPortalNavState) => void;
    onEmailClose: (props: CustomerPortalProps, probillNumber: string, state?: CustomerPortalNavState) => void;
}

export interface ViewProbillContextData extends ViewProbillContextDataModel {
    update: (probill?: Probill, errorMessage?: React.ReactNode) => Promise<void>;
    updateFormatType: (formatType: string) => Promise<void>;
    updateDownloading: (downloadingImage: boolean) => Promise<void>;
    updateCallbacks: (callbacks: {
        onClose: (props: CustomerPortalProps, state?: CustomerPortalNavState) => void,
        onViewProbill: (props: CustomerPortalProps, probillNumber: string, state?: CustomerPortalNavState) => void,
        onViewImage: (props: CustomerPortalProps, probillNumber: string, image: ProbillImage, state?: CustomerPortalNavState) => void,
        onEmailImage: (props: CustomerPortalProps, probillNumber: string, image: ProbillImage, state?: CustomerPortalNavState) => void,
        onImageClose: (props: CustomerPortalProps, probillNumber: string, state?: CustomerPortalNavState) => void,
        onEmailClose: (props: CustomerPortalProps, probillNumber: string, state?: CustomerPortalNavState) => void
    }) => Promise<void>;
    updateViewedProbills: (viewedProbills: string[]) => Promise<void>;
}

export const getDefaultProbillCallbacks = () => ({
    onClose: (props: CustomerPortalProps, state?: CustomerPortalNavState) => props.history.push("/", state),
    onViewProbill: (props: CustomerPortalProps, probillNumber: string, state?: CustomerPortalNavState) => props.history.push(`/probill/${encodeURIComponent(probillNumber)}`, state),
    onViewImage: (props: CustomerPortalProps, probillNumber: string, image: ProbillImage, state?: CustomerPortalNavState) => props.history.push(`/probill/${encodeURIComponent(probillNumber)}/view-image/${encodeURIComponent(image.path)}`, state),
    onEmailImage: (props: CustomerPortalProps, probillNumber: string, image: ProbillImage, state?: CustomerPortalNavState) => props.history.push(`/probill/${encodeURIComponent(probillNumber)}/email-image/${encodeURIComponent(image.path)}`, state),
    onImageClose: (props: CustomerPortalProps, probillNumber: string, state?: CustomerPortalNavState) => props.history.push(`/probill/${encodeURIComponent(probillNumber)}`, state),
    onEmailClose: (props: CustomerPortalProps, probillNumber: string, state?: CustomerPortalNavState) => props.history.push(`/probill/${encodeURIComponent(probillNumber)}`, state)
});

export const createBlankViewProbillContextDataModel = () => ({
    formatType: "pdf",
    viewedProbills: [] as string[],
    ...getDefaultProbillCallbacks()
} as ViewProbillContextDataModel);

const ViewProbillContext = React.createContext<ViewProbillContextData>({
    ...createBlankViewProbillContextDataModel(),
    update: () => Promise.resolve(),
    updateFormatType: () => Promise.resolve(),
    updateDownloading: () => Promise.resolve(),
    updateCallbacks: () => Promise.resolve(),
    updateViewedProbills: () => Promise.resolve()
});

export default ViewProbillContext;
