const removeModalBackdrop = () => {
    let backdrop = document.querySelector(".modal-backdrop");

    backdrop?.parentNode?.removeChild(backdrop);

    backdrop = document.querySelector(".modal-backdrop");

    if (!backdrop) {
        document.body.classList.remove("modal-open");
        document.body.style.paddingRight = "";
    } else {
        setTimeout(() => {
            const modals = $(".modal");
            
            let highestModal: any = undefined;
            let zIndex = 0;

            for (let i = 0; i < modals.length; i++) {
                const modal = modals[i];
                const modalZIndex = modal.style.zIndex ? parseInt(modal.style.zIndex) : 0;

                if (modalZIndex > zIndex) {
                    highestModal = modal;
                    zIndex = modalZIndex;
                }
            }

            if (highestModal) {
                highestModal.focus();
            }
        }, 100);
    }
};

export default removeModalBackdrop;
