/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { saveAs } from 'file-saver';
import Moment from 'moment';
import { DataTable, DataTableCustomColumn, DataTableFilterByDate, DataTableFilterByLookup, DataTableFilterByNumber, DataTableFilterByString, DataTableProps, DataTableSortColumn, FilteredKeys, LoadingSpinner, Pager, withFiltering, withPaging, withSorting } from '@rosenau/rosenau-ui';
import { CustomerPortalProps } from './CustomerPortal';
import UnpaidInvoicesContext from '../contexts/UnpaidInvoicesContext';
import removeModalBackdrop from '../utils/removeModalBackdrop';
import Invoice from '../models/Invoice';
import { formatMoney } from '../utils/Formatters';
import PaidInvoicesContext from '../contexts/PaidInvoicesContext';
import getMapFromStringArray from '../utils/getMapFromStringArray';
import Decimal from 'decimal.js';
import ViewProbillContext from '../contexts/ViewProbillContext';
import { azureEndpointBaseURL } from '../utils/Constants';

interface UnpaidInvoicesDataTableProps extends DataTableProps<Invoice> {
    selectedItemsMap: Record<string, boolean>;
}

class UnpaidInvoicesTable extends DataTable<Invoice, UnpaidInvoicesDataTableProps> {
    getTRProps(item: Invoice) {
        if (this.props.selectedItemsMap[item.primaryKey]) {
            return {
                className: "table-info"
            };
        }

        return {};
    }
}

class UnpaidInvoicesSortColumn<K extends keyof Invoice> extends DataTableSortColumn<Invoice, K> {}
class UnpaidInvoicesCustomColumn extends DataTableCustomColumn<Invoice> {}
class UnpaidInvoicesFilterByDate<K extends FilteredKeys<Invoice, string>> extends DataTableFilterByDate<Invoice, K> {}
class UnpaidInvoicesFilterByLookup<K extends keyof Invoice> extends DataTableFilterByLookup<Invoice, K> {}
class UnpaidInvoicesFilterByNumber<K extends FilteredKeys<Invoice, number>> extends DataTableFilterByNumber<Invoice, K> {}
class UnpaidInvoicesFilterByString<K extends FilteredKeys<Invoice, string>> extends DataTableFilterByString<Invoice, K> {}

const areDataSetsEqual = (data1: any, data2: any) => {
    if (!data1 && !data2) {
        return true;
    }
    
    if (!data1 || !data2) {
        return false;
    }

    if (data1.length !== data2.length) {
        return false;
    }

    const keys1 = data1.map((x: any) => x.primaryKey);
    const keys2 = data2.map((x: any) => x.primaryKey);

    for (let i = 0; i < keys1.length; i++) {
        if (keys2.indexOf(keys1[i]) === -1) {
            return false;
        }
    }

    return true;
};

const areFiltersEqual = (filter1: any, filter2: any) => {
    if (!filter1 && !filter2) {
        return true;
    }
    
    if (!filter1 || !filter2) {
        return false;
    }

    if (filter1.billingPeriod !== filter2.billingPeriod) {
        return false;
    }

    if (filter1.customerNumber !== filter2.customerNumber) {
        return false;
    }

    if (filter1.probillDateStart !== filter2.probillDateStart) {
        return false;
    }

    if (filter1.probillDateEnd !== filter2.probillDateEnd) {
        return false;
    }

    if (filter1.probillNumber !== filter2.probillNumber) {
        return false;
    }

    if (filter1.referenceNumber !== filter2.referenceNumber) {
        return false;
    }

    if (filter1.shipperName !== filter2.shipperName) {
        return false;
    }

    if (filter1.consigneeName !== filter2.consigneeName) {
        return false;
    }

    if (filter1.shipperCity !== filter2.shipperCity) {
        return false;
    }

    if (filter1.consigneeCity !== filter2.consigneeCity) {
        return false;
    }

    if (filter1.balanceDueMin !== filter2.balanceDueMin) {
        return false;
    }

    if (filter1.balanceDueMax !== filter2.balanceDueMax) {
        return false;
    }

    return true;
}

const UnpaidInvoices = (props: CustomerPortalProps) => {
    const context = useContext(UnpaidInvoicesContext);
    const paidInvoicesContext = useContext(PaidInvoicesContext);
    const viewProbillContext = useContext(ViewProbillContext);
    
    const { data, errorMessage, warningMessage, selectedItems, filter, page, sortColumn, sortDirection, updateFilter, updatePage, updateSort, updateSelectedItems } = context;
    const { keys: paidInvoices } = paidInvoicesContext;
    const { updateCallbacks } = viewProbillContext;

    const selectedItemsMap = getMapFromStringArray(selectedItems);
    const paidInvoicesMap = getMapFromStringArray(paidInvoices);

    const close = () => {
        props.history.push("/");
    };

    const exportCSV = async () => {
        if (!filteredItems?.length) {
            return;
        }

        const response = await Axios.post(`${azureEndpointBaseURL}/invoices/unpaid/csv`, filteredItems, {
            responseType: "blob"
        });

        saveAs(response.data, `unpaid-invoices-${Moment().format("YYYYMMDDHHmmss")}.csv`);
    };
    
    const pay = () => {
        props.history.push("/unpaid-invoices/pay");
    }

    const getProbillURL = (probillNumber: string) => `/unpaid-invoices/view/${encodeURIComponent(probillNumber)}`;

    const viewProbill = (probillNumber: string) => props.history.push(getProbillURL(probillNumber));

    useEffect(() => {
        $("#unpaid-invoices-modal").modal();

        $("#unpaid-invoices-modal").on("hide.bs.modal", event => {
            close();

            event.stopPropagation();
            event.preventDefault();

            return false;
        });

        updateCallbacks({
            onClose: (props, state) => props.history.push(`/unpaid-invoices`, state),
            onViewProbill: (props, probillNumber, state) => props.history.push(getProbillURL(probillNumber), state),
            onViewImage: (props, probillNumber, image, state) => props.history.push(`${getProbillURL(probillNumber)}/view-image/${encodeURIComponent(image.path)}`, state),
            onEmailImage: (props, probillNumber, image, state) => props.history.push(`${getProbillURL(probillNumber)}/email-image/${encodeURIComponent(image.path)}`, state),
            onImageClose: (props, probillNumber, state) => props.history.push(getProbillURL(probillNumber), state),
            onEmailClose: (props, probillNumber, state) => props.history.push(getProbillURL(probillNumber), state)
        })

        return removeModalBackdrop;
    }, []);

    const dataWithoutPaid = data?.filter(x => !paidInvoicesMap[x.primaryKey]);

    const [ filteredItems, setFilteredItems ] = useState<Invoice[] | undefined>(dataWithoutPaid);

    useEffect(() => {
        setFilteredItems(dataWithoutPaid);
    }, [data]);

    useEffect(() => {
        if (page && filteredItems && page > Math.ceil(filteredItems.length / 20)) {
            updatePage(1);
        }
    }, [selectedItems]);

    const isItemSelected = (primaryKey: string) => !!selectedItemsMap[primaryKey];
    
    const toggleSelectItem = (primaryKey: string) => {
        if (!selectedItems || !updateSelectedItems) {
            return;
        }
    
        const index = selectedItems.indexOf(primaryKey);
    
        if (index > -1) {
            updateSelectedItems([
                ...selectedItems.slice(0, index),
                ...selectedItems.slice(index + 1)
            ]);
        } else {
            updateSelectedItems([
                ...selectedItems,
                primaryKey
            ]);
        }
    };

    const areAllItemsSelected = () => filteredItems && selectedItems && filteredItems.every(x => selectedItems && selectedItemsMap[x.primaryKey]);
    
    const toggleSelectAllItems = () => {
        if (!filteredItems || !updateSelectedItems) {
            return;
        }
    
        const filteredItemKeys = filteredItems.map(x => x.primaryKey);
        const filteredItemKeysMap = getMapFromStringArray(filteredItemKeys);
        
        if (areAllItemsSelected()) {
            updateSelectedItems(selectedItems.filter(x => !filteredItemKeysMap[x]));
        } else {
            updateSelectedItems([...filteredItemKeys, ...(selectedItems || []).filter(x => !filteredItemKeysMap[x])]);
        }
    };
    
    const resetSelectedItems = () => {
        if (!updateSelectedItems) {
            return;
        }
    
        updateSelectedItems([]);
    };

    let hiddenSelectionWarning: React.ReactNode | undefined = undefined;
    
    if (filteredItems && selectedItems) {
        const hiddenSelections = selectedItems.filter(x => !filteredItems.some(y => y.primaryKey === x));
    
        if (hiddenSelections.length) {
            hiddenSelectionWarning = <div className="alert alert-warning">Warning: <strong>{hiddenSelections.length} {hiddenSelections.length !== 1 ? "selections are" : "selection is"} not shown in this list. <a href="#" onClick={event => {
                resetSelectedItems();
    
                event.stopPropagation();
                event.preventDefault();
    
                return false;
            }}>Click here</a> to reset your selections.</strong></div>;
        }
    }

    const InvoiceItemTable = withFiltering(withSorting(withPaging(UnpaidInvoicesTable, 20)));
    
    return <div id="unpaid-invoices-modal" className="modal" tabIndex={-1} data-backdrop="static" role="dialog" style={{zIndex: 1051}}>
        <div className="modal-dialog modal-dialog-scrollable" style={{maxWidth: dataWithoutPaid?.length ? "1366px" : undefined}}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{data ? "Unpaid invoices" : (errorMessage ? "Error" : "Loading...")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => close()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : <React.Fragment>
                        {warningMessage && <div className="alert alert-warning">{warningMessage}</div>}
                        {dataWithoutPaid ? (
                            dataWithoutPaid.length ? <React.Fragment>
                                {hiddenSelectionWarning}
                                <InvoiceItemTable className="pb-m2" data={dataWithoutPaid} selectedItemsMap={selectedItemsMap} page={page} sortColumn={sortColumn} sortDirection={sortDirection as any} initialValues={filter} renderPager={false} updatePage={updatePage} updateSort={updateSort} updateData={data => {
                                    if (!areDataSetsEqual(data, filteredItems)) {
                                        setFilteredItems(data);
                                    }
                                }} updateFilter={newFilter => {
                                    if (!areFiltersEqual(filter, newFilter)) {
                                        updateFilter(newFilter);
                                    }
                                }} renderFooter={() => <tfoot>
                                    <tr className="bg-light">
                                        <th colSpan={8}>Total selected for payment:</th>
                                        <td className="text-right"><strong>${formatMoney(dataWithoutPaid.filter(x => selectedItemsMap[x.primaryKey]).map(x => x.balanceDue).reduce((a, b) => a.add(new Decimal(b)), new Decimal(0)) || new Decimal(0))}</strong></td>
                                    </tr>
                                </tfoot>} resetPageOnDataChanged={false}>
                                    <UnpaidInvoicesFilterByLookup prop="billingPeriod" labelText="Billing period" />
                                    <UnpaidInvoicesFilterByLookup prop="customerNumber" labelText="Customer" type="any" />
                                    <UnpaidInvoicesFilterByDate prop="probillDate" labelText="Probill date" />
                                    <UnpaidInvoicesFilterByString prop="probillNumber" labelText="Probill number" />
                                    <UnpaidInvoicesFilterByString prop="referenceNumber" labelText="Reference" />
                                    <UnpaidInvoicesFilterByLookup prop="shipperCity" labelText="Origin" />
                                    <UnpaidInvoicesFilterByLookup prop="consigneeCity" labelText="Destination" />
                                    <UnpaidInvoicesFilterByNumber prop="balanceDue" labelText="Balance due" />
                                    <UnpaidInvoicesCustomColumn heading={filteredItems?.length ? <input type="checkbox" checked={areAllItemsSelected()} onChange={() => toggleSelectAllItems()} /> : undefined} render={item => <input type="checkbox" checked={isItemSelected(item.primaryKey)} disabled={paidInvoicesMap[item.primaryKey]} onChange={() => toggleSelectItem(item.primaryKey)} />} />
                                    <UnpaidInvoicesSortColumn prop="billingPeriod" heading="Billing period" />
                                    <UnpaidInvoicesSortColumn prop="customerNumber" heading="Customer" />
                                    <UnpaidInvoicesSortColumn prop="probillDate" heading="Probill date" />
                                    <UnpaidInvoicesSortColumn prop="probillNumber" heading="Probill number" render={probillNumber => <a href={`/probill/${encodeURIComponent(probillNumber)}`} onClick={event => {
                                        viewProbill(probillNumber);

                                        event.stopPropagation();
                                        event.preventDefault();

                                        return false;
                                    }}>{probillNumber}</a>} />
                                    <UnpaidInvoicesSortColumn prop="referenceNumber" heading="Reference" />
                                    <UnpaidInvoicesSortColumn prop="shipperCity" heading="Origin" render={(_, item) => `${item.shipperCity}${item.shipperProvince ? `, ${item.shipperProvince}` : ''}`} />
                                    <UnpaidInvoicesSortColumn prop="consigneeCity" heading="Destination" render={(_, item) => `${item.consigneeCity}${item.consigneeProvince ? `, ${item.consigneeProvince}` : ''}`} />
                                    <UnpaidInvoicesSortColumn prop="balanceDue" heading="Balance due" render={(amount, item) => !paidInvoicesMap[item.primaryKey] ? <div className="text-right">${formatMoney(amount)}</div> : <div className="text-right"><strong>Pending</strong></div>} />
                                </InvoiceItemTable>
                            </React.Fragment> : <p className="m-0 p-0">There are no unpaid invoices to display.</p>
                        ) : <LoadingSpinner />}
                    </React.Fragment>}
                </div>
                <div className="modal-footer justify-content-between">
                    <div className="mb-n2">
                        {filteredItems && filteredItems.length ? <Pager page={page || 1} pageSize={20} recordCount={filteredItems.length} onPageChange={page => updatePage(page)} /> : undefined}
                    </div>
                    <div>
                        <button type="button" className="btn" onClick={() => close()}>Close</button>
                        <button type="button" className="btn btn-secondary mr-2" onClick={() => exportCSV()} disabled={!filteredItems?.length}>Export listing as CSV</button>
                        {!!dataWithoutPaid?.length && <button type="button" className="btn btn-primary" disabled={!selectedItems.length} onClick={() => pay()}>Pay selected invoices</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default UnpaidInvoices;
