import React from 'react';
import Axios from 'axios';
import { endpointBaseURL } from './Constants';
import getAPIErrorMessage from './getAPIErrorMessage';
import isSessionExpired from './isSessionExpired';
import { CustomerPortalProps } from '../components/CustomerPortal';
import { AppContextData } from '../contexts/AppContext';
import { LoginContextData } from '../contexts/LoginContext';
import { SearchShipmentsByReferenceContextData } from '../contexts/SearchShipmentsByReferenceContext';
import { SearchShipmentsContextData } from '../contexts/SearchShipmentsContext';
import { SearchShipmentsByReferenceResultsContextData } from '../contexts/SearchShipmentsByReferenceResultsContext';
import { ViewProbillContextData } from '../contexts/ViewProbillContext';
import APIResponse from '../models/APIResponse';
import ProbillSummary from '../models/ProbillSummary';

const loadReferenceNumberSearchResults = async (props: CustomerPortalProps, context: SearchShipmentsByReferenceResultsContextData, appContext: AppContextData, loginContext: LoginContextData, searchShipmentsByReferenceContext: SearchShipmentsByReferenceContextData, searchShipmentsContext: SearchShipmentsContextData, viewProbillContext: ViewProbillContextData, searchFormQuery?: string) => {
    const { data, query, update } = context;
    const { cancelTokenSource } = appContext;
    const { auth, sessionExpired } = loginContext;
    const { updateQuery } = searchShipmentsByReferenceContext;
    const { updateSearchType } = searchShipmentsContext;
    const { updateCallbacks: updateProbillViewCallbacks } = viewProbillContext;

    const getProbillURL = (probillNumber: string) => `/search/reference/view/${encodeURIComponent(probillNumber)}`;
    const searchParams = new URLSearchParams(props.location.search);
    const newQuery = searchFormQuery !== undefined ? searchFormQuery : (searchParams.get("query") || "");
    const search = `?query=${encodeURIComponent(newQuery)}`;

    await updateProbillViewCallbacks({
        onClose: (props, state) => props.history.push(`/search/reference${search}`, state),
        onViewProbill: (props, probillNumber, state) => props.history.push(`${getProbillURL(probillNumber)}${search}`, state),
        onViewImage: (props, probillNumber, image, state) => props.history.push(`${getProbillURL(probillNumber)}/view-image/${encodeURIComponent(image.path)}${search}`, state),
        onEmailImage: (props, probillNumber, image, state) => props.history.push(`${getProbillURL(probillNumber)}/email-image/${encodeURIComponent(image.path)}${search}`, state),
        onImageClose: (props, probillNumber, state) => props.history.push(`${getProbillURL(probillNumber)}${search}`, state),
        onEmailClose: (props, probillNumber, state) => props.history.push(`${getProbillURL(probillNumber)}${search}`, state)
    });

    if (data && query && newQuery === query) {
        if (searchFormQuery !== undefined) {
            return data;
        } else {
            return;
        }
    }

    update();

    if (!auth.session) {
        return;
    }

    if (!newQuery) {
        update(undefined, undefined, <React.Fragment>Could not load search results: <strong>No reference number was specified.</strong></React.Fragment>)
    }

    updateSearchType("reference");
    updateQuery(newQuery);

    try {
        const response = await Axios.post<APIResponse<ProbillSummary[]>>(`${endpointBaseURL}/customerportal/probills/searchProbillsByReferenceNumber`, {
            accessToken: auth.session.accessToken,
            referenceNumber: newQuery
        }, {
            cancelToken: cancelTokenSource?.token
        });

        if (isSessionExpired(response.data)) {
            sessionExpired();

            return;
        }
        
        if (response.data.statusMessage === "At least one search filter must be specified.") {
            return [];
        }
        
        if (response.data.status === "ERROR" || !response.data.body) {
            update(undefined, undefined, <React.Fragment>Could not load search results: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);

            return;
        }

        update(newQuery, response.data.body, undefined, response.data.status === "WARNING" && response.data.statusMessage && response.data.statusMessage !== "No probills found." ? <React.Fragment>Warning: <strong>{response.data.statusMessage}</strong></React.Fragment> : undefined);

        return response.data.body;
    } catch (error) {
        if (Axios.isCancel(error)) {
            return;
        }
        
        update(undefined, undefined, <React.Fragment>Could not load search results: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);

        return;
    }
};

export default loadReferenceNumberSearchResults;
