import React from 'react';
import AuthSession from '../models/AuthSession';

export interface Auth {
    userID?: string;
    session?: AuthSession;
}

export interface LoginContextDataModel {
    auth: Auth;
    errorMessage?: React.ReactNode;
}

export interface LoginContextData extends LoginContextDataModel {
    sessionExpired: (callback?: () => void) => Promise<void>;
    setAuth: (auth: Auth, callback?: () => void) => Promise<void>;
    setErrorMessage: (errorMessage?: React.ReactNode) => Promise<void>;
}

export const createBlankLoginContextDataModel = () => ({
    auth: {}
} as LoginContextDataModel);

const LoginContext = React.createContext<LoginContextData>({
    ...createBlankLoginContextDataModel(),
    sessionExpired: () => Promise.resolve(),
    setAuth: () => Promise.resolve(),
    setErrorMessage: () => Promise.resolve()
});

export default LoginContext;
