/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import Moment from 'moment';
import { LoadingSpinner, showToast } from '@rosenau/rosenau-ui';
import { CustomerPortalProps } from './CustomerPortal';
import AppContext from '../contexts/AppContext';
import LoginContext from '../contexts/LoginContext';
import ViewProbillContext, { getDefaultProbillCallbacks } from '../contexts/ViewProbillContext';
import APIResponse from '../models/APIResponse';
import Probill from '../models/Probill';
import Base64Document from '../models/Base64Document';
import { formatLines, formatMoney, formatProbillAddress, formatProbillCarrier } from '../utils/Formatters';
import ProbillImage from '../models/ProbillImage';
import ProbillInvoice from '../models/ProbillInvoice';
import { isSearch } from '../navigation/NavItems';
import { endpointBaseURL, websiteURL } from '../utils/Constants';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import getImageTypeTranslation from '../utils/getImageTypeTranslation';
import isSessionExpired from '../utils/isSessionExpired';
import saveImage from '../utils/saveImage';
import removeModalBackdrop from '../utils/removeModalBackdrop';

const ViewProbill = (props: CustomerPortalProps) => {
    const context = useContext(ViewProbillContext);
    const appContext = useContext(AppContext);
    const loginContext = useContext(LoginContext);

    const { onClose, onViewProbill, onViewImage, onEmailImage, probill, errorMessage, formatType, viewedProbills, update, updateCallbacks, updateDownloading, updateFormatType, updateViewedProbills } = context;
    const { cancelTokenSource } = appContext;
    const { auth, sessionExpired } = loginContext;

    const referenceNumbers = probill?.references.filter(x => x.type === "REF");
    const primaryReference = referenceNumbers?.length ? referenceNumbers[0].reference : undefined;

    const purchaseOrderNumbers = probill?.references.filter(x => x.type === "PO#");

    const showCharges = ["C", "V", "A", "R"].some(x => probill?.probillStatus === x) || auth.session?.role === "Admin";

    const probillNumber = probill?.probillNumber;

    const load = async () => {
        await updateViewedProbills(props.location.state?.viewedProbills?.split(",").filter(x => x) || []);

        const probillNumber = decodeURIComponent(props.match.params.probillNumber || "");

        if (context.probill && context.probill.probillNumber === props.match.params.probillNumber) {
            return;
        }

        update();

        if (!auth.session) {
            updateCallbacks(getDefaultProbillCallbacks());

            return;
        }

        if (!probillNumber) {
            updateCallbacks(getDefaultProbillCallbacks());
            
            return;
        }

        try {
            const response = await Axios.post<APIResponse<Probill>>(`${endpointBaseURL}/customerportal/probills/getprobillbyprobillnumber`, {
                accessToken: auth.session.accessToken,
                probillNumber: probillNumber
            }, {
                cancelToken: cancelTokenSource.token
            });

            if (isSessionExpired(response.data)) {
                sessionExpired();

                return;
            }

            if (response.data.status === "ERROR" || !response.data.body) {
                update(undefined, <React.Fragment>Could not load probill: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);

                return;
            }

            update(response.data.body);
        } catch (error) {
            if (Axios.isCancel(error)) {
                return;
            }
            
            update(undefined, <React.Fragment>Could not load probill: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    const quickTrack = (probillNumber: string) => {
        window.open(`${websiteURL}/track-shipment/?probillNumber=${encodeURIComponent(probillNumber)}`);
    };

    const printInvoice = async (probillNumber: string) => {
        if (!auth.session) {
            return;
        }

        updateDownloading(true);

        try {
            const response = await Axios.post<APIResponse<ProbillInvoice>>(`${endpointBaseURL}/customerportal/probills/documents/getprobillinvoice`, {
                accessToken: auth.session.accessToken,
                probillNumber: probillNumber
            }, {
                cancelToken: cancelTokenSource.token
            });

            if (isSessionExpired(response.data)) {
                sessionExpired();

                updateDownloading(false);

                return;
            }

            if (response.data.status === "ERROR" || !response.data.body) {
                showToast("Error", `Could not print invoice: ${response.data.statusMessage || "An unknown error has occurred."}`, "danger");
                
                updateDownloading(false);

                return;
            }

            saveImage({
                fileName: response.data.body.filename,
                base64Document: response.data.body?.probillInvoiceAsBase64String
            }, "pdf");

            updateDownloading(false);
        } catch (error) {
            showToast("Error", `Could not print invoice: ${getAPIErrorMessage(error)}`, "danger");

            updateDownloading(false);
        }
    };

    const emailImage = (image: ProbillImage) => {
        if (!probillNumber) {
            return;
        }

        onEmailImage(props, probillNumber, image, {
            viewedProbills: viewedProbills.join(",")
        });
    };

    const viewImage = async (image: ProbillImage) => {
        if (!probillNumber) {
            return;
        }

        if (formatType === "jpeg") {
            onViewImage(props, probillNumber, image, {
                viewedProbills: viewedProbills.join(",")
            });
        } else {
            await downloadImage(image);
        }
    };

    const downloadImage = async (image: ProbillImage) => {
        if (!auth.session) {
            return;
        }

        updateDownloading(true);

        try {
            const response = await Axios.post<APIResponse<Base64Document[]>>(`${endpointBaseURL}/customerportal/probills/documents/getprobilldocument`, {
                accessToken: auth.session.accessToken,
                formatType: formatType,
                imageUri: image.path
            }, {
                cancelToken: cancelTokenSource?.token
            });

            if (isSessionExpired(response.data)) {
                sessionExpired();

                updateDownloading(false);
        
                return;
            }

            if (response.data.status === "ERROR" || !response.data.body || !response.data.body.length) {
                showToast("Error", `Could not load probill image: ${response.data.statusMessage || "An unknown error has occurred."}`, "danger");

                updateDownloading(false);
        
                return;
            }

            saveImage(response.data.body[0], formatType);

            updateDownloading(false);
        } catch (error) {
            if (Axios.isCancel(error)) {
                updateDownloading(false);

                return;
            }
            
            showToast("Error", `Could not load probill image: ${getAPIErrorMessage(error)}`, "danger");

            updateDownloading(false);
        }
    };

    const close = async () => onClose(props);

    const viewProbill = async (probillNumber: string) => {
        onViewProbill(props, probillNumber, probill?.probillNumber ? {
            viewedProbills: [
                ...viewedProbills,
                probill.probillNumber
            ].join(",")
        } : undefined);
    };

    const back = async () => {
        if (!viewedProbills.length) {
            close();
        }

        const probillNumber = viewedProbills[viewedProbills.length - 1];
        
        onViewProbill(props, probillNumber, {
            viewedProbills: [
                viewedProbills.slice(0, viewedProbills.length - 1)
            ].join(",")
        });
    };

    useEffect(() => {
        $("#probill-view-modal").modal();

        $("#probill-view-modal").on("hide.bs.modal", event => {
            close();

            event.stopPropagation();
            event.preventDefault();

            return false;
        });

        return removeModalBackdrop;
    }, []);

    useEffect(() => {
        load();
    }, [props.location.pathname]);

    return <React.Fragment>
        <div id="probill-view-modal" className="modal" tabIndex={-1} role="dialog" data-backdrop="static" style={{zIndex: (isSearch(props.match.path) ? 1052 : 1051)}}>
            <div className="modal-dialog modal-dialog-scrollable" style={{maxWidth: "1200px"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{probill ? `View probill ${probillNumber}` : "Loading..."}</h5>
                        <button type="button" className="close" aria-label="Close" onClick={() => close()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : (
                            probill ? <React.Fragment>
                                <table className="table table-sm">
                                    <tbody>
                                        <tr>
                                            <th className="bg-light" colSpan={3}>Date</th>
                                            <th className="bg-light" colSpan={2}>Reference number</th>
                                            <th className="bg-light" colSpan={2}>Master probill</th>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>{Moment(probill.probillDate, "YYYYMMDD").format("YYYY-MM-DD")}</td>
                                            <td colSpan={2}>{primaryReference}</td>
                                            <td colSpan={2}>{probill.masterProNumber && (probill.masterProNumber !== probill.probillNumber ? <a href={`/probill/${encodeURIComponent(probill.masterProNumber)}`} onClick={event => {
                                                viewProbill(probill.masterProNumber);

                                                event.stopPropagation();
                                                event.preventDefault();

                                                return false;
                                            }}>{probill.masterProNumber}</a> : <strong className="text-muted">{probill.masterProNumber}</strong>)}</td>
                                        </tr>
                                        <tr>
                                            <th className="bg-light" colSpan={3}>Consignee</th>
                                            <th className="bg-light" colSpan={2}>Shipper</th>
                                            <th className="bg-light" colSpan={2}>Billed to</th>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>
                                                {probill.destination && <strong className="d-block text-right">{probill.destination}</strong>}
                                                {formatProbillAddress(probill.consignee)}
                                            </td>
                                            <td colSpan={2}>
                                                {probill.origin && <strong className="d-block text-right">{probill.origin}</strong>}
                                                {formatProbillAddress(probill.shipper)}
                                            </td>
                                            <td colSpan={2}>
                                                {probill.billToCustomer && <strong className="d-block text-right">{probill.billToCustomer}</strong>}
                                                {formatProbillAddress(probill.payer)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="bg-light">Pieces</th>
                                            <th className="bg-light">Description</th>
                                            <th className="bg-light text-right">Weight</th>
                                            <th className="bg-light text-right">Rate</th>
                                            <th className="bg-light text-right">Prepaid</th>
                                            <th className="bg-light text-right">Charge collect</th>
                                            <th className="bg-light text-right">Cash collect</th>
                                        </tr>
                                        {(probill.details?.length) ? probill.details.map((detail, index) => <tr key={index}>
                                            <td>{detail.quantity}</td>
                                            <td>{detail.item}</td>
                                            <td className="text-right">{detail.weight || ""}</td>
                                            <td className="text-right">{showCharges ? formatMoney(detail.rate) || "" : "*****"}</td>
                                            <td className="text-right">{showCharges ? formatMoney(detail.prepaid) || "" : "*****"}</td>
                                            <td className="text-right">{showCharges ? formatMoney(detail.charge) || "" : "*****"}</td>
                                            <td className="text-right">{showCharges ? formatMoney(detail.collect) || "" : "*****"}</td>
                                        </tr>) : <tr>
                                            <td colSpan={7}>There are no details to display.</td>
                                        </tr>}
                                        <tr>
                                            <th className="bg-light">Advance</th>
                                            <td colSpan={2}>{formatProbillCarrier(probill.advanceCarrier)}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th className="bg-light">Beyond</th>
                                            <td colSpan={2}>{formatProbillCarrier(probill.beyondCarrier)}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        {!!probill.relatedProbills?.length && <tr>
                                            <th className="bg-light">Probills on master</th>
                                            <td colSpan={6}>{formatLines(probill.relatedProbills.map(relatedProbill => probill.probillNumber !== relatedProbill.probillNumber ? <a href={`/probill/${encodeURIComponent(relatedProbill.probillNumber)}`} onClick={event => {
                                                viewProbill(relatedProbill.probillNumber);

                                                event.stopPropagation();
                                                event.preventDefault();

                                                return false;
                                            }}>{relatedProbill.probillNumber}</a> : <strong className="text-muted">{relatedProbill.probillNumber}</strong>))}</td>
                                        </tr>}
                                        {!!(referenceNumbers?.length || purchaseOrderNumbers?.length) && <React.Fragment>
                                            <tr>
                                                <th className="bg-light" colSpan={7}>References</th>
                                            </tr>
                                            {!!referenceNumbers?.length && <tr>
                                                <th className="bg-light">REF#</th>
                                                <td colSpan={6}>{referenceNumbers.map(x => x.reference).join(", ")}</td>
                                            </tr>}
                                            {!!purchaseOrderNumbers?.length && <tr>
                                                <th className="bg-light">PO#</th>
                                                <td colSpan={6}>{purchaseOrderNumbers.map(x => x.reference).join(", ")}</td>
                                            </tr>}
                                        </React.Fragment>}
                                        <tr>
                                            <th className="bg-light" colSpan={2}>Appointment date</th>
                                            <th className="bg-light">Delivery date</th>
                                            <th className="bg-light text-right" rowSpan={2}>Declared value</th>
                                            <td className="text-right" rowSpan={2}>{showCharges ? formatMoney(probill.declaredValue) || "" : "*****"}</td>
                                            <th className="bg-light text-right">C.O.D.</th>
                                            <td className="text-right">{showCharges ? formatMoney(probill.cod) || "" : "*****"}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>{probill.appointmentDate && probill.appointmentTime && Moment(`${probill.appointmentDate} ${probill.appointmentTime.trim().padStart(4, "0")}`, "YYYYMMDD HHmm").format("YYYY-MM-DD HH:mm")}</td>
                                            <td>{probill.deliveryDate && probill.deliveryTime && Moment(`${probill.deliveryDate} ${probill.deliveryTime.trim().padStart(4, "0")}`, "YYYYMMDD HHmm").format("YYYY-MM-DD HH:mm")}</td>
                                            <th className="bg-light text-right">Fee</th>
                                            <td className="text-right">{showCharges ? formatMoney(probill.codFee) || "" : "*****"}</td>
                                        </tr>
                                        <tr>
                                            <th className="bg-light text-right" colSpan={2}>Total pro</th>
                                            <td className="text-right">{showCharges ? formatMoney(probill.probillTotal) || "" : "*****"}</td>
                                            <th className="bg-light text-right">Totals:</th>
                                            <td className="text-right">{showCharges ? formatMoney(probill.totalPrepaid) || "" : "*****"}</td>
                                            <td className="text-right">{showCharges ? formatMoney(probill.totalCharge) || "" : "*****"}</td>
                                            <td className="text-right">{showCharges ? formatMoney(probill.totalCollect) || "" : "*****"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-sm mt-n3 mb-n2">
                                    <thead>
                                        <tr>
                                            <th className="bg-light border-top-0" colSpan={3}>Probill documents</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {probill.images?.length ? probill.images.map(image => <tr>
                                            <td className="align-middle">{getImageTypeTranslation(image.type)}</td>
                                            <td className="align-middle text-center" style={{width: "120px"}}><button type="button" className="btn btn-sm btn-link" onClick={() => viewImage(image)} disabled={context.downloading}>View image</button></td>
                                            <td className="align-middle text-center" style={{width: "120px"}}><button type="button" className="btn btn-sm btn-link" onClick={() => emailImage(image)} disabled={context.downloading}>Email image</button></td>
                                        </tr>) : <tr>
                                            <td className="border-bottom-0" colSpan={3}>There are no documents to display.</td>
                                        </tr>}
                                    </tbody>
                                    {!!probill.images?.length && <tfoot>
                                        <tr>
                                            <td className="border-bottom-0" colSpan={3}>
                                                <div className="form-inline">
                                                    <label className="pr-2">Format type:</label>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="formatType" id="formatTypePDF" value="pdf" checked={context.formatType === "pdf"} onChange={() => updateFormatType("pdf")} />
                                                        <label htmlFor="formatTypePDF">PDF</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="formatType" id="formatTypeJPEG" value="jpeg" checked={context.formatType === "jpeg"} onChange={() => updateFormatType("jpeg")} />
                                                        <label htmlFor="formatTypeJPEG">JPEG</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="formatType" id="formatTypeTIFF" value="tiff" checked={context.formatType === "tiff"} onChange={() => updateFormatType("tiff")} />
                                                        <label htmlFor="formatTypeTIFF">TIFF</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>}
                                </table>
                            </React.Fragment> : <LoadingSpinner />
                        )}
                    </div>
                    <div className={probill && viewedProbills.length ? "modal-footer justify-content-between" : "modal-footer"}>
                        {probill && viewedProbills.length ? <div>
                            <button type="button" className="btn" onClick={() => back()}><i className="material-icons" style={{fontSize: "21px"}}>chevron_left</i> Back</button>
                        </div> : undefined}
                        <div>
                            <button type="button" className="btn" onClick={() => close()} disabled={context.downloading}>Close</button>
                            {probill && <button type="button" className="btn btn-info" onClick={() => quickTrack(probill.probillNumber)} disabled={context.downloading}><i className="material-icons" style={{fontSize: "21px"}}>open_in_new</i> Quick Track</button>}
                            {probill && ["C", "V"].some(x => x === probill.probillStatus) && <button type="button" className="btn btn-info ml-2" onClick={() => printInvoice(probill.probillNumber)} disabled={context.downloading}>Print invoice</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>;
};

export default ViewProbill;
