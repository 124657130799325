/* eslint-disable react-hooks/exhaustive-deps */

import { LoadingSpinner, Pager } from '@rosenau/rosenau-ui';
import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { CustomerPortalProps } from './CustomerPortal';
import ProbillSummaryTable from './ProbillSummaryTable';
import AppContext from '../contexts/AppContext';
import LoginContext from '../contexts/LoginContext';
import SearchShipmentsByInfoContext from '../contexts/SearchShipmentsByInfoContext';
import SearchShipmentsByInfoResultsContext from '../contexts/SearchShipmentsByInfoResultsContext';
import SearchShipmentsContext from '../contexts/SearchShipmentsContext';
import ViewProbillContext, { getDefaultProbillCallbacks } from '../contexts/ViewProbillContext';
import APIResponse from '../models/APIResponse';
import ProbillSummary from '../models/ProbillSummary';
import { shipmentInfoSearchQueryFromQueryString, shipmentInfoSearchQueryToQueryString } from '../models/ShipmentInfoSearchQuery';
import { endpointBaseURL } from '../utils/Constants';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import isSessionExpired from '../utils/isSessionExpired';
import removeModalBackdrop from '../utils/removeModalBackdrop';

const SearchShipmentsByInfoResults = (props: CustomerPortalProps) => {
    const context = useContext(SearchShipmentsByInfoResultsContext);
    const appContext = useContext(AppContext);
    const loginContext = useContext(LoginContext);
    const searchShipmentsContext = useContext(SearchShipmentsContext);
    const searchShipmentsByInfoContext = useContext(SearchShipmentsByInfoContext);
    const viewProbillContext = useContext(ViewProbillContext);

    const { data, errorMessage, warningMessage, filter, page, sortColumn, sortDirection, query, update, updateFilter, updatePage, updateSort } = context;
    const { cancelTokenSource } = appContext;
    const { auth, sessionExpired } = loginContext;
    const { updateSearchType } = searchShipmentsContext;
    const { updateQuery } = searchShipmentsByInfoContext;
    const { updateCallbacks } = viewProbillContext;

    const [ filteredItems, setFilteredItems ] = useState<ProbillSummary[] | undefined>(data);

    const load = async () => {
        const getProbillURL = (probillNumber: string) => `/search/shipment-info/view/${encodeURIComponent(probillNumber)}`;
        const newQuery = shipmentInfoSearchQueryFromQueryString(props.location.search);
        const search = `?${shipmentInfoSearchQueryToQueryString(newQuery)}`;

        await updateCallbacks({
            onClose: (props, state) => props.history.push(`/search/shipment-info${search}`, state),
            onViewProbill: (props, probillNumber, state) => props.history.push(`${getProbillURL(probillNumber)}${search}`, state),
            onViewImage: (props, probillNumber, image, state) => props.history.push(`${getProbillURL(probillNumber)}/view-image/${encodeURIComponent(image.path)}${search}`, state),
            onEmailImage: (props, probillNumber, image, state) => props.history.push(`${getProbillURL(probillNumber)}/email-image/${encodeURIComponent(image.path)}${search}`, state),
            onImageClose: (props, probillNumber, state) => props.history.push(`${getProbillURL(probillNumber)}${search}`, state),
            onEmailClose: (props, probillNumber, state) => props.history.push(`${getProbillURL(probillNumber)}${search}`, state)
        });

        if (data && newQuery.startDate === query.startDate && newQuery.endDate === query.endDate && newQuery.origin === query.origin && newQuery.destination === query.destination && newQuery.minimumPounds === query.minimumPounds && newQuery.maximumPounds === query.maximumPounds && newQuery.consigneeName === query.consigneeName && newQuery.consigneeAddress === query.consigneeAddress && newQuery.shipperName === query.shipperName && newQuery.shipperAddress === query.shipperAddress) {
            return;
        }

        update();

        if (!auth.session) {
            return;
        }

        if (!((newQuery.startDate && newQuery.endDate) || (newQuery.origin && newQuery.destination))) {
            update(undefined, undefined, <React.Fragment>Could not load search results: <strong>One or more required fields were not specified.</strong></React.Fragment>);

            return;
        }

        updateSearchType("shipment-info");
        updateQuery(newQuery);

        try {
            const response = await Axios.post<APIResponse<ProbillSummary[]>>(`${endpointBaseURL}/customerportal/probills/searchProbillsByShipmentInfo`, {
                accessToken: auth.session.accessToken,
                ...newQuery
            }, {
                cancelToken: cancelTokenSource?.token
            });

            if (isSessionExpired(response.data)) {
                sessionExpired();

                return;
            }
            
            if (response.data.status === "ERROR" || !response.data.body) {
                update(undefined, undefined, <React.Fragment>Could not load search results: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);

                return;
            }

            update(newQuery, response.data.body, undefined, response.data.status === "WARNING" && response.data.statusMessage && response.data.statusMessage !== "No probills found." ? <React.Fragment>Warning: <strong>{response.data.statusMessage}</strong></React.Fragment> : undefined);
        } catch (error) {
            if (Axios.isCancel(error)) {
                return;
            }
            
            let errorMessage = getAPIErrorMessage(error);

            if (errorMessage === "Response status code does not indicate success: 408 (Request Timeout).") {
                errorMessage = "Time limit exceeded - please narrow your search criteria.";
            }

            update(undefined, undefined, <React.Fragment>Could not load search results: <strong>{errorMessage}</strong></React.Fragment>);
        }
    };

    const viewProbill = (probillNumber: string) => props.history.push(`/search/shipment-info/view/${encodeURIComponent(probillNumber)}?${shipmentInfoSearchQueryToQueryString(query)}`);
    
    const close = async () => {
        await updateCallbacks(getDefaultProbillCallbacks());

        props.history.push(`/`);
    };

    useEffect(() => {
        $("#search-by-shipment-info-results-modal").modal();

        $("#search-by-shipment-info-results-modal").on("hide.bs.modal", event => {
            close();

            event.stopPropagation();
            event.preventDefault();

            return false;
        });

        return removeModalBackdrop;
    }, []);

    useEffect(() => {
        setFilteredItems(data);
    }, [data]);

    useEffect(() => {
        load();
    }, [props.location.pathname, props.location.search]);

    return <div id="search-by-shipment-info-results-modal" className="modal" tabIndex={-1} data-backdrop="static" role="dialog" style={{zIndex: 1051}}>
        <div className="modal-dialog modal-dialog-scrollable" style={{maxWidth: data && data.length ? "1366px" : undefined}}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{data ? "Shipment search results" : (errorMessage ? "Error" : "Loading...")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => close()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : <React.Fragment>
                        {warningMessage && <div className="alert alert-warning">{warningMessage}</div>}
                        {data ? (
                            data.length ?
                                <ProbillSummaryTable data={data} filter={filter} page={page} renderPager={false} sortColumn={sortColumn} sortDirection={sortDirection as any} updateFilter={updateFilter} updatePage={updatePage} updateSort={updateSort} updateData={setFilteredItems} onViewProbill={viewProbill} />
                            : <p className="m-0 p-0">No probills were found matching your search.</p>
                        ) : <React.Fragment>
                            <p className="text-muted">This search may take up to 5 minutes to complete. If the search is taking too long, please cancel and narrow your search criteria.</p>
                            <LoadingSpinner />
                        </React.Fragment>}
                    </React.Fragment>}
                </div>
                <div className="modal-footer justify-content-between">
                    <div>
                        {filteredItems && filteredItems.length ? <Pager page={page || 1} pageSize={20} recordCount={filteredItems.length} onPageChange={page => updatePage(page)} /> : undefined}
                    </div>
                    <div>
                        <button type="button" className="btn" onClick={() => close()}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default SearchShipmentsByInfoResults;
