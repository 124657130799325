import React from 'react';
import ProbillImage from '../models/ProbillImage';

export interface EmailProbillImageContextDataModel {
    image?: ProbillImage;
    errorMessage?: React.ReactNode;
    formatType?: string;
}

export interface EmailProbillImageContextData extends EmailProbillImageContextDataModel {
    update: (image?: ProbillImage, errorMessage?: React.ReactNode, formatType?: string) => Promise<void>;
    updateErrorMessage: (errorMessage?: React.ReactNode) => Promise<void>;
}

const EmailProbillImageContext = React.createContext<EmailProbillImageContextData>({
    update: () => Promise.resolve(),
    updateErrorMessage: () => Promise.resolve()
});

export default EmailProbillImageContext;
