import React from 'react';

export interface SearchShipmentsByProbillContextDataModel {
    query: string;
    errorMessage?: React.ReactNode;
}

export interface SearchShipmentsByProbillContextData extends SearchShipmentsByProbillContextDataModel {
    updateQuery: (query: string) => Promise<void>;
    updateErrorMessage: (errorMessage?: React.ReactNode) => Promise<void>;
}

export const createBlankSearchShipmentsByProbillContextDataModel = () => ({
    query: ""
} as SearchShipmentsByProbillContextDataModel);

const SearchShipmentsByProbillContext = React.createContext<SearchShipmentsByProbillContextData>({
    ...createBlankSearchShipmentsByProbillContextDataModel(),
    updateQuery: () => Promise.resolve(),
    updateErrorMessage: () => Promise.resolve()
});

export default SearchShipmentsByProbillContext;
