import React from 'react';
import BillPrintSearchQuery from '../models/BillPrintSearchQuery';
import ProbillSummary from '../models/ProbillSummary';

export interface SearchShipmentsBillPrintResultsContextDataModel {
    query: BillPrintSearchQuery;
    data?: ProbillSummary[];
    errorMessage?: React.ReactNode;
    warningMessage?: React.ReactNode;
    filter?: any;
    page?: number;
    selectedProbills?: string[];
    sortColumn?: string;
    sortDirection?: string;
    downloading: boolean;
}

export interface SearchShipmentsBillPrintResultsContextData extends SearchShipmentsBillPrintResultsContextDataModel {
    update: (query?: BillPrintSearchQuery, searchResults?: ProbillSummary[], errorMessage?: React.ReactNode, warningMessage?: React.ReactNode) => Promise<void>;
    updateFilter: (filter?: any) => Promise<void>;
    updatePage: (page?: number) => Promise<void>;
    updateSelectedProbills: (selectedProbills?: string[]) => Promise<void>;
    updateSort: (sortColumn?: string, sortDirection?: string) => Promise<void>;
    updateDownloading: (downloading: boolean) => Promise<void>;
}

export const createBlankBillPrintResultsSearchQuery = () => ({
    searchType: "",
    billingPeriod: "",
    podStartDate: "",
    podEndDate: "",
    formatType: "",
    imageType: ""
} as BillPrintSearchQuery);

export const createBlankSearchShipmentsBillPrintResultsContextDataModel = () => ({
    query: createBlankBillPrintResultsSearchQuery(),
    downloading: false
} as SearchShipmentsBillPrintResultsContextDataModel);

const SearchShipmentsBillPrintResultsContext = React.createContext<SearchShipmentsBillPrintResultsContextData>({
    ...createBlankSearchShipmentsBillPrintResultsContextDataModel(),
    update: () => Promise.resolve(),
    updateFilter: () => Promise.resolve(),
    updatePage: () => Promise.resolve(),
    updateSelectedProbills: () => Promise.resolve(),
    updateSort: () => Promise.resolve(),
    updateDownloading: () => Promise.resolve()
});

export default SearchShipmentsBillPrintResultsContext;
