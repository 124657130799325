import React from 'react';
import { RosenauAppProps } from '@rosenau/rosenau-template-components';
import AppContext, { AppContextDataModel, createBlankAppContextDataModel } from '../contexts/AppContext';
import EmailBillPrintImagesContext, { EmailBillPrintImagesContextDataModel } from '../contexts/EmailBillPrintImagesContext';
import EmailProbillImageContext, { EmailProbillImageContextDataModel } from '../contexts/EmailProbillImageContext';
import LoginContext, { createBlankLoginContextDataModel, LoginContextDataModel } from '../contexts/LoginContext';
import PaidInvoicesContext, { createBlankPaidInvoicesContextDataModel, PaidInvoicesContextDataModel } from '../contexts/PaidInvoicesContext';
import PayInvoicesContext, { createBlankPayInvoicesContextDataModel, PayInvoicesContextDataModel } from '../contexts/PayInvoicesContext';
import PaymentsContext, { PaymentsContextDataModel } from '../contexts/PaymentsContext';
import SearchShipmentsBillPrintContext, { createBlankSearchShipmentsBillPrintContextDataModel, SearchShipmentsBillPrintContextDataModel } from '../contexts/SearchShipmentsBillPrintContext';
import SearchShipmentsBillPrintResultsContext, { createBlankBillPrintResultsSearchQuery, createBlankSearchShipmentsBillPrintResultsContextDataModel, SearchShipmentsBillPrintResultsContextDataModel } from '../contexts/SearchShipmentsBillPrintResultsContext';
import SearchShipmentsByInfoContext, { createBlankSearchShipmentsByInfoContextDataModel, SearchShipmentsByInfoContextDataModel } from '../contexts/SearchShipmentsByInfoContext';
import SearchShipmentsByInfoResultsContext, { createBlankSearchShipmentsByInfoResultsContextDataModel, createBlankShipmentInfoResultsSearchQuery, SearchShipmentsByInfoResultsContextDataModel } from '../contexts/SearchShipmentsByInfoResultsContext';
import SearchShipmentsByProbillContext, { createBlankSearchShipmentsByProbillContextDataModel, SearchShipmentsByProbillContextDataModel } from '../contexts/SearchShipmentsByProbillContext';
import SearchShipmentsByProbillResultsContext, { createBlankSearchShipmentsByProbillResultsContextDataModel, SearchShipmentsByProbillResultsContextDataModel } from '../contexts/SearchShipmentsByProbillResultsContext';
import SearchShipmentsByReferenceContext, { createBlankSearchShipmentsByReferenceContextDataModel, SearchShipmentsByReferenceContextDataModel } from '../contexts/SearchShipmentsByReferenceContext';
import SearchShipmentsByReferenceResultsContext, { createBlankSearchShipmentsByReferenceResultsContextDataModel, SearchShipmentsByReferenceResultsContextDataModel } from '../contexts/SearchShipmentsByReferenceResultsContext';
import SearchShipmentsContext, { createBlankSearchShipmentsContextDataModel, SearchShipmentsContextDataModel } from '../contexts/SearchShipmentsContext';
import UnpaidInvoicesContext, { UnpaidInvoicesContextDataModel } from '../contexts/UnpaidInvoicesContext';
import ViewCarbonLevySurchargesContext, { ViewCarbonLevySurchargesContextDataModel } from '../contexts/ViewCarbonLevySurchargesContext';
import ViewFuelSurchargeContext, { FuelSurchargeContextDataModel } from '../contexts/ViewFuelSurchargeContext';
import ViewOpenShipmentsContext, { ViewOpenShipmentsContextDataModel } from '../contexts/ViewOpenShipmentsContext';
import ViewProbillContext, { createBlankViewProbillContextDataModel, ViewProbillContextDataModel } from '../contexts/ViewProbillContext';
import ViewProbillImageContext, { ViewProbillImageContextDataModel } from '../contexts/ViewProbillImageContext';
import { createBlankPayInvoicesFormData } from '../models/PayInvoicesFormData';

interface CustomerPortalAppState {
    appContext: AppContextDataModel;
    emailBillPrintImagesContext: EmailBillPrintImagesContextDataModel;
    emailProbillImageContext: EmailProbillImageContextDataModel;
    unpaidInvoicesContext: UnpaidInvoicesContextDataModel;
    loginContext: LoginContextDataModel;
    paidInvoicesContext: PaidInvoicesContextDataModel;
    payInvoicesContext: PayInvoicesContextDataModel;
    paymentsContext: PaymentsContextDataModel;
    searchShipmentsBillPrintContext: SearchShipmentsBillPrintContextDataModel;
    searchShipmentsBillPrintResultsContext: SearchShipmentsBillPrintResultsContextDataModel;
    searchShipmentsByInfoContext: SearchShipmentsByInfoContextDataModel;
    searchShipmentsByInfoResultsContext: SearchShipmentsByInfoResultsContextDataModel;
    searchShipmentsByProbillContext: SearchShipmentsByProbillContextDataModel;
    searchShipmentsByProbillResultsContext: SearchShipmentsByProbillResultsContextDataModel;
    searchShipmentsByReferenceContext: SearchShipmentsByReferenceContextDataModel;
    searchShipmentsByReferenceResultsContext: SearchShipmentsByReferenceResultsContextDataModel;
    searchShipmentsContext: SearchShipmentsContextDataModel;
    viewCarbonLevySurchargesContext: ViewCarbonLevySurchargesContextDataModel;
    viewFuelSurchargeContext: FuelSurchargeContextDataModel;
    viewOpenShipmentsContext: ViewOpenShipmentsContextDataModel;
    viewProbillContext: ViewProbillContextDataModel;
    viewProbillImageContext: ViewProbillImageContextDataModel;
}

export default function asCustomerPortalApp<P extends RosenauAppProps>(Component: React.ComponentType<P>) {
    return class CustomerPortalApp extends React.Component<P, CustomerPortalAppState> {
        constructor(props: Readonly<P>) {
            super(props);

            const storedSessionJSON = localStorage.getItem("authSession");
            const storedSession = storedSessionJSON ? JSON.parse(storedSessionJSON) : undefined;

            const storedPaidInvoicesJSON = localStorage.getItem("paidInvoices");
            const paidInvoices = storedPaidInvoicesJSON ? JSON.parse(storedPaidInvoicesJSON) : undefined;

            this.state = {
                ...this.createBlankState(),
                paidInvoicesContext: {
                    keys: paidInvoices || []
                },
                loginContext: {
                    auth: {
                        userID: storedSession?.userID,
                        session: storedSession
                    }
                }
            };
        }

        createBlankState(): CustomerPortalAppState {
            return {
                appContext: createBlankAppContextDataModel(),
                emailBillPrintImagesContext: {},
                emailProbillImageContext: {},
                loginContext: createBlankLoginContextDataModel(),
                paidInvoicesContext: createBlankPaidInvoicesContextDataModel(),
                payInvoicesContext: createBlankPayInvoicesContextDataModel(),
                paymentsContext: {
                    enablePayments: false
                },
                searchShipmentsBillPrintContext: createBlankSearchShipmentsBillPrintContextDataModel(),
                searchShipmentsBillPrintResultsContext: createBlankSearchShipmentsBillPrintResultsContextDataModel(),
                searchShipmentsByInfoContext: createBlankSearchShipmentsByInfoContextDataModel(),
                searchShipmentsByInfoResultsContext: createBlankSearchShipmentsByInfoResultsContextDataModel(),
                searchShipmentsByProbillContext: createBlankSearchShipmentsByProbillContextDataModel(),
                searchShipmentsByProbillResultsContext: createBlankSearchShipmentsByProbillResultsContextDataModel(),
                searchShipmentsByReferenceContext: createBlankSearchShipmentsByReferenceContextDataModel(),
                searchShipmentsByReferenceResultsContext: createBlankSearchShipmentsByReferenceResultsContextDataModel(),
                searchShipmentsContext: createBlankSearchShipmentsContextDataModel(),
                unpaidInvoicesContext: {
                    selectedItems: []
                },
                viewCarbonLevySurchargesContext: {},
                viewFuelSurchargeContext: {},
                viewOpenShipmentsContext: {},
                viewProbillContext: createBlankViewProbillContextDataModel(),
                viewProbillImageContext: {}
            };
        }

        async setAppState(state: Partial<AppContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    appContext: {
                        ...prevState.appContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setEmailBillPrintImagesState(state: Partial<EmailBillPrintImagesContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    emailBillPrintImagesContext: {
                        ...prevState.emailBillPrintImagesContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setEmailProbillImageState(state: Partial<EmailProbillImageContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    emailProbillImageContext: {
                        ...prevState.emailProbillImageContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setInvoicesState(state: Partial<UnpaidInvoicesContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    unpaidInvoicesContext: {
                        ...prevState.unpaidInvoicesContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setLoginState(state: Partial<LoginContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    loginContext: {
                        ...prevState.loginContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setPaidInvoicesState(state: Partial<PaidInvoicesContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    paidInvoicesContext: {
                        ...prevState.paidInvoicesContext,
                        ...state
                    }
                }), () => {
                    const keysJSON = JSON.stringify(this.state.paidInvoicesContext.keys);

                    localStorage.setItem("paidInvoices", keysJSON);

                    resolve();
                })
            );
        }

        async setPayInvoicesState(state: Partial<PayInvoicesContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    payInvoicesContext: {
                        ...prevState.payInvoicesContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setPaymentsState(state: Partial<PaymentsContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    paymentsContext: {
                        ...prevState.paymentsContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setSearchShipmentsState(state: Partial<SearchShipmentsContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    searchShipmentsContext: {
                        ...prevState.searchShipmentsContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setSearchShipmentsBillPrintState(state: Partial<SearchShipmentsBillPrintContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    searchShipmentsBillPrintContext: {
                        ...prevState.searchShipmentsBillPrintContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setSearchShipmentsBillPrintResultsState(state: Partial<SearchShipmentsBillPrintResultsContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    searchShipmentsBillPrintResultsContext: {
                        ...prevState.searchShipmentsBillPrintResultsContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setSearchShipmentsByInfoState(state: Partial<SearchShipmentsByInfoContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    searchShipmentsByInfoContext: {
                        ...prevState.searchShipmentsByInfoContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setSearchShipmentsByInfoResultsState(state: Partial<SearchShipmentsByInfoResultsContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    searchShipmentsByInfoResultsContext: {
                        ...prevState.searchShipmentsByInfoResultsContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setSearchShipmentsByProbillState(state: Partial<SearchShipmentsByProbillContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    searchShipmentsByProbillContext: {
                        ...prevState.searchShipmentsByProbillContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setSearchShipmentsByProbillResultsState(state: Partial<SearchShipmentsByProbillResultsContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    searchShipmentsByProbillResultsContext: {
                        ...prevState.searchShipmentsByProbillResultsContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setSearchShipmentsByReferenceState(state: Partial<SearchShipmentsByReferenceContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    searchShipmentsByReferenceContext: {
                        ...prevState.searchShipmentsByReferenceContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setSearchShipmentsByReferenceResultsState(state: Partial<SearchShipmentsByReferenceResultsContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    searchShipmentsByReferenceResultsContext: {
                        ...prevState.searchShipmentsByReferenceResultsContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setViewCarbonLevySurchargesState(state: Partial<ViewCarbonLevySurchargesContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    viewCarbonLevySurchargesContext: {
                        ...prevState.viewCarbonLevySurchargesContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setViewFuelSurchargeState(state: Partial<FuelSurchargeContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    viewFuelSurchargeContext: {
                        ...prevState.viewFuelSurchargeContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setViewOpenShipmentsState(state: Partial<ViewOpenShipmentsContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    viewOpenShipmentsContext: {
                        ...prevState.viewOpenShipmentsContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setViewProbillState(state: Partial<ViewProbillContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    viewProbillContext: {
                        ...prevState.viewProbillContext,
                        ...state
                    }
                }), resolve)
            );
        }

        async setViewProbillImageState(state: Partial<ViewProbillImageContextDataModel>) {
            return new Promise<void>(resolve =>
                this.setState(prevState => ({
                    viewProbillImageContext: {
                        ...prevState.viewProbillImageContext,
                        ...state
                    }
                }), resolve)
            );
        }

        render() {
            return <AppContext.Provider value={{
                ...this.state.appContext,
                updateCancelTokenSource: async (cancelTokenSource) => {
                    await this.setAppState({
                        cancelTokenSource: cancelTokenSource
                    });
                }
            }}>
                <EmailBillPrintImagesContext.Provider value={{
                    ...this.state.emailBillPrintImagesContext,
                    updateErrorMessage: async (errorMessage) => {
                        await this.setEmailBillPrintImagesState({
                            errorMessage: errorMessage
                        });
                    }
                }}>
                    <EmailProbillImageContext.Provider value={{
                        ...this.state.emailProbillImageContext,
                        update: async (probillImage, errorMessage, formatType) => {
                            await this.setEmailProbillImageState({
                                image: probillImage,
                                errorMessage: errorMessage,
                                formatType: formatType
                            });
                        },
                        updateErrorMessage: async (errorMessage) => {
                            await this.setEmailProbillImageState({
                                errorMessage: errorMessage
                            });
                        }
                    }}>
                        <LoginContext.Provider value={{
                            ...this.state.loginContext,
                            sessionExpired: async () => {
                                $(".modal-backdrop").remove();

                                $("body").removeClass("modal-open");

                                await this.setLoginState({
                                    auth: {
                                        userID: this.state.loginContext.auth.userID
                                    },
                                    errorMessage: "Your session has expired. Please log in again."
                                });
                            },
                            setAuth: async (auth) => {
                                await this.setLoginState({
                                    auth: auth
                                });
                            },
                            setErrorMessage: async (errorMessage) => {
                                await this.setLoginState({
                                    errorMessage: errorMessage
                                });
                            }
                        }}>
                            <PaidInvoicesContext.Provider value={{
                                ...this.state.paidInvoicesContext,
                                payInvoices: async (keys) => {
                                    await this.setPaidInvoicesState({
                                        keys: [
                                            ...this.state.paidInvoicesContext.keys,
                                            ...(keys.filter(x => this.state.paidInvoicesContext.keys.indexOf(x) === -1))
                                        ]
                                    });
                                },
                                removeProcessedInvoices: async (newKeys) => {
                                    await this.setPaidInvoicesState({
                                        keys: this.state.paidInvoicesContext.keys.filter(x => newKeys.indexOf(x) !== -1)
                                    });
                                }
                            }}>
                                <PayInvoicesContext.Provider value={{
                                    ...this.state.payInvoicesContext,
                                    reset: async () => {
                                        await this.setPayInvoicesState({
                                            orderID: undefined,
                                            errorMessage: undefined,
                                            formData: createBlankPayInvoicesFormData(),
                                            response: undefined
                                        });
                                    },
                                    resetResponse: async () => {
                                        await this.setPayInvoicesState({
                                            orderID: undefined,
                                            errorMessage: undefined,
                                            response: undefined
                                        })
                                    },
                                    updateOrderID: async (orderID) => {
                                        await this.setPayInvoicesState({
                                            orderID: orderID
                                        });
                                    },
                                    updateErrorMessage: async (errorMessage) => {
                                        await this.setPayInvoicesState({
                                            errorMessage: errorMessage
                                        });
                                    },
                                    updateFormData: async (formData) => {
                                        await this.setPayInvoicesState({
                                            formData: formData
                                        });
                                    },
                                    updateResponse: async (response) => {
                                        await this.setPayInvoicesState({
                                            response: response
                                        });
                                    }
                                }}>
                                    <PaymentsContext.Provider value={{
                                        ...this.state.paymentsContext,
                                        update: async (enablePayments) => {
                                            await this.setPaymentsState({
                                                enablePayments: enablePayments
                                            });
                                        }
                                    }}>
                                        <SearchShipmentsBillPrintContext.Provider value={{
                                            ...this.state.searchShipmentsBillPrintContext,
                                            updateBillingPeriods: async (billingPeriods) => {
                                                await this.setSearchShipmentsBillPrintState({
                                                    billingPeriods: billingPeriods
                                                });
                                            },
                                            updateBillingPeriodsErrorMessage: async (errorMessage) => {
                                                await this.setSearchShipmentsBillPrintState({
                                                    errorMessage: errorMessage
                                                });
                                            },
                                            updateQuery: async (query) => {
                                                await this.setSearchShipmentsBillPrintState({
                                                    query: query
                                                });
                                            },
                                            updateErrorMessage: async (errorMessage) => {
                                                await this.setSearchShipmentsBillPrintState({
                                                    errorMessage: errorMessage
                                                });
                                            }
                                        }}>
                                            <SearchShipmentsBillPrintResultsContext.Provider value={{
                                                ...this.state.searchShipmentsBillPrintResultsContext,
                                                update: async (query, data, errorMessage, warningMessage) => {
                                                    await this.setSearchShipmentsBillPrintResultsState({
                                                        query: query || createBlankBillPrintResultsSearchQuery(),
                                                        data: data,
                                                        errorMessage: errorMessage,
                                                        warningMessage: warningMessage,
                                                        filter: undefined,
                                                        page: undefined,
                                                        sortColumn: undefined,
                                                        sortDirection: undefined
                                                    });
                                                },
                                                updateFilter: async (filter) => {
                                                    await this.setSearchShipmentsBillPrintResultsState({
                                                        filter: filter,
                                                        page: 1
                                                    });
                                                },
                                                updatePage: async (page) => {
                                                    await this.setSearchShipmentsBillPrintResultsState({
                                                        page: page
                                                    });
                                                },
                                                updateSelectedProbills: async (selectedProbills) => {
                                                    await this.setSearchShipmentsBillPrintResultsState({
                                                        selectedProbills: selectedProbills
                                                    });
                                                },
                                                updateDownloading: async (downloading) => {
                                                    await this.setSearchShipmentsBillPrintResultsState({
                                                        downloading: downloading
                                                    });
                                                },
                                                updateSort: async (sortColumn, sortDirection) => {
                                                    await this.setSearchShipmentsBillPrintResultsState({
                                                        sortColumn: sortColumn,
                                                        sortDirection: sortDirection
                                                    });
                                                }
                                            }}>
                                                <SearchShipmentsByInfoContext.Provider value={{
                                                    ...this.state.searchShipmentsByInfoContext,
                                                    updateCities: async (cities) => {
                                                        await this.setSearchShipmentsByInfoState({
                                                            cities: cities
                                                        });
                                                    },
                                                    updateCitiesErrorMessage: async (errorMessage) => {
                                                        await this.setSearchShipmentsByInfoState({
                                                            citiesErrorMessage: errorMessage
                                                        });
                                                    },
                                                    updateQuery: async (query) => {
                                                        await this.setSearchShipmentsByInfoState({
                                                            query: query
                                                        });
                                                    },
                                                    updateErrorMessage: async (errorMessage) => {
                                                        await this.setSearchShipmentsByInfoState({
                                                            errorMessage: errorMessage
                                                        });
                                                    }
                                                }}>
                                                    <SearchShipmentsByInfoResultsContext.Provider value={{
                                                        ...this.state.searchShipmentsByInfoResultsContext,
                                                        update: async (query, data, errorMessage, warningMessage) => {
                                                            await this.setSearchShipmentsByInfoResultsState({
                                                                query: query || createBlankShipmentInfoResultsSearchQuery(),
                                                                data: data,
                                                                errorMessage: errorMessage,
                                                                warningMessage: warningMessage,
                                                                filter: undefined,
                                                                page: undefined,
                                                                sortColumn: undefined,
                                                                sortDirection: undefined
                                                            });
                                                        },
                                                        updateFilter: async (filter) => {
                                                            await this.setSearchShipmentsByInfoResultsState({
                                                                filter: filter,
                                                                page: 1
                                                            });
                                                        },
                                                        updatePage: async (page) => {
                                                            await this.setSearchShipmentsByInfoResultsState({
                                                                page: page
                                                            });
                                                        },
                                                        updateSort: async (sortColumn, sortDirection) => {
                                                            await this.setSearchShipmentsByInfoResultsState({
                                                                sortColumn: sortColumn,
                                                                sortDirection: sortDirection
                                                            });
                                                        }
                                                    }}>
                                                        <SearchShipmentsByProbillContext.Provider value={{
                                                            ...this.state.searchShipmentsByProbillContext,
                                                            updateQuery: async (query) => {
                                                                await this.setSearchShipmentsByProbillState({
                                                                    query: query
                                                                });
                                                            },
                                                            updateErrorMessage: async (errorMessage) => {
                                                                await this.setSearchShipmentsByProbillState({
                                                                    errorMessage: errorMessage
                                                                });
                                                            }
                                                        }}>
                                                            <SearchShipmentsByProbillResultsContext.Provider value={{
                                                                ...this.state.searchShipmentsByProbillResultsContext,
                                                                update: async (query, data, errorMessage, warningMessage) => {
                                                                    await this.setSearchShipmentsByProbillResultsState({
                                                                        query: query || "",
                                                                        data: data,
                                                                        errorMessage: errorMessage,
                                                                        warningMessage: warningMessage,
                                                                        filter: undefined,
                                                                        page: undefined,
                                                                        sortColumn: undefined,
                                                                        sortDirection: undefined
                                                                    });
                                                                },
                                                                updateFilter: async (filter) => {
                                                                    await this.setSearchShipmentsByProbillResultsState({
                                                                        filter: filter,
                                                                        page: 1
                                                                    });
                                                                },
                                                                updatePage: async (page) => {
                                                                    await this.setSearchShipmentsByProbillResultsState({
                                                                        page: page
                                                                    });
                                                                },
                                                                updateSort: async (sortColumn, sortDirection) => {
                                                                    await this.setSearchShipmentsByProbillResultsState({
                                                                        sortColumn: sortColumn,
                                                                        sortDirection: sortDirection
                                                                    });
                                                                }
                                                            }}>
                                                                <SearchShipmentsByReferenceContext.Provider value={{
                                                                    ...this.state.searchShipmentsByReferenceContext,
                                                                    updateQuery: async (query) => {
                                                                        await this.setSearchShipmentsByReferenceState({
                                                                            query: query
                                                                        });
                                                                    },
                                                                    updateErrorMessage: async (errorMessage) => {
                                                                        await this.setSearchShipmentsByReferenceState({
                                                                            errorMessage: errorMessage
                                                                        });
                                                                    }
                                                                }}>
                                                                    <SearchShipmentsByReferenceResultsContext.Provider value={{
                                                                        ...this.state.searchShipmentsByReferenceResultsContext,
                                                                        update: async (query, data, errorMessage, warningMessage) => {
                                                                            await this.setSearchShipmentsByReferenceResultsState({
                                                                                query: query || "",
                                                                                data: data,
                                                                                errorMessage: errorMessage,
                                                                                warningMessage: warningMessage,
                                                                                filter: undefined,
                                                                                page: undefined,
                                                                                sortColumn: undefined,
                                                                                sortDirection: undefined
                                                                            });
                                                                        },
                                                                        updateFilter: async (filter) => {
                                                                            await this.setSearchShipmentsByReferenceResultsState({
                                                                                filter: filter,
                                                                                page: 1
                                                                            });
                                                                        },
                                                                        updatePage: async (page) => {
                                                                            await this.setSearchShipmentsByReferenceResultsState({
                                                                                page: page
                                                                            });
                                                                        },
                                                                        updateSort: async (sortColumn, sortDirection) => {
                                                                            await this.setSearchShipmentsByReferenceResultsState({
                                                                                sortColumn: sortColumn,
                                                                                sortDirection: sortDirection
                                                                            });
                                                                        }
                                                                    }}>
                                                                        <SearchShipmentsContext.Provider value={{
                                                                            ...this.state.searchShipmentsContext,
                                                                            updateSearchType: async (searchType) => {
                                                                                await this.setSearchShipmentsState({
                                                                                    searchType: searchType
                                                                                });
                                                                            }
                                                                        }}>
                                                                            <UnpaidInvoicesContext.Provider value={{
                                                                                ...this.state.unpaidInvoicesContext,
                                                                                update: async (data, errorMessage, warningMessage) => {
                                                                                    await this.setInvoicesState({
                                                                                        data: data,
                                                                                        errorMessage: errorMessage,
                                                                                        warningMessage: warningMessage
                                                                                    });
                                                                                },
                                                                                updateResults: async (errorMessage, warningMessage) => {
                                                                                    await this.setInvoicesState({
                                                                                        resultsErrorMessage: errorMessage,
                                                                                        resultsWarningMessage: warningMessage
                                                                                    });
                                                                                },
                                                                                updateFilter: async (filter) => {
                                                                                    await this.setInvoicesState({
                                                                                        filter: filter,
                                                                                        page: 1
                                                                                    });
                                                                                },
                                                                                updatePage: async (page) => {
                                                                                    await this.setInvoicesState({
                                                                                        page: page
                                                                                    });
                                                                                },
                                                                                updateSelectedItems: async (selectedItems) => {
                                                                                    await this.setInvoicesState({
                                                                                        selectedItems: selectedItems
                                                                                    });
                                                                                },
                                                                                updateSort: async (sortColumn, sortDirection) => {
                                                                                    await this.setInvoicesState({
                                                                                        sortColumn: sortColumn,
                                                                                        sortDirection: sortDirection
                                                                                    });
                                                                                }
                                                                            }}>
                                                                                <ViewCarbonLevySurchargesContext.Provider value={{
                                                                                    ...this.state.viewCarbonLevySurchargesContext,
                                                                                    update: async (carbonLevySurcharges, errorMessage) => {
                                                                                        await this.setViewCarbonLevySurchargesState({
                                                                                            data: carbonLevySurcharges,
                                                                                            errorMessage: errorMessage
                                                                                        });
                                                                                    }
                                                                                }}>
                                                                                    <ViewFuelSurchargeContext.Provider value={{
                                                                                        ...this.state.viewFuelSurchargeContext,
                                                                                        update: async (fuelSurcharge, errorMessage) => {
                                                                                            await this.setViewFuelSurchargeState({
                                                                                                data: fuelSurcharge,
                                                                                                errorMessage: errorMessage
                                                                                            });
                                                                                        }
                                                                                    }}>
                                                                                        <ViewOpenShipmentsContext.Provider value={{
                                                                                            ...this.state.viewOpenShipmentsContext,
                                                                                            update: async (openShipments, errorMessage) => {
                                                                                                await this.setViewOpenShipmentsState({
                                                                                                    data: openShipments,
                                                                                                    errorMessage: errorMessage,
                                                                                                    filter: undefined,
                                                                                                    page: undefined,
                                                                                                    sortColumn: undefined,
                                                                                                    sortDirection: undefined
                                                                                                });
                                                                                            },
                                                                                            updatePage: async (page) => {
                                                                                                await this.setViewOpenShipmentsState({
                                                                                                    page: page
                                                                                                });
                                                                                            },
                                                                                            updateSort: async (sortColumn, sortDirection) => {
                                                                                                await this.setViewOpenShipmentsState({
                                                                                                    sortColumn: sortColumn,
                                                                                                    sortDirection: sortDirection
                                                                                                });
                                                                                            },
                                                                                            updateFilter: async (filter) => {
                                                                                                await this.setViewOpenShipmentsState({
                                                                                                    filter: filter,
                                                                                                    page: 1
                                                                                                });
                                                                                            }
                                                                                        }}>
                                                                                            <ViewProbillContext.Provider value={{
                                                                                                ...this.state.viewProbillContext,
                                                                                                update: async (probill, errorMessage) => {
                                                                                                    await this.setViewProbillState({
                                                                                                        probill: probill,
                                                                                                        errorMessage: errorMessage
                                                                                                    });
                                                                                                },
                                                                                                updateFormatType: async (formatType) => {
                                                                                                    await this.setViewProbillState({
                                                                                                        formatType: formatType
                                                                                                    });
                                                                                                },
                                                                                                updateDownloading: async (downloadingImage) => {
                                                                                                    await this.setViewProbillState({
                                                                                                        downloading: downloadingImage
                                                                                                    });
                                                                                                },
                                                                                                updateCallbacks: async (callbacks) => {
                                                                                                    await this.setViewProbillState({
                                                                                                        onClose: callbacks.onClose,
                                                                                                        onViewProbill: callbacks.onViewProbill,
                                                                                                        onViewImage: callbacks.onViewImage,
                                                                                                        onEmailImage: callbacks.onEmailImage,
                                                                                                        onImageClose: callbacks.onImageClose,
                                                                                                        onEmailClose: callbacks?.onEmailClose
                                                                                                    });
                                                                                                },
                                                                                                updateViewedProbills: async (viewedProbills) => {
                                                                                                    await this.setViewProbillState({
                                                                                                        viewedProbills: viewedProbills
                                                                                                    });
                                                                                                }
                                                                                            }}>
                                                                                                <ViewProbillImageContext.Provider value={{
                                                                                                    ...this.state.viewProbillImageContext,
                                                                                                    update: async (probillImage, imageData, errorMessage) => {
                                                                                                        await this.setViewProbillImageState({
                                                                                                            image: probillImage,
                                                                                                            imageData: imageData,
                                                                                                            errorMessage: errorMessage
                                                                                                        });
                                                                                                    }
                                                                                                }}>
                                                                                                    <Component allowPath={path => path === "/" || this.state.loginContext.auth.session} renderAccountDropdown={() => this.renderAccountDropdown()} {...this.props} />
                                                                                                </ViewProbillImageContext.Provider>
                                                                                            </ViewProbillContext.Provider>
                                                                                        </ViewOpenShipmentsContext.Provider>
                                                                                    </ViewFuelSurchargeContext.Provider>
                                                                                </ViewCarbonLevySurchargesContext.Provider>
                                                                            </UnpaidInvoicesContext.Provider>
                                                                        </SearchShipmentsContext.Provider>
                                                                    </SearchShipmentsByReferenceResultsContext.Provider>
                                                                </SearchShipmentsByReferenceContext.Provider>
                                                            </SearchShipmentsByProbillResultsContext.Provider>
                                                        </SearchShipmentsByProbillContext.Provider>
                                                    </SearchShipmentsByInfoResultsContext.Provider>
                                                </SearchShipmentsByInfoContext.Provider>
                                            </SearchShipmentsBillPrintResultsContext.Provider>
                                        </SearchShipmentsBillPrintContext.Provider>
                                    </PaymentsContext.Provider>
                                </PayInvoicesContext.Provider>
                            </PaidInvoicesContext.Provider>
                        </LoginContext.Provider>
                    </EmailProbillImageContext.Provider>
                </EmailBillPrintImagesContext.Provider>
            </AppContext.Provider>;
        }

        renderAccountDropdown(): React.ReactNode {
            return this.state.loginContext.auth.session ? <div id="user-dropdown-container" className={"dropdown col-md-2 no-nav"}>
                <div className="float-right position-relative">
                    <button className="btn btn-outline-dark border-0 dropdown-toggle" id="user-dropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="material-icons d-inline-block align-middle">person</i> <span className="btn-username">{this.state.loginContext.auth.session.name}</span></button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="user-dropdown">
                        <span className="dropdown-item-text dropdown-username text-muted">Logged in as {this.state.loginContext.auth.session.name}</span>
                        <div className="dropdown-divider dropdown-username-divider"></div>
                        <button className="dropdown-item" type="button" onClick={() => this.logout()}>Log out</button>
                    </div>
                </div>
            </div> : <div className="col-md-2"></div>;
        }

        logout() {
            localStorage.removeItem("authSession");

            this.setState(this.createBlankState());
        }
    };
}
