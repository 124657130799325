/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { LoadingSpinner, Pager, showToast } from '@rosenau/rosenau-ui';
import AppContext from '../contexts/AppContext';
import ProbillSummaryTable from './ProbillSummaryTable';
import { CustomerPortalProps } from './CustomerPortal';
import LoginContext from '../contexts/LoginContext';
import SearchShipmentsBillPrintContext from '../contexts/SearchShipmentsBillPrintContext';
import SearchShipmentsBillPrintResultsContext from '../contexts/SearchShipmentsBillPrintResultsContext';
import SearchShipmentsContext from '../contexts/SearchShipmentsContext';
import APIResponse from '../models/APIResponse';
import { billPrintSearchQueryFromQueryString, billPrintSearchQueryToQueryString } from '../models/BillPrintSearchQuery';
import ProbillSummary from '../models/ProbillSummary';
import { endpointBaseURL } from '../utils/Constants';
import getAPIErrorMessage from '../utils/getAPIErrorMessage';
import isSessionExpired from '../utils/isSessionExpired';
import ViewProbillContext, { getDefaultProbillCallbacks } from '../contexts/ViewProbillContext';
import Base64Document from '../models/Base64Document';
import saveImage from '../utils/saveImage';
import removeModalBackdrop from '../utils/removeModalBackdrop';
import areProbillSummaryDataSetsEqual from '../utils/areProbillSummaryDataSetsEqual';

const SearchShipmentsBillPrintResults = (props: CustomerPortalProps) => {
    const context = useContext(SearchShipmentsBillPrintResultsContext);
    const appContext = useContext(AppContext);
    const loginContext = useContext(LoginContext);
    const searchShipmentsBillPrintContext = useContext(SearchShipmentsBillPrintContext);
    const searchShipmentsContext = useContext(SearchShipmentsContext);
    const viewProbillContext = useContext(ViewProbillContext);

    const { data, errorMessage, warningMessage, selectedProbills, filter, page, sortColumn, sortDirection, downloading, query, update, updateDownloading, updateFilter, updatePage, updateSort, updateSelectedProbills } = context;
    const { updateQuery } = searchShipmentsBillPrintContext;
    const { cancelTokenSource } = appContext;
    const { auth, sessionExpired } = loginContext;
    const { updateSearchType } = searchShipmentsContext;
    const { updateCallbacks } = viewProbillContext;

    const [ filteredItems, setFilteredItems ] = useState<ProbillSummary[] | undefined>(data);

    const load = async () => {
        const getProbillURL = (probillNumber: string) => `/search/bill-print/view/${encodeURIComponent(probillNumber)}`;
        const newQuery = billPrintSearchQueryFromQueryString(props.location.search);
        const search = `?${billPrintSearchQueryToQueryString(newQuery)}`;

        await updateCallbacks({
            onClose: (props, state) => props.history.push(`/search/bill-print${search}`, state),
            onViewProbill: (props, probillNumber, state) => props.history.push(`${getProbillURL(probillNumber)}${search}`, state),
            onViewImage: (props, probillNumber, image, state) => props.history.push(`${getProbillURL(probillNumber)}/view-image/${encodeURIComponent(image.path)}${search}`, state),
            onEmailImage: (props, probillNumber, image, state) => props.history.push(`${getProbillURL(probillNumber)}/email-image/${encodeURIComponent(image.path)}${search}`, state),
            onImageClose: (props, probillNumber, state) => props.history.push(`${getProbillURL(probillNumber)}${search}`, state),
            onEmailClose: (props, probillNumber, state) => props.history.push(`${getProbillURL(probillNumber)}${search}`, state)
        });

        if (data && query && newQuery.searchType === query.searchType && newQuery.billingPeriod === query.billingPeriod && newQuery.podStartDate === query.podStartDate && newQuery.podEndDate === query.podEndDate && newQuery.formatType === query.formatType && newQuery.imageType === query.imageType) {
            return;
        }

        update();

        if (!auth.session) {
            return;
        }

        if ((newQuery.searchType === "billingPeriod" && !newQuery.billingPeriod) || (newQuery.searchType === "podDate" && (!newQuery.podStartDate || !newQuery.podEndDate)) || !newQuery.formatType || !newQuery.imageType) {
            update(undefined, undefined, <React.Fragment>Could not load search results: <strong>One or more required fields were not specified.</strong></React.Fragment>);

            return;
        }

        updateSearchType("bill-print");
        updateQuery(newQuery);

        try {
            const response = await Axios.post<APIResponse<ProbillSummary[]>>(`${endpointBaseURL}/customerportal/probills/${newQuery.searchType === "billingPeriod" ? "searchprobillsbybillingperiods" : "searchprobillsbypoddates"}`, newQuery.searchType === "billingPeriod" ? {
                accessToken: auth.session.accessToken,
                billingPeriod: newQuery.billingPeriod
            } : {
                accessToken: auth.session.accessToken,
                podStartDate: newQuery.podStartDate,
                podEndDate: newQuery.podEndDate
            }, {
                cancelToken: cancelTokenSource?.token
            });

            if (isSessionExpired(response.data)) {
                sessionExpired();

                return;
            }
            
            if (response.data.status === "ERROR" || !response.data.body) {
                update(undefined, undefined, <React.Fragment>Could not load search results: <strong>{response.data.statusMessage || "An unknown error has occurred."}</strong></React.Fragment>);

                return;
            }

            update(newQuery, response.data.body, undefined, response.data.status === "WARNING" && response.data.statusMessage && response.data.statusMessage !== "No probills found." ? <React.Fragment>Warning: <strong>{response.data.statusMessage}</strong></React.Fragment> : undefined);
        } catch (error) {
            if (Axios.isCancel(error)) {
                return;
            }
            
            update(undefined, undefined, <React.Fragment>Could not load search results: <strong>{getAPIErrorMessage(error)}</strong></React.Fragment>);
        }
    };

    const close = async () => {
        await updateCallbacks(getDefaultProbillCallbacks());

        props.history.push(`/`);
    };

    const viewProbill = (probillNumber: string) => props.history.push(`/search/bill-print/view/${encodeURIComponent(probillNumber)}?${billPrintSearchQueryToQueryString(query)}`);

    const view = async () => {
        if (!auth.session) {
            return;
        }

        if (!selectedProbills || !selectedProbills.length) {
            showToast("Error", "No probills are selected.", "danger");

            return;
        }

        if (selectedProbills.length > 50) {
            showToast("Error", "You must select no more than 50 probills.", "danger");

            return;
        }

        updateDownloading(true);

        try {
            const response = await Axios.post<APIResponse<Base64Document[]>>(`${endpointBaseURL}/customerportal/probills/documents/getprobilldocuments`, {
                accessToken: auth.session.accessToken,
                imageType: query.imageType,
                formatType: query.formatType,
                selectedProbills: selectedProbills
            }, {
                cancelToken: cancelTokenSource?.token
            });

            if (isSessionExpired(response.data)) {
                sessionExpired();

                updateDownloading(false);

                return;
            }

            if (response.data.status === "ERROR" || !response.data.body || !response.data.body.length) {
                showToast("Error", `Could not load images: ${response.data.statusMessage || "An unknown error has occurred."}`, "danger");

                updateDownloading(false);
        
                return;
            }

            saveImage(response.data.body[0], "zip");

            updateDownloading(false);
        } catch (error) {
            if (Axios.isCancel(error)) {
                updateDownloading(false);

                return;
            }

            showToast("Error", `Could not load images: ${getAPIErrorMessage(error)}`, "danger");

            updateDownloading(false);
        }
    };

    const email = () => {
        if (!selectedProbills || !selectedProbills.length) {
            showToast("Error", "No probills are selected.", "danger");

            return;
        }

        if (selectedProbills.length > 50) {
            showToast("Error", "You must select no more than 50 probills.", "danger");

            return;
        }

        props.history.push(`/search/bill-print/email?${billPrintSearchQueryToQueryString(query)}&selectedProbills=${selectedProbills?.map(x => encodeURIComponent(x)).join(",")}`);
    };

    useEffect(() => {
        $("#search-bill-print-results-modal").modal();

        $("#search-bill-print-results-modal").on("hide.bs.modal", event => {
            close();

            event.stopPropagation();
            event.preventDefault();

            return false;
        });

        return removeModalBackdrop;
    }, []);

    useEffect(() => {
        setFilteredItems(data);
    }, [data]);

    useEffect(() => {
        load();
    }, [props.location.pathname, props.location.search]);

    return <div id="search-bill-print-results-modal" className="modal" tabIndex={-1} data-backdrop="static" role="dialog" style={{zIndex: 1051}}>
        <div className="modal-dialog modal-dialog-scrollable" style={{maxWidth: data && data.length ? "1366px" : undefined}}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{data ? "Shipment search results" : (errorMessage ? "Error" : "Loading...")}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={() => close()} disabled={downloading}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : <React.Fragment>
                        {warningMessage && <div className="alert alert-warning">{warningMessage}</div>}
                        {data ? (
                            data.length ? <ProbillSummaryTable data={data} filter={filter} page={page} renderPager={false} selectedProbills={selectedProbills} sortColumn={sortColumn} sortDirection={sortDirection as any} updateFilter={updateFilter} updatePage={updatePage} updateSelectedProbills={updateSelectedProbills} updateSort={updateSort} updateData={data => {
                                    if (!areProbillSummaryDataSetsEqual(data, filteredItems)) {
                                        setFilteredItems(data);
                                    }
                                }} onViewProbill={viewProbill} /> : (!warningMessage && <p className="m-0 p-0">No probills were found matching your search.</p>)
                        ) : <LoadingSpinner />}
                    </React.Fragment>}
                </div>
                <div className="modal-footer justify-content-between">
                    <div>
                        {filteredItems && filteredItems.length ? <Pager page={page || 1} pageSize={20} recordCount={filteredItems.length} onPageChange={page => updatePage(page)} /> : undefined}
                    </div>
                    <div>
                        <button type="button" className="btn" onClick={() => close()} disabled={downloading}>Close</button>
                        {!!(data && data.length) && <React.Fragment>
                            <button type="button" className="btn btn-info mr-2" onClick={() => view()} disabled={!selectedProbills?.length || downloading}>View selected</button>
                            <button type="button" className="btn btn-info" onClick={() => email()} disabled={!selectedProbills?.length || downloading}>Email selected</button>
                        </React.Fragment>}
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default SearchShipmentsBillPrintResults;
